export const gradeTranslation = {
    K: "K",
    FIRST: "1st",
    SECOND: "2nd",
    THIRD: "3rd",
    FOURTH: "4th",
    FIFTH: "5th",
    SIXTH: "6th"
};

export const instructionalAreaToGrade = {
    51: "K",
    52: "FIRST",
    53: "SECOND",
    54: "THIRD",
    55: "FOURTH",
    56: "FIFTH",
    57: "SIXTH"
};
