import React, { Fragment, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import "../../styles/BreadCrumb.scss";
import DcsdDialog from "../modals/DcsdDialog";
import ActionButton from "../formInputs/buttons/ActionButton";

const BreadCrumb = ({ labelObj }) => {
    const [open, setOpen] = useState("false");
    const [clickedPath, setClickedPath] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const handleOnClick = (path) => {
        if (path !== pathname) {
            setOpen("navigate");
            setClickedPath(path);
        }
    };
    return (
        <div className="breadCrumb">
            <DcsdDialog hasCloseX={false} id="navigate" open={open} title="Navigate Away">
                <div>You are about to navigate away from this page. Any unsaved work will be lost.</div>
                <div className="breadCrumb-btn-group">
                    <ActionButton className="action-button-cancel" label="Cancel" onClick={() => setOpen("false")} />
                    <ActionButton
                        className="action-button-reg"
                        label="Continue"
                        onClick={() => navigate(clickedPath)}
                    />
                </div>
            </DcsdDialog>
            {labelObj.map((obj, index) => {
                return (
                    <Fragment key={obj.label}>
                        <button className="breadCrumb-btn" onClick={() => handleOnClick(obj.path)}>
                            {obj.label}
                        </button>
                        {index !== labelObj.length - 1 && <div className="breadCrumb-arrow">{">"}</div>}
                    </Fragment>
                );
            })}
        </div>
    );
};

BreadCrumb.propTypes = {
    labelObj: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        })
    ).isRequired
};

BreadCrumb.defaultProps = {
    labelObj: {
        label: "Home",
        path: "/home"
    }
};

export default BreadCrumb;
