const getCombinedGrades = (firstSemesterTemplateDto, secondSemesterTemplateDto) => {
    const firstGradeToSubjects = firstSemesterTemplateDto.gradeToSubjects;
    const secondGradeToSubjects = secondSemesterTemplateDto.gradeToSubjects;
    const gradesArray = firstGradeToSubjects.map((grade) => grade.gradeLevel);
    return gradesArray.reduce((acc, val) => {
        const firstSemVal = firstGradeToSubjects.find((obj) => obj.gradeLevel === val);
        const secondSemVal = secondGradeToSubjects.find((obj) => obj.gradeLevel === val);
        acc.push({
            grade: val,
            complete:
                firstSemVal.subjectToStandards.every((sub) => sub.completed === true) &&
                secondSemVal.subjectToStandards.every((sub) => sub.completed === true),
            firstSemProfileId: firstSemesterTemplateDto.profileId,
            secondSemProfileId: secondSemesterTemplateDto.profileId,
            released: firstSemVal.templatesReleased === true && secondSemVal.templatesReleased === true,
            semesterProfiles: { firstSem: firstSemVal, secondSem: secondSemVal }
        });
        return acc;
    }, []);
};

export default getCombinedGrades;
