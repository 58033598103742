import { useContext } from "react";
import { GlobalContext } from "./ContextProvider";
import UserDao from "../../dao/UserDao";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
/**
 * A collection of context setters
 */

/**
 * SchoolYearPerformance "getter" flag
 * @type {boolean}
 */
let getSY = true;
let getAllSY = true;

/**
 * TimePeriod "getter" flag
 * @type {boolean}
 */
let getTP = true;

/**
 * Current timePeriod "getter" flag
 * @type {boolean}
 */
let getCurrentTP = true;

/**
 * District Template "getter" flag
 * @type {boolean}
 */
let getDT = true;

/**
 * School Template "getter" flag
 * @type {boolean}
 */
let getST = true;

/**
 * Get all locations and set it in context
 * @name SetLocationDtos
 * @constructor
 */
export const SetAllLocationDtos = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { allLocationDtos, token } = state || {};

    if (token && !allLocationDtos) {
        if (getAllSY) {
            getAllSY = false;
            const options = {
                action: "locationsSearchableRead",
                params: {
                    facetField: "locationType",
                    searchString:
                        "locationType=ELEMENTARY_SCHOOL&locationType=MIDDLE_SCHOOL&locationType=HIGH_SCHOOL&locationType=ALTERNATIVE_SCHOOL&locationType=CHARTER_SCHOOL&locationType=ADMINISTRATIVE",
                    sort: "name",
                    numRows: "1000"
                },
                token
            };
            UserDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        dispatch({
                            type: "AllLocationDtos",
                            allLocationDtos: payload.results
                        });
                    }
                    getAllSY = true;
                }
            });
        }
    }
};

/**
 * Get the schoolYearDto and set it in context
 * @name SetSchoolYearDto
 * @constructor
 */
export const SetSchoolYearDto = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { schoolYearDto, token } = state || {};

    if (token && !schoolYearDto) {
        if (getSY) {
            getSY = false;
            const options = {
                action: "activeSchoolYearRead",
                token
            };
            UserDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (Object.keys(payload).length) {
                        dispatch({
                            type: "SchoolYearDto",
                            schoolYearDto: payload
                        });
                    }
                    getSY = true;
                }
            });
        }
    }
};

/**
 * Get the time periods and set it in context
 * @name SetTimePeriods
 * @constructor
 */
export const SetTimePeriodDto = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { schoolYearDto, timePeriodDto, token } = state || {};

    if (getTP && schoolYearDto && !timePeriodDto && token) {
        getTP = false;
        const options = {
            action: "getTimePeriodId",
            schoolYearKey: schoolYearDto.key,
            token
        };
        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                const { payload } = response.data;
                dispatch({
                    type: "TimePeriodDto",
                    timePeriodDto: payload
                });
            }
        });
    }
};

/**
 * Get the time periods and set it in context
 * @name SetTimePeriods
 * @constructor
 */
export const SetCurrentTimePeriodDto = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { schoolYearDto, currentTimePeriodDto, token } = state || {};

    if (getCurrentTP && schoolYearDto && !currentTimePeriodDto && token) {
        getCurrentTP = false;
        const options = {
            action: "getCurrentTimePeriodDto",
            token
        };
        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                const { payload } = response.data;
                dispatch({
                    type: "CurrentTimePeriodDto",
                    currentTimePeriodDto: payload
                });
            }
        });
    }
};

/**
 * Get the District Level Template and set it in context
 * @name SetDistrictTemplate
 * @constructor
 */
export const SetDistrictTemplateDto = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { schoolYearDto, districtTemplateDto, timePeriodDto, token } = state || {};

    if (getDT && schoolYearDto?.key && timePeriodDto && !districtTemplateDto && token) {
        getDT = false;
        const options = {
            action: "getTemplateByLocationAndTimePeriod",
            locationKey: 0,
            timePeriodId: timePeriodDto.find((period) => period.name === "Full Year").key,
            schoolYearKey: schoolYearDto.key,
            token
        };

        ElementaryProgressReportDao(options).then((response) => {
            if (response && response.data && response.data.payload?.profileId !== null) {
                const { payload } = response.data;
                dispatch({
                    type: "DistrictTemplateDto",
                    districtTemplateDto: payload
                });
            }
        });
    }
};

/**
 * Get the School Level Template and set it in context
 * @name SetSchoolTemplateDto
 * @constructor
 */
export const SetSchoolTemplateDto = () => {
    const { dispatch, state } = useContext(GlobalContext);

    const { schoolYearDto, districtTemplateDto, timePeriodDto, schoolLevelTemplateDto, userDetails, token } =
        state || {};

    const locationKey = userDetails?.userAttributeDto?.userAttributeMap?.CURRENT_PREDOMINANT_SCHOOL?.locationKey;

    if (
        districtTemplateDto &&
        userDetails &&
        locationKey &&
        schoolYearDto?.key &&
        timePeriodDto &&
        token &&
        !schoolLevelTemplateDto
    ) {
        if (getST) {
            getST = false;
            const options = {
                action: "getTemplateByLocationAndTimePeriod",
                locationKey,
                timePeriodId: timePeriodDto.find((period) => period.name === "1st Semester").key,
                schoolYearKey: schoolYearDto.key,
                token
            };
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload?.profileId !== null) {
                    const { payload: semesterOnePayload } = response.data;
                    const options = {
                        action: "getTemplateByLocationAndTimePeriod",
                        locationKey,
                        timePeriodId: timePeriodDto.find((period) => period.name === "2nd Semester").key,
                        schoolYearKey: schoolYearDto.key,
                        token
                    };
                    ElementaryProgressReportDao(options).then((response) => {
                        if (response && response.data && response.data.payload?.profileId !== null) {
                            const { payload: semesterTwoPayload } = response.data;
                            dispatch({
                                type: "SchoolLevelTemplateDto",
                                schoolLevelTemplateDto: {
                                    firstSemester: semesterOnePayload,
                                    secondSemester: semesterTwoPayload
                                }
                            });
                        }
                    });
                } else {
                    dispatch({
                        type: "SchoolLevelTemplateDto",
                        schoolLevelTemplateDto: null
                    });
                }
            });
        }
    }
};
