import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import ElementaryProgressReportDao from "../dao/ElementaryProgressReportDao.jsx";
import DefineReportingPeriods from "../components/school/DefineReportingPeriods.jsx";
import getCombinedGrades from "../utils/SchoolAdmin/getCombinedGrades.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";
import SchoolHome from "../components/school/SchoolHome.jsx";
import SchoolLevelExpectations from "../components/school/SchoolLevelExpectations.jsx";
import SchoolTemplate from "../components/school/SchoolTemplate.jsx";
import SidebarMenu from "../components/sidebar/SidebarMenu.jsx";
import UserDao from "../dao/UserDao.jsx";

import { GlobalContext } from "../components/contextProvider/ContextProvider";

import "../styles/global/Global.scss";
import "../styles/Home.scss";

/**
 * This is the SchoolAdminHome page for epr
 * @name SchoolAdmin
 * @returns {JSX.Element}
 */
const SchoolAdmin = () => {
    const [status, setStatus] = useState({ displayStatus: "", status: "" });
    const [combinedGrades, setCombinedGrades] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState("K");
    const [selectedContentArea, setSelectedContentArea] = useState("");

    const [isDistrictTemplateReleased, setIsDistrictTemplateReleased] = useState(false);
    const [templateTouchedFlag, setTemplateTouchedFlag] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedLink, setSelectedLink] = useState("HOME");
    const [navCollapsed, setNavCollapsed] = useState(false);
    const [labelObj, setLabelObj] = useState([{ label: "Home", path: "/home" }]);
    const [applicableGrades, setApplicableGrades] = useState(null);
    const { dispatch, state } = useContext(GlobalContext);

    const { districtTemplateDto, schoolYearDto, schoolLevelTemplateDto, timePeriodDto, token, userDetails } = state;
    const locationKey = userDetails?.userAttributeDto?.userAttributeMap?.CURRENT_PREDOMINANT_SCHOOL?.locationKey;

    const location = useLocation();
    const { pathname } = location;

    const handleClearSchoolOnDelete = () => {
        setCombinedGrades(null);
        dispatch({
            type: "SchoolLevelTemplateDto",
            schoolLevelTemplateDto: null
        });
    };

    /**
     * Sets the selected link based of the path to make the nav work on refresh
     */
    useEffect(() => {
        const trimmedPath = pathname.slice(1).toUpperCase();
        setSelectedLink(trimmedPath);
    }, [pathname]);

    /**
     * Get the correct status of school epr template
     */
    useEffect(() => {
        if (!districtTemplateDto || (districtTemplateDto && isDistrictTemplateReleased === false)) {
            setStatus({ displayStatus: "Waiting on District", status: "waiting" });
        }
        if (districtTemplateDto && isDistrictTemplateReleased && !schoolLevelTemplateDto) {
            setStatus({ displayStatus: "Ready to Pull", status: "ready" });
        }
        if (schoolLevelTemplateDto) {
            setStatus({ displayStatus: "In Progress", status: "in-progress" });
        }
        if (
            schoolLevelTemplateDto?.firstSemester?.profileReleasedToTeachers === true &&
            schoolLevelTemplateDto?.secondSemester?.profileReleasedToTeachers === true
        ) {
            setStatus({ displayStatus: "Released to Teachers", status: "released" });
        }
    }, [isDistrictTemplateReleased, schoolLevelTemplateDto, districtTemplateDto]);

    /**
     * Get location information to know which grades are applicable
     */
    useEffect(() => {
        if (!applicableGrades && locationKey && token) {
            const options = {
                action: "locationByKeyRead",
                key: locationKey,
                token
            };
            setLoader(true);
            UserDao(options).then((response) => {
                if (response && response.data && response.data.payload) {
                    const gradeTags = response.data.payload.tags.filter((tag) => tag.type === "GRADE");
                    setApplicableGrades(gradeTags);
                }
                setLoader(false);
            });
        }
    }, [applicableGrades, locationKey, token]);

    /**
     * Get school template if it exists and has not been fetched yet
     */
    useEffect(() => {
        if (
            districtTemplateDto &&
            locationKey &&
            schoolYearDto?.key &&
            timePeriodDto &&
            !schoolLevelTemplateDto &&
            templateTouchedFlag &&
            token
        ) {
            setLoader(true);
            const options = {
                action: "getTemplateByLocationAndTimePeriod",
                locationKey,
                timePeriodId: timePeriodDto.find((period) => period.name === "1st Semester").key,
                schoolYearKey: schoolYearDto.key,
                token
            };
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload?.profileId !== null) {
                    const { payload: semesterOnePayload } = response.data;
                    const options = {
                        action: "getTemplateByLocationAndTimePeriod",
                        locationKey,
                        timePeriodId: timePeriodDto.find((period) => period.name === "2nd Semester").key,
                        schoolYearKey: schoolYearDto.key,
                        token
                    };
                    ElementaryProgressReportDao(options).then((response) => {
                        if (response && response.data && response.data.payload?.profileId !== null) {
                            const { payload: semesterTwoPayload } = response.data;
                            dispatch({
                                type: "SchoolLevelTemplateDto",
                                schoolLevelTemplateDto: {
                                    firstSemester: semesterOnePayload,
                                    secondSemester: semesterTwoPayload
                                }
                            });
                        }
                    });
                    setTemplateTouchedFlag(false);
                    setLoader(false);
                }
            });
        }
    }, [
        combinedGrades,
        dispatch,
        districtTemplateDto,
        locationKey,
        schoolLevelTemplateDto,
        schoolYearDto,
        templateTouchedFlag,
        timePeriodDto,
        token
    ]);

    /**
     * Check for released (if 'some' (any) grade is released they are all released and template is released)
     */
    useEffect(() => {
        if (districtTemplateDto && districtTemplateDto.profileId) {
            const isReleased = districtTemplateDto.gradeToSubjects.some((grade) => grade.templatesReleased === true);
            setIsDistrictTemplateReleased(isReleased);
        }
    }, [districtTemplateDto]);

    /**
     * Take first and second semester dtos and combine into an array of grade objects
     */
    useEffect(() => {
        if (schoolLevelTemplateDto) {
            const combined = getCombinedGrades(
                schoolLevelTemplateDto.firstSemester,
                schoolLevelTemplateDto.secondSemester
            );
            setCombinedGrades(combined);
        }
    }, [schoolLevelTemplateDto]);

    return (
        <div className="gutter-95" style={{ padding: "0px 0px" }}>
            <BreadCrumb style={{ marginTop: "-10px" }} labelObj={labelObj} setSelectedLink={setSelectedLink} />
            <div style={{ display: "flex" }}>
                <SidebarMenu
                    isSchoolTemplate={Boolean(schoolLevelTemplateDto)}
                    navCollapsed={navCollapsed}
                    selectedLink={selectedLink}
                    setLabelObj={setLabelObj}
                    setNavCollapsed={setNavCollapsed}
                    setSelectedLink={setSelectedLink}
                />

                {selectedLink === "HOME" && (
                    <SchoolHome
                        applicableGrades={applicableGrades}
                        combinedGrades={combinedGrades}
                        loader={loader}
                        status={status.displayStatus}
                        statusClass={status.status}
                        timePeriodDto={timePeriodDto}
                    />
                )}
                {!loader && (schoolLevelTemplateDto || schoolLevelTemplateDto === null) && (
                    <>
                        {selectedLink === "HOME/TEMPLATE" && (
                            <SchoolTemplate
                                applicableGrades={applicableGrades}
                                combinedGrades={combinedGrades}
                                handleClearSchoolOnDelete={handleClearSchoolOnDelete}
                                setLabelObj={setLabelObj}
                                setSelectedLink={setSelectedLink}
                                setTemplateTouchedFlag={setTemplateTouchedFlag}
                                status={status.status}
                            />
                        )}
                        {selectedLink.includes("HOME/TEMPLATE/DEFINE") && (
                            <DefineReportingPeriods
                                applicableGrades={applicableGrades}
                                combinedGrades={combinedGrades}
                                isDistrictTemplateReleased={isDistrictTemplateReleased}
                                isSchoolTemplate={Boolean(schoolLevelTemplateDto)}
                                loader={loader}
                                selectedContentArea={selectedContentArea}
                                selectedGrade={selectedGrade}
                                setLabelObj={setLabelObj}
                                setSelectedContentArea={setSelectedContentArea}
                                setSelectedGrade={setSelectedGrade}
                                setSelectedLink={setSelectedLink}
                                setTemplateTouchedFlag={setTemplateTouchedFlag}
                            />
                        )}
                        {selectedLink === "HOME/SLES" && (
                            <SchoolLevelExpectations
                                fullYearTimePeriodKey={timePeriodDto?.find((period) => period.name === "Full Year").key}
                            />
                        )}
                    </>
                )}
                {loader && <LoadingSvg />}
            </div>
        </div>
    );
};

export default SchoolAdmin;
