// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-row {
  align-items: center;
  display: flex;
  margin-top: 40px;
}
.admin-row .admin-link {
  margin-right: 50px;
}

.admin-row:last-of-type {
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Home.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,gBAAA;AACJ;AACI;EACI,kBAAA;AACR;;AAGA;EACG,mBAAA;AAAH","sourcesContent":[".admin-row {\n    align-items: center;\n    display: flex;\n    margin-top: 40px;\n\n    .admin-link {\n        margin-right: 50px;\n    }\n}\n\n.admin-row:last-of-type {\n   margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
