// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.breadCrumb {
  background: white;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  color: #0669B3;
  padding: 0 12px 0 36px;
}

.breadCrumb-arrow {
  margin: 0 8px;
}

.breadCrumb-btn {
  background: transparent;
  border: none;
  color: #0669B3;
  font-weight: 500;
}

.breadCrumb-btn-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/BreadCrumb.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AAPA;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,cDoBS;ECnBT,sBAAA;AAUJ;;AAPA;EACI,aAAA;AAUJ;;AAPA;EACI,uBAAA;EACA,YAAA;EACA,cDSS;ECRT,gBAAA;AAUJ;;AAPA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAUJ","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// EPR colors\n// $dashboard-blue: #007bff; this color does not pass Accessibility. Updated version below.\n$dashboard-blue: #0d6efd;\n$dk-blue-toolbar: #19608f;\n$dk-blue: #0A4064;\n$lt-blue: #7DB7CC;\n$pale-blue: #c0def0;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$DCSD-logo-green: #84ae30;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$red-hover: #E2381D;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$lt-important: #FED883;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n\n$timeline-arrow-1: #718355;\n$timeline-arrow-2: #87986a;\n$timeline-arrow-3: #97a97c;\n$timeline-arrow-4: #b5c99a;\n$timeline-arrow-5: #cfe1b9;\n","@import \"../styles/global/Colors\";\n@import \"../styles/global/globalMixins\";\n\n.breadCrumb {\n    background: white;\n    font-size: 1.2rem;\n    font-weight: 700;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 60px;\n    color: $pearl-blue;\n    padding: 0 12px 0 36px;\n}\n\n.breadCrumb-arrow {\n    margin: 0 8px;\n}\n\n.breadCrumb-btn {\n    background: transparent;\n    border: none;\n    color: $pearl-blue;\n    font-weight: 500;\n}\n\n.breadCrumb-btn-group {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
