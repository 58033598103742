import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PropTypes from "prop-types";

import "../../styles/Timeline.scss";

/**
 * This is the Timeline component on school admin homepage
 * @name Timeline
 * @returns {JSX.Element}
 */
const Timeline = ({ loader, status, statusClass, timePeriodDto }) => {
    return (
        <div className="timeline-container">
            <div className="sub-section-title">
                Timeline{" "}
                {!loader && timePeriodDto ? (
                    <div className={`status-box ${statusClass}`}>Template Status: {status}</div>
                ) : (
                    <div style={{ height: "24px" }} />
                )}
            </div>

            <div className="sub-section-line" />
            <div className="fat-arrow-rappa">
                <div className="fat-arrow">
                    <OverlayTrigger
                        delay={{ hide: 5, show: 300 }}
                        overlay={(props) => (
                            <Tooltip className="tooltip-window" {...props}>
                                District Default Template - Aug - Sept
                            </Tooltip>
                        )}
                        placement="top"
                    >
                        <div className="flo-arrow one">01</div>
                    </OverlayTrigger>
                    <div className="inner">
                        <div>District Default Template</div>
                        <div className="timeline-date">Aug - Sept</div>
                    </div>
                </div>
                <div className="fat-arrow">
                    <OverlayTrigger
                        delay={{ hide: 5, show: 300 }}
                        overlay={(props) => (
                            <Tooltip className="tooltip-window" {...props}>
                                Create School Template - Sept
                            </Tooltip>
                        )}
                        placement="top"
                    >
                        <div className="flo-arrow two">02</div>
                    </OverlayTrigger>
                    <div className="inner">
                        <div>Create School Template</div>
                        <div className="timeline-date">Sept</div>
                    </div>
                </div>
                <div className="fat-arrow">
                    <OverlayTrigger
                        delay={{ hide: 5, show: 300 }}
                        overlay={(props) => (
                            <Tooltip className="tooltip-window" {...props}>
                                Define Reporting Periods - Oct
                            </Tooltip>
                        )}
                        placement="top"
                    >
                        <div className="flo-arrow three">03</div>
                    </OverlayTrigger>
                    <div className="inner">
                        <div>Define Reporting Periods</div>
                        <div className="timeline-date">Oct</div>
                    </div>
                </div>
                <div className="fat-arrow">
                    <OverlayTrigger
                        delay={{ hide: 5, show: 300 }}
                        overlay={(props) => (
                            <Tooltip className="tooltip-window" {...props}>
                                Teacher Work Period - Nov-Dec
                            </Tooltip>
                        )}
                        placement="top"
                    >
                        <div className="flo-arrow four">04</div>
                    </OverlayTrigger>
                    <div className="inner">
                        <div>Teacher Work Period</div>
                        <div className="timeline-date">Nov - Dec</div>
                    </div>
                </div>
                <div className="fat-arrow">
                    <OverlayTrigger
                        delay={{ hide: 5, show: 300 }}
                        overlay={(props) => (
                            <Tooltip className="tooltip-window" {...props}>
                                Availabe to Parents - Dec 21
                            </Tooltip>
                        )}
                        placement="top"
                    >
                        <div className="flo-arrow five">05</div>
                    </OverlayTrigger>
                    <div className="inner">
                        <div>Available to Parents</div>
                        <div className="timeline-date">Dec 21</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Timeline.propTypes = {
    loader: PropTypes.bool,
    status: PropTypes.string,
    statusClass: PropTypes.string,
    timePeriodDto: PropTypes.instanceOf(Array)
};

export default Timeline;
