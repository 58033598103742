export const getTouchedObject = (changes, templateContentId) => {
    return changes.find((changedObj) => parseInt(changedObj.key, 10) === parseInt(templateContentId, 10));
};

export const getSecondSemEo = (gradeObj, standardKey, gleKey, eoKey) => {
    return gradeObj.cdeStandards
        .find((std) => std.key === standardKey)
        .gles.find((semTwoGle) => semTwoGle.key === gleKey)
        .evidenceOutcomes.find((semTwoEo) => semTwoEo.key === eoKey);
};

export const getSecondSemChild = (gradeObj, standardKey, gleKey, eoKey, childKey) => {
    return gradeObj.cdeStandards
        .find((std) => std.key === standardKey)
        .gles.find((semTwoGle) => semTwoGle.key === gleKey)
        .evidenceOutcomes.find((semTwoEo) => semTwoEo.key === eoKey)
        .evidenceOutcomeChildList.find((semTwoChild) => semTwoChild.key === childKey);
};

export const getChecked = (touchedObj, obj) => {
    return touchedObj ? touchedObj.included : obj.included;
};

export const getFilteredArray = (array, dataKey) => {
    return array.filter((cKey) => {
        return parseInt(cKey, 10) !== parseInt(dataKey, 10);
    });
};
