import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import ActionButton from "../formInputs/buttons/ActionButton";
import StudentRosterTable from "./StudentRosterTable";

import "../../styles/HomeroomGroups.scss";

/**
 * HomeroomGroupForm component renders a form for creating or editing a homeroom group.
 * It includes fields for the group name, student selection, and action buttons.
 * The form appears inline during creation or in a modal during editing or when groups already exist.
 *
 * @component
 * @param {function} dispatch
 * @param {string} groupName
 * @param {function} handleCheckboxChange
 * @param {function} handleCreateGroup
 * @param {function} handleSelectAll
 * @param {function} handleUpdateGroup
 * @param {boolean} isEditMode
 * @param {function} onClose
 * @param {object} selectedEpr
 * @param {string[]} selectedStudents
 * @param {function} setGroupName
 * @param {function} setShow
 * @param {boolean} show
 * @param {object} state
 */
const HomeroomGroupForm = ({
    dispatch,
    groupName,
    handleCheckboxChange,
    handleCreateGroup,
    handleSelectAll,
    handleUpdateGroup,
    isEditMode,
    onClose,
    selectedEpr,
    selectedStudents,
    setGroupName,
    setShow,
    show,
    state
}) => {
    const formContent = (
        <div className="form-content">
            <section className="group-title-section">
                <label className="create-homeroom-label">
                    <span>Homeroom Group Name*: </span>
                    <input
                        className="create-homeroom-input"
                        type="text"
                        placeholder="Homeroom Group Name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </label>
            </section>
            <section>
                <StudentRosterTable
                    dispatch={dispatch}
                    gradingProfileStudents={selectedEpr.gradingProfileStudents}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectAll={handleSelectAll}
                    isGroup
                    selectedEpr={selectedEpr}
                    selectedStudents={selectedStudents}
                    state={state}
                />
            </section>
            <div className="group-form-buttons">
                {(isEditMode || selectedEpr.homeroomGroups.length > 0) && (
                    <ActionButton
                        className="action-button-cancel"
                        label="Close"
                        onClick={isEditMode ? onClose : () => setShow(false)}
                    />
                )}

                <ActionButton
                    className="action-button-reg"
                    disable={groupName.trim().length === 0 || selectedStudents.length === 0}
                    label={isEditMode ? "Save" : "Create Group"}
                    onClick={isEditMode ? handleUpdateGroup : handleCreateGroup}
                />
            </div>
        </div>
    );

    if (isEditMode || selectedEpr?.homeroomGroups.length > 0) {
        return (
            <Modal show={show} onHide={onClose} size="xl" backdrop="static">
                <Modal.Header>
                    <Modal.Title>{isEditMode ? "Edit" : "Create"} Homeroom Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>{formContent}</Modal.Body>
            </Modal>
        );
    }

    return formContent;
};

HomeroomGroupForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    groupName: PropTypes.string.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleCreateGroup: PropTypes.func,
    handleSelectAll: PropTypes.func.isRequired,
    handleUpdateGroup: PropTypes.func,
    isEditMode: PropTypes.bool,
    onClose: PropTypes.func,
    selectedEpr: PropTypes.object.isRequired,
    selectedStudents: PropTypes.array.isRequired,
    setGroupName: PropTypes.func.isRequired,
    setShow: PropTypes.func,
    show: PropTypes.bool,
    state: PropTypes.object.isRequired
};

HomeroomGroupForm.defaultProps = {
    handleCreateGroup: null,
    handleUpdateGroup: null,
    setShow: null
};

export default HomeroomGroupForm;
