import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MathJax } from "better-react-mathjax";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import DefineInputGroup from "./DefineInputGroup";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
import LoadingSvg from "../loadingSvg/LoadingSvg";

import {
    getChecked,
    getFilteredArray,
    getSecondSemChild,
    getSecondSemEo,
    getTouchedObject
} from "../../utils/SchoolAdmin/defineReportingPeriodUtils";
import { GlobalContext } from "../contextProvider/ContextProvider";
import { gradeTranslation } from "../../utils/SchoolAdmin/translations.jsx";

import "../../styles/DefineReportingPeriods.scss";

/**
 * This is the Define By Subject component for epr
 * Part of the define reporting periods functionality for school admin
 * @name DefineBySubject
 * @param {[]} combinedGrades
 * @param {bool} isApplicableGrade
 * @param {bool} isReleased
 * @param {string} selectedContentArea
 * @param {string} selectedGrade
 * @param {{}} selectedGradeObject
 * @param {func} setLabelObj
 * @param {func} setSelectedContentArea
 * @param {func} setSelectedGradeObject
 * @param {func} setSelectedLink
 * @param {{}} subject
 * @returns {JSX.Element}
 */
const DefineBySubject = ({
    combinedGrades,
    isApplicableGrade,
    isReleased,
    selectedContentArea,
    selectedGrade,
    selectedGradeObject,
    setLabelObj,
    setSelectedContentArea,
    setSelectedGradeObject,
    setSelectedLink,
    setTemplateTouchedFlag,
    subject: { cdeStandards, completed, subjectId, subjectName, profileSubjectId }
}) => {
    const { dispatch, state } = useContext(GlobalContext);
    const { token, userDetails } = state;
    const navigate = useNavigate();

    const [changes, setChanges] = useState([]);
    const [bothUnchecked, setBothUnchecked] = useState([]);
    const [notReported, setNotReported] = useState([]);
    const [valid, setValid] = useState(true);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState("false");

    const secondSemesterProfiles = selectedGradeObject.semesterProfiles.secondSem.subjectToStandards;
    const secondSemesterProfileSelectedGrade = secondSemesterProfiles.find((secSub) => secSub.subjectId === subjectId);
    const yearComplete = completed === true && secondSemesterProfileSelectedGrade.completed === true;

    const handleOnChange = (event) => {
        const tempChanges = [...changes];
        const { name, value } = event.target;
        const previousVal = value === "false" ? false : true;
        const prevChange = tempChanges.find((prevChange) => prevChange.key === name);
        const changedObj = { key: name, included: !previousVal };

        if (prevChange) {
            const filteredChanges = tempChanges.filter((obj) => {
                return obj.key !== name;
            });
            setChanges(filteredChanges);
        } else {
            setChanges([...changes, changedObj]);
        }
        if (previousVal === false) {
            handleUncheckNotReported(event.target.dataset.contentkey);
        }
    };

    const handleOnClick = (linkName) => {
        const array = linkName.split("/").map((str) => {
            return { label: str, path: `/${str}` };
        });
        setSelectedLink(linkName);
        setLabelObj(array);
        navigate(`/${linkName.split(" ")[0].toLowerCase()}`);
    };

    const handleComplete = (currentCompletedState, firstSemProfileSubjectId, secondSemProfileSubjectId) => {
        if (isReleased) {
            setOpen("confirm-unrelease");
            return;
        }
        if (changes.length > 0) {
            handleSaveAndComplete(currentCompletedState, firstSemProfileSubjectId, secondSemProfileSubjectId);
            return;
        }
        handleCompleteBothSemesters(currentCompletedState, firstSemProfileSubjectId, secondSemProfileSubjectId);
    };

    const handleUpdateFlags = () => {
        const options = {
            action: "updateSchoolTemplateIncludedFlag",
            params: {
                lastUpdaterGuid: userDetails.uid
            },
            data: changes,
            token
        };
        setLoader(true);

        ElementaryProgressReportDao(options).then((response) => {
            if (response && !response.errors) {
                toast.success(`Reporting periods changes saved successfully.`, {
                    autoClose: 5000
                });
                setChanges([]);
                dispatch({
                    type: "SchoolLevelTemplateDto",
                    schoolLevelTemplateDto: null
                });
                setTemplateTouchedFlag(true);
            } else {
                toast.error(`Reporting periods changes saved could not be saved.`, {
                    autoClose: false
                });
            }
            setLoader(false);
        });
    };

    const handleSaveAndComplete = (currentCompletedState, firstSemProfileSubjectId, secondSemProfileSubjectId) => {
        const updateFlagOptions = {
            action: "updateSchoolTemplateIncludedFlag",
            params: {
                lastUpdaterGuid: userDetails.uid
            },
            data: changes,
            token
        };
        ElementaryProgressReportDao(updateFlagOptions).then((response) => {
            if (response && !response.errors) {
                toast.success(`Reporting periods changes saved successfully.`, {
                    autoClose: 5000
                });
                handleCompleteBothSemesters(currentCompletedState, firstSemProfileSubjectId, secondSemProfileSubjectId);
            }
        });
    };

    const handleCompleteBothSemesters = (
        currentCompletedState,
        firstSemProfileSubjectId,
        secondSemProfileSubjectId
    ) => {
        setLoader(true);
        const firstSemProfileGradeId = selectedGradeObject.semesterProfiles.firstSem.profileGradeId;
        const secondSemProfileGradeId = selectedGradeObject.semesterProfiles.secondSem.profileGradeId;

        const firstSemOptions = {
            action: "completeSubjectGradeTemplate",
            guid: userDetails.uid,
            profileGradeId: firstSemProfileGradeId,
            profileSubjectIds: `${firstSemProfileSubjectId}`,
            isCompleted: !currentCompletedState,
            token
        };
        ElementaryProgressReportDao(firstSemOptions).then((response) => {
            if (response) {
                const secondSemOptions = {
                    action: "completeSubjectGradeTemplate",
                    guid: userDetails.uid,
                    profileGradeId: secondSemProfileGradeId,
                    profileSubjectIds: `${secondSemProfileSubjectId}`,
                    isCompleted: !currentCompletedState,
                    token
                };
                ElementaryProgressReportDao(secondSemOptions).then((response) => {
                    const gradeDisplayName =
                        selectedGrade === "K" ? "kindergarten" : `${gradeTranslation[selectedGrade]} grade`;
                    if (response) {
                        toast.success(
                            currentCompletedState === true
                                ? `Successfully marked ${selectedContentArea} for ${gradeDisplayName} as incomplete.`
                                : `${selectedContentArea} for ${gradeDisplayName} successfully marked complete.`,
                            {
                                autoClose: 3000
                            }
                        );
                        setSelectedContentArea("");
                        setChanges([]);
                        dispatch({
                            type: "SchoolLevelTemplateDto",
                            schoolLevelTemplateDto: null
                        });
                        setTemplateTouchedFlag(true);
                        setTimeout(() => setLoader(false), 1000);
                    }
                });
            }
        });
    };

    const handleUncheckNotReported = (dataContentKey) => {
        const tempNotReported = [...notReported];
        const filteredNotReported = getFilteredArray(tempNotReported, dataContentKey);
        setNotReported(filteredNotReported);
    };

    const handleNotReported = (contentKey) => {
        const tempNotReported = [...notReported];
        if (notReported.includes(contentKey)) {
            const filteredNotReported = getFilteredArray(tempNotReported, contentKey);
            setNotReported(filteredNotReported);
        } else {
            setNotReported([...tempNotReported, contentKey]);
        }
    };

    const validateRow = (firstSemChecked, secondSemChecked, contentKey) => {
        if (!firstSemChecked && !secondSemChecked && !bothUnchecked.includes(contentKey)) {
            setBothUnchecked([...bothUnchecked, contentKey]);
        }
        if (!firstSemChecked && !secondSemChecked && changes.length === 0 && !notReported.includes(contentKey)) {
            setNotReported([...notReported, contentKey]);
        }
        if ((firstSemChecked || secondSemChecked) && bothUnchecked.includes(contentKey)) {
            const tempBothUnchecked = [...bothUnchecked];
            const filteredBothUnchecked = getFilteredArray(tempBothUnchecked, contentKey);
            setBothUnchecked(filteredBothUnchecked);
        }
    };

    /**
     * Determine if form has errors or is valid
     */
    useEffect(() => {
        if (bothUnchecked.length > 0 && bothUnchecked.length !== notReported.length) {
            setValid(false);
        } else {
            setValid(true);
        }
    }, [bothUnchecked, notReported]);

    /**
     * Get the selected grades data
     */
    useEffect(() => {
        if (combinedGrades && selectedGrade) {
            const selectedGradeObject = combinedGrades.find((grade) => grade.grade === selectedGrade);
            setSelectedGradeObject(selectedGradeObject);
        }
    }, [combinedGrades, selectedGrade, setSelectedGradeObject]);

    /**
     * Scroll to clicked content area
     */
    useEffect(() => {
        if (selectedContentArea) {
            const element = document.getElementById(selectedContentArea);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [selectedContentArea]);

    return (
        <div key={subjectId}>
            <button
                id={subjectName}
                onClick={() => {
                    if (selectedContentArea !== subjectName) {
                        setSelectedContentArea(subjectName);
                    } else {
                        setSelectedContentArea("");
                    }
                }}
                className={selectedContentArea !== subjectName ? "subject-name" : "selected-subject-name"}
            >
                {subjectName}
                <div className="complete-centered-tag">
                    {isApplicableGrade && (
                        <div className={`secondary-text-define ${yearComplete ? "complete-text-green" : ""}`}>
                            {yearComplete ? "Completed" : "Incomplete"}
                        </div>
                    )}
                    <i className={selectedContentArea !== subjectName ? "bi bi-chevron-down" : "bi bi-chevron-up"}></i>
                </div>
            </button>
            {selectedContentArea === subjectName && cdeStandards.length === 0 && (
                <div className="open-content-area">
                    <div className="header-define-table">
                        <div className="standard-row">
                            <span className="no-reportable-content-message">
                                No reportable content defined on district template for this subject.
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {selectedContentArea === subjectName && cdeStandards.length > 0 && (
                <div className="open-content-area">
                    <div className="header-define-table">
                        <div className="standard-row">
                            <span className="subject-name-header">{subjectName} - Reportable Content</span>
                            {isApplicableGrade && (
                                <div className="standard-check-container">
                                    <div className="header">1st</div>
                                    <div className="header">2nd</div>
                                    <div className="header">NR</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {cdeStandards.map((standard, index) => {
                        const firstSemStdTouched = getTouchedObject(changes, standard.templateContentId);
                        const firstSemStdChecked = getChecked(firstSemStdTouched, standard);

                        const secSemStd = secondSemesterProfileSelectedGrade.cdeStandards.find(
                            (std) => std.gradeToContentKey === standard.gradeToContentKey
                        );
                        const secSemStdTouched = getTouchedObject(changes, secSemStd.templateContentId);
                        const secSemStdChecked = getChecked(secSemStdTouched, secSemStd);
                        const displayStandardName = standard.standardName.substring(
                            standard.standardName.indexOf(".") + 1
                        );

                        standard.reportFlag === true &&
                            validateRow(firstSemStdChecked, secSemStdChecked, standard.gradeToContentKey);

                        const isError =
                            bothUnchecked.includes(standard.gradeToContentKey) &&
                            !notReported.includes(standard.gradeToContentKey);

                        return (
                            <div key={standard.key}>
                                <div className="standard-row std">
                                    <span className="standard-name-define">
                                        Standard {index + 1}: {displayStandardName}
                                    </span>
                                    {isApplicableGrade && (
                                        <DefineInputGroup
                                            firstSemChecked={firstSemStdChecked}
                                            firstSemContentKey={standard.gradeToContentKey}
                                            firstSemTemplateContentId={standard.templateContentId}
                                            handleNotReported={handleNotReported}
                                            handleOnChange={handleOnChange}
                                            isError={isError}
                                            notReported={notReported}
                                            reportFlag={standard.reportFlag}
                                            secondSemChecked={secSemStdChecked}
                                            secondSemContentKey={secSemStd.gradeToContentKey}
                                            secondSemTemplateContentId={secSemStd.templateContentId}
                                            yearComplete={yearComplete}
                                        />
                                    )}
                                </div>
                                {standard.gles.map((gle) => {
                                    const firstSemGleTouched = getTouchedObject(changes, gle.templateContentId);
                                    const firstSemGleChecked = getChecked(firstSemGleTouched, gle);

                                    const secSemGle = secondSemesterProfileSelectedGrade.cdeStandards
                                        .find((std) => std.key === standard.key)
                                        .gles.find((semTwoGle) => semTwoGle.key === gle.key);

                                    const secSemGleTouched = getTouchedObject(changes, secSemGle.templateContentId);

                                    const secSemGleChecked = getChecked(secSemGleTouched, secSemGle);
                                    const displayGleName = gle.gleName.substring(gle.gleName.indexOf(".") + 1);

                                    gle.reportFlag === true &&
                                        validateRow(firstSemGleChecked, secSemGleChecked, gle.key);

                                    const isError = bothUnchecked.includes(gle.key) && !notReported.includes(gle.key);
                                    return (
                                        <div key={gle.key} style={{ marginLeft: "40px" }}>
                                            <div className="standard-row">
                                                <span className="gle-name">
                                                    {gle.gleShortName}: {displayGleName}
                                                </span>
                                                {isApplicableGrade && (
                                                    <DefineInputGroup
                                                        firstSemChecked={firstSemGleChecked}
                                                        firstSemContentKey={gle.key}
                                                        firstSemTemplateContentId={gle.templateContentId}
                                                        handleNotReported={handleNotReported}
                                                        handleOnChange={handleOnChange}
                                                        isError={isError}
                                                        notReported={notReported}
                                                        reportFlag={gle.reportFlag}
                                                        secondSemChecked={secSemGleChecked}
                                                        secondSemContentKey={secSemGle.key}
                                                        secondSemTemplateContentId={secSemGle.templateContentId}
                                                        yearComplete={yearComplete}
                                                    />
                                                )}
                                            </div>
                                            <ul style={{ marginLeft: "40px" }}>
                                                {gle.evidenceOutcomes?.map((eo) => {
                                                    const firstSemEoTouched = getTouchedObject(
                                                        changes,
                                                        eo.templateContentId
                                                    );
                                                    const firstSemEoChecked = getChecked(firstSemEoTouched, eo);

                                                    const secSemEo = getSecondSemEo(
                                                        secondSemesterProfileSelectedGrade,
                                                        standard.key,
                                                        gle.key,
                                                        eo.key
                                                    );
                                                    const secSemEoTouched = getTouchedObject(
                                                        changes,
                                                        secSemEo.templateContentId
                                                    );

                                                    const secSemEoChecked = getChecked(secSemEoTouched, secSemEo);

                                                    eo.reportFlag === true &&
                                                        validateRow(firstSemEoChecked, secSemEoChecked, eo.key);

                                                    const isError =
                                                        bothUnchecked.includes(eo.key) && !notReported.includes(eo.key);

                                                    return (
                                                        <li key={eo.key}>
                                                            <div className="standard-row">
                                                                {eo && eo.body && subjectId === 3 ? (
                                                                    <MathJax>
                                                                        <span
                                                                            className="eo-name"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: eo.body
                                                                            }}
                                                                        ></span>
                                                                    </MathJax>
                                                                ) : (
                                                                    eo &&
                                                                    eo.body && (
                                                                        <span
                                                                            className="eo-name"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: eo.body
                                                                            }}
                                                                        ></span>
                                                                    )
                                                                )}
                                                                {isApplicableGrade && (
                                                                    <DefineInputGroup
                                                                        firstSemChecked={firstSemEoChecked}
                                                                        firstSemContentKey={eo.key}
                                                                        firstSemTemplateContentId={eo.templateContentId}
                                                                        handleNotReported={handleNotReported}
                                                                        handleOnChange={handleOnChange}
                                                                        isError={isError}
                                                                        notReported={notReported}
                                                                        reportFlag={eo.reportFlag}
                                                                        secondSemChecked={secSemEoChecked}
                                                                        secondSemContentKey={secSemEo.key}
                                                                        secondSemTemplateContentId={
                                                                            secSemEo.templateContentId
                                                                        }
                                                                        yearComplete={yearComplete}
                                                                    />
                                                                )}
                                                            </div>
                                                            <ul>
                                                                {eo.evidenceOutcomeChildList?.map((eoChild) => {
                                                                    const { body, key, reportFlag, templateContentId } =
                                                                        eoChild;
                                                                    const firstSemChildTouched = getTouchedObject(
                                                                        changes,
                                                                        templateContentId
                                                                    );
                                                                    const firstSemChildChecked = getChecked(
                                                                        firstSemChildTouched,
                                                                        eoChild
                                                                    );

                                                                    const secSemChild = getSecondSemChild(
                                                                        secondSemesterProfileSelectedGrade,
                                                                        standard.key,
                                                                        gle.key,
                                                                        eo.key,
                                                                        key
                                                                    );
                                                                    const secSemChildTouched = getTouchedObject(
                                                                        changes,
                                                                        secSemChild.templateContentId
                                                                    );

                                                                    const secSemChildChecked = getChecked(
                                                                        secSemChildTouched,
                                                                        secSemChild
                                                                    );

                                                                    reportFlag === true &&
                                                                        validateRow(
                                                                            firstSemChildChecked,
                                                                            secSemChildChecked,
                                                                            key
                                                                        );

                                                                    const isError =
                                                                        bothUnchecked.includes(key) &&
                                                                        !notReported.includes(key);

                                                                    return (
                                                                        <li key={eoChild.key}>
                                                                            <div className="standard-row">
                                                                                {eoChild && body && subjectId === 3 ? (
                                                                                    <MathJax>
                                                                                        <span
                                                                                            className="eoChild-name"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: body
                                                                                            }}
                                                                                        ></span>
                                                                                    </MathJax>
                                                                                ) : (
                                                                                    eoChild &&
                                                                                    body && (
                                                                                        <span
                                                                                            className="eoChild-name"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: body
                                                                                            }}
                                                                                        ></span>
                                                                                    )
                                                                                )}
                                                                                {isApplicableGrade && (
                                                                                    <DefineInputGroup
                                                                                        firstSemChecked={
                                                                                            firstSemChildChecked
                                                                                        }
                                                                                        firstSemContentKey={key}
                                                                                        firstSemTemplateContentId={
                                                                                            templateContentId
                                                                                        }
                                                                                        handleNotReported={
                                                                                            handleNotReported
                                                                                        }
                                                                                        handleOnChange={handleOnChange}
                                                                                        isError={isError}
                                                                                        notReported={notReported}
                                                                                        reportFlag={reportFlag}
                                                                                        secondSemChecked={
                                                                                            secSemChildChecked
                                                                                        }
                                                                                        secondSemContentKey={
                                                                                            secSemChild.key
                                                                                        }
                                                                                        secondSemTemplateContentId={
                                                                                            secSemChild.templateContentId
                                                                                        }
                                                                                        yearComplete={yearComplete}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    {isApplicableGrade && (
                        <div className="profile-button-container">
                            <ActionButton
                                className="action-button-cancel"
                                disable={yearComplete || changes.length === 0}
                                label="Cancel Changes"
                                onClick={() => {
                                    setChanges([]);
                                }}
                            />
                            <ActionButton
                                className="action-button-reg"
                                disable={yearComplete || !valid}
                                label="Save"
                                onClick={() => handleUpdateFlags()}
                            />
                            <div className="define-complete-check-wrapper">
                                <label
                                    className={`define-complete-check-btn ${!valid && "disabled-save-and-complete"}`}
                                    disabled={!valid}
                                >
                                    <input
                                        type="checkbox"
                                        checked={yearComplete}
                                        disabled={!valid}
                                        onChange={() =>
                                            handleComplete(
                                                yearComplete,
                                                profileSubjectId,
                                                secondSemesterProfileSelectedGrade.profileSubjectId
                                            )
                                        }
                                    />
                                    <span>{yearComplete ? "Completed" : "Save and Complete"}</span>
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {loader && <LoadingSvg />}
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label="Confirm"
                            onClick={() => {
                                handleOnClick("HOME/TEMPLATE");
                            }}
                        />
                    </div>
                }
                ariaLabel="Confirmation Unrelease Dialog"
                hasCloseX={false}
                id="confirm-unrelease"
                open={open}
                title={<span className="unrelease-dialog-title">Manage Reporting Period(s) Alert</span>}
            >
                <div>
                    In order to make changes to the reporting period for one or more content areas, you must first
                    unrelease the grade-level template.{" "}
                    <span className="unrelease-dialog-imp-text">
                        Click confirm to return to the manage school template page.
                    </span>
                </div>
            </DcsdDialog>
        </div>
    );
};

DefineBySubject.propTypes = {
    combinedGrades: PropTypes.instanceOf(Array),
    isApplicableGrade: PropTypes.bool,
    isReleased: PropTypes.bool,
    selectedContentArea: PropTypes.string,
    selectedGrade: PropTypes.string,
    selectedGradeObject: PropTypes.oneOfType([PropTypes.object]),
    setLabelObj: PropTypes.func,
    setSelectedContentArea: PropTypes.func,
    setSelectedGradeObject: PropTypes.func,
    setSelectedLink: PropTypes.func,
    setTemplateTouchedFlag: PropTypes.func,
    subject: PropTypes.oneOfType([PropTypes.object])
};

export default DefineBySubject;
