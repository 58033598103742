import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import "../../styles/BreadCrumb.scss";
import DcsdDialog from "../modals/DcsdDialog";
import ActionButton from "../formInputs/buttons/ActionButton";

const BreadCrumb = ({ labelObj, setSelectedLink }) => {
    const [open, setOpen] = useState("false");
    const [clickedPath, setClickedPath] = useState("");
    const [updatedLabelObj, setUpdatedLabelObj] = useState(labelObj);

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const removeValuesAfterString = (arr, str) => {
        const index = arr.indexOf(str);
        if (index !== -1) {
            return arr.slice(0, index + 1);
        } else {
            return arr;
        }
    };

    const handleOnClick = (path, label) => {
        if (setSelectedLink) {
            if (label.toUpperCase() !== "HOME") {
                const arrayOfLabels = labelObj.map((obj) => obj.label.toUpperCase());
                const neededValues = removeValuesAfterString(arrayOfLabels, label.toUpperCase());
                const newLabelObj = labelObj.filter((obj) => neededValues.includes(obj.label));
                setUpdatedLabelObj(newLabelObj);
                setSelectedLink(neededValues.join("/"));
                const newPath = neededValues.join("/").split(" ")[0].toLowerCase();
                navigate(`/${newPath}`);
            } else {
                setUpdatedLabelObj([{ label: "Home", path: "/home" }]);
                setSelectedLink("HOME");
                navigate("/home");
            }
            return;
        }
        if (path !== pathname) {
            setOpen("navigate");
            setClickedPath(path);
        }
    };
    const capitalize = (words) => {
        if (words === "sles") {
            return "SLEs";
        }
        if (words === "summary") {
            return "EPR Summary";
        }

        return words
            .split(/[\s-]+/)
            .map((word) => {
                return word[0].toUpperCase() + word.substring(1);
            })
            .join(" ");
    };

    useEffect(() => {
        setUpdatedLabelObj(labelObj);
    }, [labelObj]);

    /**
     * Based on the url path create a functional breadcrumb (for refreshes etc)
     */
    useEffect(() => {
        if (pathname !== "/") {
            const pathArray = pathname.split("/").slice(1);
            const stringOnlyPathArray = pathArray.filter((str) => isNaN(Number(str)));
            const newLabelObj = stringOnlyPathArray.map((pathString) => {
                let capString = pathString.toUpperCase();
                if (capString === "DEFINE") {
                    capString = "DEFINE REPORTING PERIODS";
                }
                return { label: capString, path: `/${capString}` };
            });
            setUpdatedLabelObj(newLabelObj);
        }
    }, [pathname]);

    return (
        <div className="breadCrumb">
            <DcsdDialog
                actions={
                    <div className="breadCrumb-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label="Continue"
                            onClick={() => navigate(clickedPath)}
                        />
                    </div>
                }
                ariaLabel="Navigate Away Dialog"
                hasCloseX={false}
                id="navigate"
                open={open}
                title={<span style={{ color: "#CB321A" }}>Warning &ndash; Navigate Away</span>}
            >
                <div>You are about to navigate away from this page. Any unsaved work will be lost.</div>
            </DcsdDialog>
            {updatedLabelObj.map((obj, index) => {
                return (
                    <Fragment key={obj.label}>
                        <button className="breadCrumb-btn" onClick={() => handleOnClick(obj.path, obj.label)}>
                            {setSelectedLink ? capitalize(obj.label.toLowerCase()) : obj.label}
                        </button>
                        {index !== updatedLabelObj.length - 1 && <div className="breadCrumb-arrow">{">"}</div>}
                    </Fragment>
                );
            })}
        </div>
    );
};

BreadCrumb.propTypes = {
    labelObj: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        })
    ).isRequired,
    setSelectedLink: PropTypes.func
};

BreadCrumb.defaultProps = {
    labelObj: {
        label: "Home",
        path: "/home"
    }
};

export default BreadCrumb;
