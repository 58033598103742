import React, { useContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
import LoadingSvg from "../loadingSvg/LoadingSvg";
import StudentRosterTable from "./StudentRosterTable";
import TabButton from "../formInputs/buttons/TabButton";
import TeacherInfoDao from "../../dao/TeacherInfoDao";

import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/ManageRoster.scss";
import "../../styles/TeacherHome.scss";

/**
 * ManageRoster component allows users to view and manage students in an EPR (Elementary Progress Report) roster.
 * Users can select an IC section, import students, and update their grading profiles.
 *
 * @component
 * @param {Object} props
 * @param {Function} dispatch
 * @param {Object} state
 * @returns {JSX.Element}
 */
const ManageRoster = ({ dispatch, state }) => {
    const { gradingProfileId } = useParams();
    const { gradingProfiles, selectedEpr } = state;

    const { state: globalState } = useContext(GlobalContext);
    const { currentTimePeriodDto, userDetails, schoolYearDto, token } = globalState || {};

    const [currentRosteredSection, setCurrentRosteredSection] = useState(null);
    const [deltaStudents, setDeltaStudents] = useState([]);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState("false");
    const [showSectionSelect, setShowSectionSelect] = useState(true);
    const [sectionListDto, setSectionListDto] = useState(null);
    const [selectedRoster, setSelectedRoster] = useState("");
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [studentsNotAdded, setStudentsNotAdded] = useState(null);

    /**
     * Extracts section IDs already assigned to students in the selected EPR.
     */
    const usedSections = useMemo(() => {
        return selectedEpr?.gradingProfileStudents?.map((student) => Number(student.sectionId)) || [];
    }, [selectedEpr]);

    /**
     * Filters out sections already assigned to the selected EPR.
     */
    const filteredSections = useMemo(() => {
        return sectionListDto?.filter((section) => !usedSections.includes(section.id)) || [];
    }, [sectionListDto, usedSections]);

    /**
     * Determines if multiple grading profiles exist, requiring a selection toggle.
     */
    const shouldShowEprSelect = useMemo(() => {
        return !gradingProfileId && gradingProfiles?.length > 1;
    }, [gradingProfileId, gradingProfiles]);

    /**
     * Sets the selected EPR profile.
     * @param {Object} profile
     */
    const handleEprChange = (profile) => {
        dispatch({ type: "SET_SELECTED_EPR", payload: profile });
        setDeltaStudents([]);
        setShowSectionSelect(true);
    };

    /**
     * Handles changes to the selected IC roster.
     * @param {Object} e
     */
    const handleRosterChange = (e) => {
        setSelectedRoster(e.target.value);
    };

    /**
     * Handles importing an IC section into the grading profile.
     * If a roster already exists, prompts for confirmation before replacing it.
     */
    const handleImportICSection = () => {
        if (selectedEpr?.gradingProfileStudents?.length > 0) {
            setOpen("confirm-replace-roster");
        } else {
            importNewSection();
        }
    };

    const handleClearAndImport = () => {
        setDeltaStudents([]);
        setStudentsNotAdded([]);
        setOpen("false");
        const clearOptions = {
            action: "clearStudentsFromProfile",
            gradingProfile: selectedEpr.key,
            token
        };

        ElementaryProgressReportDao(clearOptions).then(() => {
            importNewSection();
        });
    };

    /**
     * Imports a new section by fetching students from the IC system.
     */
    const importNewSection = () => {
        const secId = sectionListDto.find((sec) => sec.sectionCourseName === selectedRoster)?.id;
        if (!secId) return;

        const options = {
            action: "rosterBySectionRead",
            sectionId: secId,
            token
        };

        TeacherInfoDao(options).then((response) => {
            if (response) {
                const { payload } = response.data;
                const arrayOfStudents = payload.sectionRoster.map((student) => ({
                    key: null,
                    gradingProfile: selectedEpr.key,
                    studentNumber: student.studentNumber,
                    sectionId: secId
                }));

                const addStudentsOptions = {
                    action: "addStudentsToProfile",
                    gradingProfile: selectedEpr.key,
                    data: arrayOfStudents,
                    token
                };

                ElementaryProgressReportDao(addStudentsOptions).then((response) => {
                    if (response?.data?.payload) {
                        const updatedProfile = response.data.payload.gradingProfileDto;
                        const studentsNotAddedInRoster = response.data.payload.studentsNotAddedInRoster;

                        dispatch({ type: "SET_SELECTED_EPR", payload: updatedProfile });
                        dispatch({
                            type: "SET_PROFILES",
                            payload: state.gradingProfiles.map((profile) =>
                                profile.key === updatedProfile.key ? updatedProfile : profile
                            )
                        });
                        if (studentsNotAddedInRoster && studentsNotAddedInRoster.length > 0) {
                            setStudentsNotAdded(studentsNotAddedInRoster);
                            setOpen("student-not-added");
                        }
                    }
                });
            }
        });
    };

    const handleAddStudents = () => {
        const arrayOfStudents = selectedStudents.map((student) => ({
            key: null,
            gradingProfile: selectedEpr.key,
            studentNumber: student,
            sectionId: currentRosteredSection.id
        }));

        const options = {
            action: "addStudentsToProfile",
            gradingProfile: selectedEpr.key,
            data: arrayOfStudents,
            token
        };
        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data?.payload) {
                const updatedProfile = response.data.payload.gradingProfileDto;
                const studentsNotAddedInRoster = response.data.payload.studentsNotAddedInRoster;

                dispatch({ type: "SET_SELECTED_EPR", payload: updatedProfile });
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedProfile.key ? updatedProfile : profile
                    )
                });
                if (studentsNotAddedInRoster && studentsNotAddedInRoster.length > 0) {
                    setStudentsNotAdded(studentsNotAddedInRoster);
                    setOpen("student-not-added");
                }
                setSelectedStudents([]);
            }
        });
    };

    /**
     * Finds the homeroom section that matches the selected grading profile.
     * @param {Array} sections
     * @param {Object} gradingProfileInfo
     * @returns {Object|null}
     */
    const findMatchingHomeroom = (sections, gradingProfileInfo) => {
        let gradeNumber;

        if (gradingProfileInfo.gradeName.toUpperCase() === "K") {
            gradeNumber = "00";
        } else {
            gradeNumber = gradingProfileInfo.gradeName.replace(/\D/g, "").padStart(2, "0");
        }

        return sections.find(
            (section) =>
                section.sectionCourseName.includes("Homeroom") && section.sectionCourseNumber.slice(-2) === gradeNumber
        );
    };

    const handleSelectAll = () => {
        if (selectedStudents.length === deltaStudents.length) {
            setSelectedStudents([]);
        } else {
            setSelectedStudents(deltaStudents.map((student) => student.studentNumber));
        }
    };

    const handleCheckboxChange = (id) => {
        setSelectedStudents((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((studentId) => studentId !== id) : [...prevSelected, id]
        );
    };

    /**
     * Selects the appropriate homeroom section based on the selected EPR.
     */
    useEffect(() => {
        if (!selectedRoster && selectedEpr && sectionListDto?.length > 0) {
            const matchedHomeroom = findMatchingHomeroom(filteredSections, selectedEpr);
            const selectedIcRoster =
                selectedEpr.gradingProfileStudents.length > 0 ? "" : matchedHomeroom?.sectionCourseName;
            setSelectedRoster(selectedIcRoster || "");
        }
    }, [filteredSections, sectionListDto?.length, selectedEpr, selectedRoster]);

    /**
     * Loads available IC sections when the component mounts.
     */
    useEffect(() => {
        if (!sectionListDto && token) {
            setLoader(true);
            const options = {
                action: "teacherSectionsRead",
                username: userDetails.username,
                token
            };
            TeacherInfoDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    const uniqueSectionList = payload.sectionList.filter((obj, index) => {
                        return index === payload.sectionList.findIndex((o) => obj.id === o.id);
                    });
                    setSectionListDto(uniqueSectionList);
                    setLoader(false);
                }
            });
        }
    }, [sectionListDto, token, userDetails]);

    /**
     * Sets the default selected EPR profile when the component mounts.
     */
    useEffect(() => {
        if (!selectedEpr && gradingProfiles.length > 0) {
            const profileToSet = gradingProfileId
                ? gradingProfiles.find((profile) => parseInt(profile.key, 10) === parseInt(gradingProfileId, 10))
                : gradingProfiles[0];

            dispatch({ type: "SET_SELECTED_EPR", payload: profileToSet });
        }
    }, [gradingProfileId, gradingProfiles, selectedEpr, dispatch]);

    /**
     * Finds missing students that exist in IC but not in EPR roster
     */
    useEffect(() => {
        if (selectedEpr) {
            const options = {
                action: "getStudentRosterDelta",
                gradingProfile: selectedEpr.key,
                token
            };
            ElementaryProgressReportDao(options).then((response) => {
                if (response?.data?.payload) {
                    setDeltaStudents(response.data.payload.sectionRoster);
                }
            });
        }
    }, [selectedEpr, token]);

    /**
     * Sets current rostered section name in state and hides select
     */
    useEffect(() => {
        if (
            selectedEpr &&
            selectedEpr.gradingProfileStudents &&
            selectedEpr.gradingProfileStudents.length > 0 &&
            sectionListDto
        ) {
            const currentSectionId = parseInt(selectedEpr.gradingProfileStudents[0].sectionId, 10);
            const currentSection = sectionListDto.find((sec) => sec.id === currentSectionId);
            setCurrentRosteredSection(currentSection);
            setShowSectionSelect(false);
        }
    }, [sectionListDto, selectedEpr]);

    return (
        <div className="right-side-wrapper">
            <div className="right-page-title">
                <h4>
                    EPR Roster{" "}
                    {selectedEpr && (
                        <span>
                            &ndash; {selectedEpr.gradeName === "K" ? "Kindergarten" : `${selectedEpr.gradeName} Grade`}
                        </span>
                    )}
                </h4>
                <h5>
                    <span style={{ fontWeight: "700" }}>{currentTimePeriodDto.name}</span> | {schoolYearDto.name}
                </h5>
            </div>
            <hr />
            {!gradingProfiles ||
                (gradingProfiles.length === 0 && (
                    <div>
                        Roster management is unavailable until a Homeroom EPR has been created. If your school has
                        released the grade you teach, please first create a Homeroom EPR. Otherwise, please wait until
                        it becomes available.
                    </div>
                ))}
            {gradingProfiles && gradingProfiles.length > 0 && (
                <>
                    <section className="epr-roster-manage-section">
                        {shouldShowEprSelect && (
                            <div>
                                <h6>Select an EPR</h6>
                                <div className="epr-toggle-container">
                                    {gradingProfiles
                                        .sort((a, b) => a.grade - b.grade)
                                        .map((profile) => (
                                            <TabButton
                                                key={profile.key}
                                                className={
                                                    selectedEpr.key === profile.key
                                                        ? "tab-button-selected"
                                                        : "tab-button"
                                                }
                                                label={
                                                    profile.gradeName === "K"
                                                        ? "Kindergarten"
                                                        : `${profile.gradeName} Grade`
                                                }
                                                onClick={() => handleEprChange(profile)}
                                                size="large"
                                            />
                                        ))}
                                </div>
                                <hr />
                            </div>
                        )}
                        {selectedEpr?.gradingProfileStudents && selectedEpr?.gradingProfileStudents.length > 0 && (
                            <div
                                className={`current-roster ${showSectionSelect || deltaStudents.length > 0 ? "with-select" : ""}`}
                            >
                                <h5>
                                    Current Rostered Section: <span>{currentRosteredSection?.sectionCourseName}</span>
                                </h5>
                                <ActionButton
                                    className="action-button-reg"
                                    label="Change Section"
                                    onClick={() => setShowSectionSelect(true)}
                                />
                            </div>
                        )}

                        {selectedEpr && showSectionSelect && (
                            <>
                                <label className="select-label-ic" htmlFor="rosterSelect">
                                    <h6 style={{ marginBottom: "0px" }}>Select an IC Section for EPR:</h6>
                                    <select
                                        className="ic-select"
                                        id="rosterSelect"
                                        name="roster-select"
                                        value={selectedRoster}
                                        onChange={handleRosterChange}
                                    >
                                        <option value="">– Select an IC Section –</option>
                                        {filteredSections?.map((section, index) => (
                                            <option
                                                key={`section-${section.id}-${index}`}
                                                value={section.sectionCourseName}
                                            >
                                                {section.sectionCourseName}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <div className="no-margin-btn-roster">
                                    <ActionButton
                                        label="Import IC Section"
                                        disable={!selectedRoster}
                                        onClick={handleImportICSection}
                                    />
                                </div>
                            </>
                        )}

                        {deltaStudents.length > 0 && (
                            <section className={showSectionSelect ? "delta-students" : ""}>
                                <div className="container mt-3">
                                    <div className="accordion" id="studentAccordion">
                                        <div className="accordion-item" style={{ border: "2px solid #FEBF31" }}>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    Students Potentially Missing from Roster
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#studentAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <section className="delta-section-container">
                                                        <div className="delta-text" style={{ marginBottom: "24px" }}>
                                                            The following students are in the original IC Section but
                                                            are not in your homeroom roster. Would you like to add them?
                                                        </div>
                                                        <div className="delta-student-grid">
                                                            {deltaStudents
                                                                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                                                                .map((student) => {
                                                                    const { firstName, lastName, studentNumber } =
                                                                        student;
                                                                    const displayStudent = `${lastName}, ${firstName} (${studentNumber})`;
                                                                    return (
                                                                        <label
                                                                            key={student.id}
                                                                            className="delta-student-card"
                                                                        >
                                                                            <input
                                                                                checked={selectedStudents.includes(
                                                                                    student.studentNumber
                                                                                )}
                                                                                name="student"
                                                                                onChange={() =>
                                                                                    handleCheckboxChange(
                                                                                        student.studentNumber
                                                                                    )
                                                                                }
                                                                                value={student.studentNumber}
                                                                                type="checkbox"
                                                                            />
                                                                            <span>{displayStudent}</span>
                                                                        </label>
                                                                    );
                                                                })}
                                                        </div>
                                                        <div className="delta-button-container">
                                                            <label className="select-all-check-btn">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        selectedStudents.length === deltaStudents.length
                                                                    }
                                                                    onChange={handleSelectAll}
                                                                />
                                                                <span>
                                                                    {selectedStudents.length === deltaStudents.length
                                                                        ? "Unselect All"
                                                                        : "Select All"}
                                                                </span>
                                                            </label>

                                                            <ActionButton
                                                                className="action-button-reg"
                                                                onClick={() => handleAddStudents()}
                                                                label="Add to Roster"
                                                            />
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </section>
                    {selectedEpr?.gradingProfileStudents?.length > 0 && (
                        <section>
                            <StudentRosterTable
                                dispatch={dispatch}
                                gradingProfileStudents={selectedEpr.gradingProfileStudents}
                                selectedEpr={selectedEpr}
                                setDeltaStudents={setDeltaStudents}
                                setShowSectionSelect={setShowSectionSelect}
                                setStudentsNotAdded={setStudentsNotAdded}
                                state={state}
                            />
                        </section>
                    )}
                </>
            )}
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label="Confirm"
                            onClick={() => {
                                handleClearAndImport();
                            }}
                        />
                    </div>
                }
                ariaLabel="Confirmation Replace Roster Dialog"
                hasCloseX={false}
                id="confirm-replace-roster"
                open={open}
                title="Clear and Replace Current EPR Roster"
            >
                <div>
                    Please confirm that you wish to clear your current EPR roster and set your EPR roster based on the
                    selected IC section.
                    <br />
                    <span style={{ fontStyle: "italic", textDecoration: "underline" }}>
                        Please note, you will lose any completed work with this action.
                    </span>
                </div>
            </DcsdDialog>
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-reg"
                            label="Close"
                            onClick={() => {
                                setOpen("false");
                            }}
                        />
                    </div>
                }
                ariaLabel="Students not added to roster dialog"
                hasCloseX={false}
                id="student-not-added"
                open={open}
                title={
                    <span className="warning-modal-title-color">
                        {studentsNotAdded?.length > 1 ? "Students" : "Student"} Cannot be Added
                    </span>
                }
            >
                {studentsNotAdded && studentsNotAdded.length > 0 && (
                    <div style={{ overflow: "scroll", maxHeight: "500px" }}>
                        The following student(s) could not be added because they are currently rostered on another
                        homeroom EPR. Please coordinate with the teacher to determine who will post the student&apos;s
                        progress report for the semester. If you need to add the student, the student&apos;s former
                        teacher will first need to go to their homeroom roster and remove the student.
                        <ul style={{ marginTop: "8px" }}>
                            {studentsNotAdded?.map((student) => {
                                const {
                                    locationName,
                                    studentFirstName,
                                    studentLastName,
                                    studentNumber,
                                    teacherFirstName,
                                    teacherLastName
                                } = student;
                                return (
                                    <li key={studentNumber} style={{ marginBottom: "4px" }}>
                                        <b>
                                            {studentFirstName} {studentLastName}
                                        </b>{" "}
                                        is rostered by{" "}
                                        <b>
                                            {teacherFirstName} {teacherLastName}{" "}
                                        </b>
                                        at <b>{locationName}</b>.
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </DcsdDialog>
            {loader && <LoadingSvg />}
        </div>
    );
};

ManageRoster.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default ManageRoster;
