import { SERVICE_HOST } from "../utils/auth/config";

export const EPR_SERVICE = `${SERVICE_HOST}/epr-sb/v0`;

export const EPR_REPORTABLE_CONTENT = `${EPR_SERVICE}/content`;
export const EPR_REPORTABLE_BY_CONTENT_AREA = `${EPR_REPORTABLE_CONTENT}/by_content_area`; // <contentAreaId>/index.json
export const EPR_REPORTABLE_CONTENT_UPDATE = `${EPR_REPORTABLE_CONTENT}/update_report_flag`; // <contentAreaId>/index.json

export const EPR_TEMPLATE = `${EPR_SERVICE}/template`;
export const EPR_COMPLETE_GRADE_SUBJECT = `${EPR_TEMPLATE}/complete/by_profile_grade`; // <profileGradeId>/detail.json?completed=[true/false]&lastUpdaterGuid=[guid]&profileSubjectIds=[ids]
export const EPR_CREATE_DISTRICT_TEMPLATE = `${EPR_TEMPLATE}/district`; // <schoolYearKey>/detail.json?lastUpdaterGuid=<guid>
export const EPR_DELETE_TEMPLATE = `${EPR_TEMPLATE}`; // <schoolYearKey>/<locationKey>/detail.json?lastUpdaterGuid=<guid>
export const EPR_LOCATIONS_WITH_TEMPLATES = `${EPR_TEMPLATE}/locations_with_templates`; //<schoolYearKey>/index.json
export const EPR_RELEASE_TEMPLATE = `${EPR_TEMPLATE}/release/by_profile`; // <profileId>/detail.json?templatesReleased=<true|false>&lastUpdaterGuid=<guid>

export const EPR_GET_TIME_PERIOD = `${EPR_SERVICE}/time_period/by_school_year`; // <schoolYearKey>/index.json
export const REFERENCE_DATA = `${EPR_SERVICE}/reference_data`;
export const ALL_CONTENT_AREAS = `${REFERENCE_DATA}/content_area/index.json`;
export const ALL_GRADES = `${REFERENCE_DATA}/grade/index.json`;
