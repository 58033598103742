// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.overall-container {
  display: flex;
}
.overall-container .left-side {
  width: 50%;
  display: flex;
}
.overall-container .left-side .logo-container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.overall-container .left-side .header-button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -8px;
}
.overall-container .left-side .header-button-container .logo-header {
  color: #19608f;
  font-size: 1.8em;
  font-weight: 600;
}
.overall-container .right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.overall-container .right-container .second-message {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/AdminRow.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AANA;EACI,aAAA;AASJ;AAPI;EACI,UAAA;EACA,aAAA;AASR;AAPQ;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AASZ;AAPQ;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,gBAAA;AASZ;AAPY;EACI,cDhBN;ECiBM,gBAAA;EACA,gBAAA;AAShB;AALI;EACI,aAAA;EACA,sBAAA;EACA,UAAA;AAOR;AALQ;EACI,gBAAA;AAOZ","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// EPR colors\n$dashboard-blue: #007bff;\n$dk-blue: #19608f;\n$lt-blue: #7DB7CC;\n$pale-blue: #c0def0;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$DCSD-logo-green: #84ae30;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n","@import \"../styles/global/Colors\";\n@import \"../styles/global/globalMixins\";\n\n\n.overall-container {\n    display: flex;\n\n    .left-side {\n        width: 50%;\n        display: flex;\n\n        .logo-container {\n            display: flex;\n            flex-direction: column;\n            margin-right: 10px;\n        }\n        .header-button-container {\n            display: flex;\n            flex-direction: column;\n            justify-content: flex-start;\n            margin-top: -8px;\n\n            .logo-header {\n                color: $dk-blue;\n                font-size: 1.8em;\n                font-weight: 600;\n            }\n        }\n    }\n    .right-container {\n        display: flex;\n        flex-direction: column;\n        width: 50%;\n\n        .second-message {\n            margin-top: 10px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
