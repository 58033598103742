import React from "react";
import PropTypes from "prop-types";

import "../../../styles/TabButton.scss";

/**
 * A button for selecting what to view
 * @name TabButton
 * @param {string} className
 * @param {bool} disable
 * @param {string} id
 * @param {string} label
 * @param {func} onClick
 * @param {string} size
 * @return {JSX.Element}
 * @constructor
 */
const TabButton = ({ children, className, disable, id, label, name, onClick, onMouseEnter, onMouseLeave, size }) => {
    return (
        <button
            aria-label={label}
            className={`${className}-${size}`}
            disabled={disable}
            id={id}
            name={name}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            tabIndex={0}
            type="button"
        >
            {label}
            {children}
        </button>
    );
};

TabButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disable: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    size: PropTypes.string
};

TabButton.defaultProps = {
    children: null,
    className: "action-button-reg",
    disable: false,
    id: null,
    label: null,
    name: null,
    onClick: null,
    onMouseEnter: null,
    onMouseLeave: null,
    size: "small"
};

export default TabButton;
