import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";

import ActionButton from "../formInputs/buttons/ActionButton";
import HomeroomGroupForm from "../teacher/HomeroomGroupForm";

import "../../styles/HomeroomGroups.scss";

/**
 * GroupAccordionItem component renders a single group within an accordion.
 * It displays group details, allows editing/deleting, and conditionally renders
 * the HomeroomGroupForm in a modal when editing.
 *
 * @component
 * @param {Object} dispatch
 * @param {string} eventKey
 * @param {Object} group
 * @param {string} groupName
 * @param {Function} handleCheckboxChange
 * @param {Function} handleDeleteGroup
 * @param {Function} handleSelectAll
 * @param {Function} handleUpdateGroup
 * @param {boolean} isEditing
 * @param {Function} onCloseEditForm
 * @param {Function} onEdit
 * @param {Object} selectedEpr
 * @param {string[]} selectedStudents
 * @param {Function} setGroupName
 * @param {Function} setSelectedStudents
 * @param {Object} state
 */
const GroupAccordionItem = ({
    dispatch,
    eventKey,
    group,
    groupName,
    handleCheckboxChange,
    handleDeleteGroup,
    handleSelectAll,
    handleUpdateGroup,
    isEditing,
    onCloseEditForm,
    onEdit,
    selectedEpr,
    selectedStudents,
    setGroupName,
    setSelectedStudents,
    state
}) => {
    return (
        <Accordion.Item eventKey={eventKey} className="mb-3 group-accordion">
            <Accordion.Header>
                <span className="group-name-title">{group.name}</span>&ndash;{" "}
                <span className="group-student-count">{group.homeroomGroupStudents?.length} Students</span>
            </Accordion.Header>
            <Accordion.Body>
                <div className="group-accordion-btn-group">
                    <ActionButton
                        className="action-button-reg"
                        disable={isEditing}
                        label="Edit Group"
                        onClick={onEdit}
                    />
                    <ActionButton
                        className="action-button-delete"
                        label="Delete Group"
                        onClick={() => handleDeleteGroup(group.key)}
                    />
                </div>

                {isEditing && (
                    <HomeroomGroupForm
                        dispatch={dispatch}
                        groupName={groupName}
                        groupKey={group.key}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSelectAll={handleSelectAll}
                        handleUpdateGroup={() => handleUpdateGroup(group)}
                        isEditMode
                        onClose={onCloseEditForm}
                        selectedEpr={selectedEpr}
                        selectedStudents={selectedStudents}
                        setGroupName={setGroupName}
                        setSelectedStudents={setSelectedStudents}
                        show={true}
                        state={state}
                    />
                )}
            </Accordion.Body>
        </Accordion.Item>
    );
};

GroupAccordionItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    eventKey: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    groupName: PropTypes.string.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleDeleteGroup: PropTypes.func.isRequired,
    handleSelectAll: PropTypes.func.isRequired,
    handleUpdateGroup: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onCloseEditForm: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    selectedEpr: PropTypes.object.isRequired,
    selectedStudents: PropTypes.arrayOf(PropTypes.string).isRequired,
    setGroupName: PropTypes.func.isRequired,
    setSelectedStudents: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired
};

export default GroupAccordionItem;
