import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../styles/SidebarNav.scss";

const SidebarMenu = ({
    isSchoolTemplate,
    navCollapsed,
    selectedLink,
    setLabelObj,
    setNavCollapsed,
    setSelectedLink
}) => {
    const navigate = useNavigate();
    const handleOnClick = (linkName) => {
        const array = linkName.split("/").map((str) => {
            return { label: str, path: `/${str}` };
        });
        setSelectedLink(linkName);
        if (linkName !== "HOME") {
            setLabelObj(array);
        }
        if (linkName === "HOME") {
            setLabelObj([{ label: "Home", path: "/home" }]);
        }
        navigate(`/${linkName.split(" ")[0].toLowerCase()}`);
    };

    return (
        <>
            {!navCollapsed && (
                <div className="expanded-nav-container">
                    <div
                        className="expanded-nav-arrow-row"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setNavCollapsed(true);
                            }
                        }}
                        onClick={() => setNavCollapsed(true)}
                        tabIndex={0}
                    >
                        <i className={`bi bi-arrow-bar-left white arrow-icon`}></i>
                    </div>
                    <div
                        className={`expanded-nav-row ${selectedLink === "HOME" ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i className={`bi bi-house-fill ${selectedLink === "HOME" ? "dk-blue" : "white"}`}></i>
                        <span className={`expanded-nav-link ${selectedLink === "HOME" ? "dk-blue" : "white"}`}>
                            Home
                        </span>
                    </div>
                    <div
                        className={`expanded-nav-row ${selectedLink === "HOME/TEMPLATE" ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME/TEMPLATE")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME/TEMPLATE");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i
                            className={`bi bi-card-checklist ${selectedLink === "HOME/TEMPLATE" ? "dk-blue" : "white"}`}
                        ></i>
                        <span className={`expanded-nav-link ${selectedLink === "HOME/TEMPLATE" ? "dk-blue" : "white"}`}>
                            School Template
                        </span>
                    </div>
                    {selectedLink.includes("TEMPLATE") && isSchoolTemplate && (
                        <div
                            className={`expanded-nav-sub-row ${selectedLink.includes("HOME/TEMPLATE/DEFINE") ? "selected-row" : "not-selected-row"}`}
                            onClick={() => handleOnClick("HOME/TEMPLATE/DEFINE REPORTING PERIODS")}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleOnClick("HOME/TEMPLATE/DEFINE REPORTING PERIODS");
                                }
                            }}
                            tabIndex={0}
                        >
                            <div
                                className={`expanded-nav-link ${selectedLink.includes("HOME/TEMPLATE/DEFINE") ? "dk-blue" : "white"}`}
                            >
                                Define Reporting Periods
                            </div>
                        </div>
                    )}
                    <div
                        className={`expanded-nav-row ${selectedLink === "HOME/SLES" ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME/SLES")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME/SLES");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i className={`bi bi-bookmark-star ${selectedLink === "HOME/SLES" ? "dk-blue" : "white"}`}></i>
                        <span className={`expanded-nav-link ${selectedLink === "HOME/SLES" ? "dk-blue" : "white"}`}>
                            SLEs
                        </span>
                    </div>
                </div>
            )}
            {navCollapsed && (
                <div className="collapsed-nav-container">
                    <div
                        className="collapsed-nav-row"
                        onClick={() => setNavCollapsed(false)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setNavCollapsed(false);
                            }
                        }}
                        tabIndex={0}
                    >
                        <i className={`bi bi-arrow-bar-right white arrow-icon`}></i>
                    </div>
                    <div
                        className={`collapsed-nav-row ${selectedLink === "HOME" ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i className={`bi bi-house-fill ${selectedLink === "HOME" ? "dk-blue" : "white"}`}></i>
                    </div>
                    <div
                        className={`collapsed-nav-row ${selectedLink === "HOME/TEMPLATE" ? "selected-row" : selectedLink.includes("HOME/TEMPLATE/DEFINE") ? "child-selected" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME/TEMPLATE")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME/TEMPLATE");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i
                            className={`bi bi-card-checklist ${selectedLink === "HOME/TEMPLATE" ? "dk-blue" : "white"}`}
                        ></i>
                    </div>
                    <div
                        className={`collapsed-nav-row ${selectedLink === "HOME/SLES" ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME/SLES")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME/SLES");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i className={`bi bi-bookmark-star ${selectedLink === "HOME/SLES" ? "dk-blue" : "white"}`}></i>
                    </div>
                </div>
            )}
        </>
    );
};

SidebarMenu.propTypes = {
    isSchoolTemplate: PropTypes.bool,
    navCollapsed: PropTypes.bool,
    selectedLink: PropTypes.string,
    setLabelObj: PropTypes.func,
    setNavCollapsed: PropTypes.func,
    setSelectedLink: PropTypes.func
};

export default SidebarMenu;
