import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import ActionButton from "../formInputs/buttons/ActionButton";
import HomeroomGroupForm from "./HomeroomGroupForm";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
import GroupAccordionItem from "../display/GroupAccordionItem";
import LoadingSvg from "../loadingSvg/LoadingSvg";
import TabButton from "../formInputs/buttons/TabButton";

import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/HomeroomGroups.scss";

/**
 * HomeroomGroups is the main interface for managing student groupings within an EPR (Elementary Progress Report).
 * It supports creating, editing, and deleting groups, as well as selecting students within each group.
 *
 * @component
 * @param {Object} props
 * @param {Function} props.dispatch - Dispatch function for reducer/state updates.
 * @param {Object} props.state - Global state including selectedEpr and gradingProfiles.
 * @returns {JSX.Element}
 */
const HomeroomGroups = ({ dispatch, state }) => {
    const { gradingProfileId } = useParams();
    const { gradingProfiles, selectedEpr } = state;

    const { state: globalState } = useContext(GlobalContext);
    const { currentTimePeriodDto, schoolYearDto, token } = globalState || {};

    const [selectedStudents, setSelectedStudents] = useState([]);
    const [shouldShowEprSelect, setShouldShowEprSelect] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [activeGroupKey, setActiveGroupKey] = useState(null);
    const [loader, setLoader] = useState(false);
    const [editingGroupKey, setEditingGroupKey] = useState(null);
    const [editingGroupName, setEditingGroupName] = useState("");
    const [editingSelectedStudents, setEditingSelectedStudents] = useState([]);
    const [show, setShow] = useState(false);

    const handleEprChange = (profile) => {
        dispatch({ type: "SET_SELECTED_EPR", payload: profile });
    };

    /**
     * Creates a new homeroom group with the selected students.
     * If all students are selected, the group is marked as "WHOLE_CLASS".
     */
    const handleCreateGroup = () => {
        const arrayOfStudents = selectedStudents.map((student) => ({
            key: null,
            studentNumber: student
        }));

        const options = {
            action: "createHomeroomGroup",
            gradingProfile: selectedEpr.key,
            data: {
                key: null,
                name: groupName,
                type: arrayOfStudents.length === selectedEpr.gradingProfileStudents.length ? "WHOLE_CLASS" : "GROUP",
                schoolYearKey: schoolYearDto.key,
                timePeriod: currentTimePeriodDto.key,
                ownerGradingProfile: selectedEpr.key,
                homeroomGroupStudents: arrayOfStudents
            },
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data?.payload) {
                const updatedProfile = response.data.payload;

                dispatch({ type: "SET_SELECTED_EPR", payload: updatedProfile });
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedProfile.key ? updatedProfile : profile
                    )
                });
                toast.success("EPR homeroom group created.", { autoClose: 3000 });

                setSelectedStudents([]);
                setGroupName("");
                if (setShow) {
                    setShow(false);
                }

                const newlyCreatedGroup = updatedProfile.homeroomGroups?.[updatedProfile.homeroomGroups.length - 1];
                if (newlyCreatedGroup) {
                    setActiveGroupKey(String(newlyCreatedGroup.key));
                }
                setLoader(false);
            }
        });
    };

    /**
     * Updates an existing homeroom group, preserving existing student keys
     * and setting key: null for newly added students.
     *
     * @param {Object} group - The group object being edited.
     */
    const handleUpdateGroup = (group) => {
        // Build a map of existing students in the group for quick lookup
        const existingStudentMap = {};
        group.homeroomGroupStudents.forEach((s) => {
            existingStudentMap[s.studentNumber] = s.key;
        });

        const arrayOfStudents = editingSelectedStudents.map((studentNumber) => ({
            key: existingStudentMap[studentNumber] || null,
            studentNumber
        }));

        const options = {
            action: "updateHomeroomGroup",
            homeroomGroupId: group.key,
            data: {
                key: group.key,
                name: editingGroupName,
                type: arrayOfStudents.length === selectedEpr.gradingProfileStudents.length ? "WHOLE_CLASS" : "GROUP",
                schoolYearKey: group.schoolYearKey,
                timePeriod: group.timePeriod,
                ownerGradingProfile: selectedEpr.key,
                homeroomGroupStudents: arrayOfStudents
            },
            token
        };

        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data?.payload) {
                const updatedProfile = response.data.payload;

                dispatch({ type: "SET_SELECTED_EPR", payload: updatedProfile });
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedProfile.key ? updatedProfile : profile
                    )
                });
                toast.success("EPR homeroom group updated.", { autoClose: 3000 });

                setEditingGroupKey(null);
                setEditingGroupName("");
                setEditingSelectedStudents([]);
            }
        });
    };

    /**
     * Deletes a homeroom group by key and updates local and global state.
     *
     * @param {string} groupKey - The key of the group to be deleted.
     */
    const handleDeleteGroup = (groupKey) => {
        const options = {
            action: "deleteHomeroomGroup",
            homeroomGroupId: groupKey,
            token
        };

        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data === "") {
                const updatedGroups = selectedEpr.homeroomGroups.filter((group) => group.key !== groupKey);

                const updatedEpr = {
                    ...selectedEpr,
                    homeroomGroups: updatedGroups
                };

                dispatch({ type: "SET_SELECTED_EPR", payload: updatedEpr });
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedEpr.key ? updatedEpr : profile
                    )
                });

                toast.success("EPR homeroom group deleted.", { autoClose: 3000 });

                if (String(groupKey) === activeGroupKey) {
                    setActiveGroupKey(null);
                }
            }
        });
    };

    const handleSelectAll = (isEdit = false) => {
        const all = selectedEpr.gradingProfileStudents.map((s) => s.studentNumber);
        if (isEdit) {
            setEditingSelectedStudents(editingSelectedStudents.length === all.length ? [] : all);
        } else {
            setSelectedStudents(selectedStudents.length === all.length ? [] : all);
        }
    };

    const handleCheckboxChange = (id, isEdit = false) => {
        if (isEdit) {
            setEditingSelectedStudents((prev) =>
                prev.includes(id) ? prev.filter((sid) => sid !== id) : [...prev, id]
            );
        } else {
            setSelectedStudents((prev) => (prev.includes(id) ? prev.filter((sid) => sid !== id) : [...prev, id]));
        }
    };

    /**
     * Populates editing state with the selected group's data and activates its accordion panel.
     *
     * @param {Object} group - The group to be edited.
     */
    const handleEditGroup = (group) => {
        setEditingGroupKey(String(group.key));
        setActiveGroupKey(String(group.key));
        setEditingGroupName(group.name);
        setEditingSelectedStudents(group.homeroomGroupStudents.map((s) => s.studentNumber));
    };

    const handleAccordionSelect = (key) => {
        setActiveGroupKey(key);
    };

    const handleCloseEditForm = () => {
        setEditingGroupKey(null);
        setEditingGroupName("");
        setEditingSelectedStudents([]);
    };

    /**
     * Sets the default selected EPR profile on initial load if none is selected.
     * Prioritizes URL param if present, otherwise defaults to the first profile in the list.
     */
    useEffect(() => {
        if (!selectedEpr && gradingProfiles.length > 0) {
            const profileToSet = gradingProfileId
                ? gradingProfiles.find((p) => p.key === gradingProfileId)
                : gradingProfiles[0];
            dispatch({ type: "SET_SELECTED_EPR", payload: profileToSet });
        }
    }, [gradingProfileId, gradingProfiles, selectedEpr, dispatch]);

    /**
     * Determines whether to show the EPR selection toggle.
     * If there's no gradingProfileId in the URL and multiple profiles exist, toggling is enabled.
     */
    useEffect(() => {
        if (!gradingProfileId && gradingProfiles?.length > 1) {
            setShouldShowEprSelect(true);
        }
    }, [gradingProfileId, gradingProfiles]);

    /**
     * Renders all existing homeroom groups in individual accordion panels.
     * Each group can be edited or deleted from its own panel.
     *
     * @returns {JSX.Element} Accordion component containing group panels.
     */
    const renderGroupAccordions = () => (
        <Accordion activeKey={activeGroupKey} onSelect={handleAccordionSelect}>
            {selectedEpr.homeroomGroups.map((group) => (
                <GroupAccordionItem
                    key={group.key}
                    dispatch={dispatch}
                    eventKey={String(group.key)}
                    group={group}
                    groupName={editingGroupName}
                    handleCheckboxChange={(id) => handleCheckboxChange(id, true)}
                    handleDeleteGroup={handleDeleteGroup}
                    handleUpdateGroup={handleUpdateGroup}
                    handleSelectAll={() => handleSelectAll(true)}
                    isEditing={editingGroupKey === String(group.key)}
                    onCloseEditForm={handleCloseEditForm}
                    onEdit={() => handleEditGroup(group)}
                    selectedEpr={selectedEpr}
                    selectedStudents={editingSelectedStudents}
                    setGroupName={setEditingGroupName}
                    setSelectedStudents={setEditingSelectedStudents}
                    state={state}
                />
            ))}
        </Accordion>
    );

    /**
     * Renders the homeroom group creation form (modal or inline).
     *
     * @returns {JSX.Element} The creation form.
     */
    const renderCreateGroupForm = () => (
        <HomeroomGroupForm
            groupName={groupName}
            setGroupName={setGroupName}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            handleCreateGroup={handleCreateGroup}
            handleCheckboxChange={(id) => handleCheckboxChange(id, false)}
            handleSelectAll={() => handleSelectAll(false)}
            dispatch={dispatch}
            selectedEpr={selectedEpr}
            setShow={setShow}
            show={show}
            state={state}
            isEditMode={false}
        />
    );

    return (
        <div className="right-side-wrapper">
            <div className="right-page-title">
                <h4>
                    Homeroom Groups{" "}
                    {selectedEpr && (
                        <span>
                            &ndash; {selectedEpr.gradeName === "K" ? "Kindergarten" : `${selectedEpr.gradeName} Grade`}
                        </span>
                    )}
                </h4>
                <h5>
                    <span style={{ fontWeight: "700" }}>{currentTimePeriodDto.name}</span> | {schoolYearDto.name}
                </h5>
            </div>
            <hr />

            {shouldShowEprSelect && (
                <div>
                    <h6>Select an EPR</h6>
                    <div className="epr-toggle-container">
                        {gradingProfiles
                            .sort((a, b) => a.grade - b.grade)
                            .map((profile) => (
                                <TabButton
                                    key={profile.key}
                                    className={selectedEpr.key === profile.key ? "tab-button-selected" : "tab-button"}
                                    label={profile.gradeName === "K" ? "Kindergarten" : `${profile.gradeName} Grade`}
                                    onClick={() => handleEprChange(profile)}
                                    size="large"
                                />
                            ))}
                    </div>
                    <hr />
                </div>
            )}

            {selectedEpr?.homeroomGroups?.length > 0 && (
                <div style={{ marginLeft: "-8px", marginBottom: "24px" }}>
                    <ActionButton
                        className="action-button-reg"
                        label="Create Homeroom Group"
                        onClick={() => setShow(true)}
                    />
                </div>
            )}

            {selectedEpr?.homeroomGroups?.length > 0 && renderGroupAccordions()}

            {selectedEpr && renderCreateGroupForm()}

            {loader && <LoadingSvg />}
        </div>
    );
};

HomeroomGroups.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default HomeroomGroups;
