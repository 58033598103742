import React from "react";
import PropTypes from "prop-types";

import "../../styles/DefineReportingPeriods.scss";

/**
 * This is the three inputs for defining periods as first semester, second semester, or neither
 * @name DefineInputGroup
 * @param {bool} firstSemChecked
 * @param {string} firstSemContentKey
 * @param {number} firstSemTemplateContentId
 * @param {func} handleNotReported
 * @param {func} handleOnChange
 * @param {bool} isError
 * @param {[]} notReported
 * @param {bool} reportFlag
 * @param {bool} secondSemChecked
 * @param {string} secondSemContentKey
 * @param {number} secondSemTemplateContentId
 * @param {bool} yearComplete
 * @returns {JSX.Element}
 */
const DefineInputGroup = ({
    firstSemChecked,
    firstSemContentKey,
    firstSemTemplateContentId,
    handleNotReported,
    handleOnChange,
    isError,
    notReported,
    reportFlag,
    secondSemChecked,
    secondSemContentKey,
    secondSemTemplateContentId,
    yearComplete
}) => {
    return reportFlag ? (
        <div className="standard-check-container">
            <input
                aria-label="First Semester"
                checked={firstSemChecked}
                data-contentkey={firstSemContentKey}
                disabled={yearComplete}
                name={firstSemTemplateContentId}
                onChange={(e) => handleOnChange(e)}
                type="checkbox"
                value={firstSemChecked}
            />
            <input
                aria-label="Second Semester"
                checked={secondSemChecked}
                data-contentkey={secondSemContentKey}
                disabled={yearComplete}
                name={secondSemTemplateContentId}
                onChange={(e) => handleOnChange(e)}
                type="checkbox"
                value={secondSemChecked}
            />
            <div className="not-reportable-wrapper">
                <div className={isError ? "error-not-reportable-border" : "not-reportable-border"}>
                    <input
                        aria-label="Not Reported"
                        disabled={firstSemChecked || secondSemChecked || yearComplete}
                        style={isError ? { outline: "2px solid red" } : {}}
                        type="checkbox"
                        checked={notReported?.includes(firstSemContentKey)}
                        name={firstSemContentKey}
                        onChange={() => handleNotReported(firstSemContentKey)}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="standard-check-container">
            <div className="dash">&mdash;</div>
            <div className="dash">&mdash;</div>
            <div className="dash">&mdash;</div>
        </div>
    );
};

DefineInputGroup.propTypes = {
    firstSemChecked: PropTypes.bool,
    firstSemContentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstSemTemplateContentId: PropTypes.number,
    handleNotReported: PropTypes.func,
    handleOnChange: PropTypes.func,
    isError: PropTypes.bool,
    notReported: PropTypes.instanceOf(Array),
    reportFlag: PropTypes.bool,
    secondSemChecked: PropTypes.bool,
    secondSemContentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    secondSemTemplateContentId: PropTypes.number,
    yearComplete: PropTypes.bool
};

export default DefineInputGroup;
