export const isUserDistrictAdmin = (userDetails) => {
    const { roleDtos } = userDetails || [];
    return roleDtos?.length > 0 && roleDtos?.find((role) => role.role === "EPR_ADMIN");
};

export const isUserSchoolAdmin = (userDetails) => {
    const { roleDtos } = userDetails || [];
    return (
        roleDtos?.length > 0 &&
        roleDtos?.find((role) => role.role === "PRINCIPAL" || role.role === "ASSISTANT_PRINCIPAL")
    );
};
