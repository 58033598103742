import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import {
    ALL_CONTENT_AREAS,
    ALL_GRADES,
    COPY_SCHOOL_LEVEL_EXPECTATION,
    CREATE_HOMEROOM_GROUP,
    DELETE_GRADING_PROFILE,
    EPR_COMPLETE_GRADE_SUBJECT,
    EPR_CREATE_GRADING_PROFILE,
    EPR_CREATE_DISTRICT_TEMPLATE,
    EPR_CREATE_SCHOOL_TEMPLATES,
    EPR_DELETE_TEMPLATE,
    EPR_GET_CURRENT_TIME_PERIOD,
    EPR_GET_TIME_PERIOD,
    EPR_GRADING_PROFILE,
    EPR_LOCATIONS_WITH_TEMPLATES,
    EPR_RELEASE_TEMPLATE,
    EPR_REPORTABLE_CONTENT,
    EPR_REPORTABLE_BY_CONTENT_AREA,
    EPR_REPORTABLE_CONTENT_UPDATE,
    EPR_TEMPLATE,
    EPR_UPDATE_INCLUDED,
    EPR_GRADING_PROFILE_STUDENT,
    EPR_GRADING_PROFILE_COMMENTS,
    GRADING_PROFILE_ADD_STUDENTS,
    HOMEROOM_GROUP,
    SCHOOL_LEVEL_EXPECTATION
} from "../const/ElementaryProgressReportConst";

/**
 * Data Access for the Elementary Progress Report Service
 * @name ElementaryProgressReportDao
 * @param props
 * @return {null|*}
 */
const ElementaryProgressReportDao = (props) => {
    const {
        action,
        contentAreaKey,
        data,
        guid,
        gradeLevels,
        gradingProfile,
        homeroomGroupId,
        isCompleted,
        locationKey,
        params,
        profileGradeId,
        profileId,
        profileSubjectIds,
        schoolYearKey,
        timePeriodId,
        token
    } = props;
    const bearer = `Bearer ${token}`;
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };
    switch (action) {
        case "addStudentsToProfile":
            options.method = "POST";
            options.data = data;
            options.url = `${GRADING_PROFILE_ADD_STUDENTS}/${gradingProfile}/index.json`;
            break;
        case "allContentAreasRead":
            options.method = "GET";
            options.url = `${ALL_CONTENT_AREAS}`;
            break;
        case "allGradesRead":
            options.method = "GET";
            options.url = `${ALL_GRADES}`;
            break;
        case "clearStudentsFromProfile":
            options.method = "PUT";
            options.url = `${EPR_GRADING_PROFILE}/clear_roster/${gradingProfile}/index.json`;
            break;
        case "createHomeroomGroup":
            options.method = "POST";
            options.data = data;
            options.url = `${CREATE_HOMEROOM_GROUP}/${gradingProfile}/detail.json`;
            break;
        case "completeSubjectGradeTemplate":
            options.method = "PUT";
            options.url = `${EPR_COMPLETE_GRADE_SUBJECT}/${profileGradeId}/detail.json?completed=${isCompleted}&lastUpdaterGuid=${guid}&profileSubjectIds=${profileSubjectIds}`;
            break;
        case "completeGradeTemplate":
            options.method = "PUT";
            options.url = `${EPR_COMPLETE_GRADE_SUBJECT}/${profileGradeId}/detail.json?completed=${isCompleted}&lastUpdaterGuid=${guid}`;
            break;
        case "copySchoolLevelExpectations":
            options.method = "POST";
            options.data = data;
            options.url = `${COPY_SCHOOL_LEVEL_EXPECTATION}/${locationKey}/index.json`;
            break;
        case "createDistrictTemplate":
            options.method = "POST";
            options.params = params;
            options.url = `${EPR_CREATE_DISTRICT_TEMPLATE}/${schoolYearKey}/detail.json?lastUpdaterGuid=${guid}`;
            break;
        case "createGradingProfile":
            options.method = "POST";
            options.data = data;
            if (params) {
                options.params = params;
            }
            options.url = `${EPR_CREATE_GRADING_PROFILE}`;
            break;
        case "deleteGradingProfile":
            options.method = "DELETE";
            if (params) {
                options.params = params;
            }
            options.url = `${DELETE_GRADING_PROFILE}/${gradingProfile}/detail.json`;
            break;
        case "deleteHomeroomGroup":
            options.method = "DELETE";
            options.url = `${HOMEROOM_GROUP}/${homeroomGroupId}/detail.json`;
            break;
        case "deleteSchoolLevelExpectationChildren":
            options.method = "DELETE";
            if (params) {
                options.params = params;
            }
            options.url = `${SCHOOL_LEVEL_EXPECTATION}/child/index.json`;
            break;
        case "deleteAllSchoolLevelExpectations":
            options.method = "DELETE";
            if (params) {
                options.params = params;
            }
            options.url = `${SCHOOL_LEVEL_EXPECTATION}/${schoolYearKey}/${locationKey}/index.json`;
            break;
        case "deleteSchoolLevelExpectations":
            options.method = "DELETE";
            if (params) {
                options.params = params;
            }
            options.url = `${SCHOOL_LEVEL_EXPECTATION}/index.json`;
            break;
        case "createSchoolTemplates":
            options.method = "POST";
            options.params = params;
            options.url = `${EPR_CREATE_SCHOOL_TEMPLATES}/${schoolYearKey}/${locationKey}/detail.json?lastUpdaterGuid=${guid}`;
            break;
        case "deleteTemplate":
            options.method = "DELETE";
            options.params = params;
            options.url = `${EPR_DELETE_TEMPLATE}/${schoolYearKey}/${locationKey}/detail.json?lastUpdaterGuid=${guid}`;
            break;
        case "eprReportableByContentAreaAndGrades":
            options.method = "GET";
            options.params = params;
            options.url = `${EPR_REPORTABLE_BY_CONTENT_AREA}/${contentAreaKey}/index.json?gradeLevels=${gradeLevels}`;
            break;
        case "getAllContent":
            options.method = "GET";
            options.url = `${EPR_REPORTABLE_CONTENT}/index.json`;
            break;
        case "getGradingProfile":
            options.method = "GET";
            options.url = `${EPR_GRADING_PROFILE}/${guid}/${schoolYearKey}/index.json`;
            break;
        case "getLocationsWithTemplates":
            options.method = "GET";
            options.url = `${EPR_LOCATIONS_WITH_TEMPLATES}/${schoolYearKey}/index.json`;
            break;
        case "getSchoolLevelExpectations":
            options.method = "GET";
            options.url = `${SCHOOL_LEVEL_EXPECTATION}/${schoolYearKey}/${locationKey}/index.json`;
            break;
        case "getStudentRosterDelta":
            options.method = "GET";
            options.url = `${EPR_GRADING_PROFILE}/student_roster_delta/${gradingProfile}/index.json`;
            break;
        case "getTemplateByLocationAndTimePeriod":
            options.method = "GET";
            options.url = `${EPR_TEMPLATE}/${schoolYearKey}/${locationKey}/${timePeriodId}/index.json`;
            break;
        case "getCurrentTimePeriodDto":
            options.method = "GET";
            options.url = `${EPR_GET_CURRENT_TIME_PERIOD}/detail.json`;
            break;
        case "getTimePeriodId":
            options.method = "GET";
            options.url = `${EPR_GET_TIME_PERIOD}/${schoolYearKey}/index.json`;
            break;
        case "removeStudentsFromProfile":
            options.method = "DELETE";
            if (params) {
                options.params = params;
            }
            options.url = `${EPR_GRADING_PROFILE_STUDENT}/${gradingProfile}/index.json`;
            break;
        case "releaseTemplate":
            options.method = "PUT";
            if (params) {
                options.params = params;
            }
            options.url = `${EPR_RELEASE_TEMPLATE}/${profileId}/detail.json`;
            break;
        case "updateEprReportableContent":
            options.method = "PUT";
            if (params) {
                options.params = params;
            }
            options.data = data;
            options.url = `${EPR_REPORTABLE_CONTENT_UPDATE}/${contentAreaKey}/index.json`;
            break;
        case "updateGradingProfileComments":
            options.method = "PUT";
            if (params) {
                options.params = params;
            }
            options.data = data;
            options.url = `${EPR_GRADING_PROFILE_COMMENTS}/${gradingProfile}/index.json`;
            break;
        case "updateHomeroomGroup":
            options.method = "PUT";
            options.data = data;
            options.url = `${HOMEROOM_GROUP}/${homeroomGroupId}/detail.json`;
            break;
        case "updateSchoolLevelExpectations":
            options.method = "PUT";
            options.data = data;
            options.url = `${SCHOOL_LEVEL_EXPECTATION}/${schoolYearKey}/${locationKey}/index.json`;
            break;
        case "updateSchoolTemplateIncludedFlag":
            options.method = "PUT";
            if (params) {
                options.params = params;
            }
            options.data = data;
            options.url = `${EPR_UPDATE_INCLUDED}/index.json`;
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

ElementaryProgressReportDao.propTypes = {
    action: PropTypes.string.isRequired,
    contentAreaKey: PropTypes.number,
    data: PropTypes.objectOf([PropTypes.object]),
    gradeLevels: PropTypes.string,
    gradingProfile: PropTypes.string,
    guid: PropTypes.number,
    homeroomGroupId: PropTypes.number,
    isCompleted: PropTypes.bool,
    isReleased: PropTypes.bool,
    isReleasedToTeachers: PropTypes.bool,
    locationKey: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    params: PropTypes.objectOf([PropTypes.object]),
    profileGradeId: PropTypes.number,
    profileId: PropTypes.number,
    profileSubjectIds: PropTypes.string,
    schoolYearKey: PropTypes.number,
    timePeriodId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    token: PropTypes.string.isRequired
};

ElementaryProgressReportDao.defaultProps = {
    contentAreaKey: null,
    params: null,
    schoolYearKey: null
};

export default ElementaryProgressReportDao;
