import React, { useContext, useEffect, useReducer, useMemo } from "react";
import { useLocation } from "react-router-dom";

import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import ElementaryProgressReportDao from "../dao/ElementaryProgressReportDao.jsx";
import EprSummary from "../components/teacher/EprSummary.jsx";
import GradingProfilesReducer from "../utils/GradingProfileReducer.jsx";
import HomeroomGroups from "../components/teacher/HomeroomGroups.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";
import ManageRoster from "../components/teacher/ManageRoster.jsx";
import TeacherSidebarMenu from "../components/sidebar/TeacherSidebarMenu.jsx";

import { GlobalContext } from "../components/contextProvider/ContextProvider.jsx";
import { gradeTranslation, instructionalAreaToGrade } from "../utils/SchoolAdmin/translations";

import "../styles/TeacherHome.scss";

/**
 * TeacherHome component serves as the main dashboard for teachers,
 * providing access to grading profiles, rosters, and summary views.
 *
 * @component
 * @returns {JSX.Element}
 */
const TeacherHome = () => {
    const { pathname } = useLocation();
    const { state: globalState } = useContext(GlobalContext);
    const { userDetails, currentTimePeriodDto, schoolYearDto, token, schoolLevelTemplateDto } = globalState || {};

    const initialState = {
        gradingProfiles: [],
        selectedEpr: "",
        fetchProfileFlag: true,
        loader: true,
        navCollapsed: false,
        selectedLink: "HOME/SUMMARY",
        labelObj: [{ label: "Home", path: "/home" }]
    };
    const [state, dispatch] = useReducer(GradingProfilesReducer, initialState);

    /**
     * Retrieves the list of released grades for the current time period.
     */
    const releasedGrades = useMemo(() => {
        if (!schoolLevelTemplateDto) return [];
        const currentTimePeriod = currentTimePeriodDto?.name === "1st Semester" ? "firstSemester" : "secondSemester";
        return (
            schoolLevelTemplateDto[currentTimePeriod]?.gradeToSubjects.filter((grade) => grade.templatesReleased) || []
        );
    }, [schoolLevelTemplateDto, currentTimePeriodDto]);

    /**
     * Determines the teacher's assigned grade if it has been released.
     */
    const teacherGradeIfReleased = useMemo(() => {
        if (!userDetails) return null;
        const instructionalAreaKey = userDetails?.userAttributeDto?.userAttributeMap?.INSTRUCTIONAL_AREA_KEY;
        const teacherGrade = instructionalAreaToGrade[instructionalAreaKey];
        return releasedGrades.find((grade) => grade.gradeLevel === teacherGrade);
    }, [userDetails, releasedGrades]);

    /**
     * Updates the selected navigation link based on the current route.
     */
    useEffect(() => {
        dispatch({ type: "SET_SELECTED_LINK", payload: pathname.slice(1).toUpperCase() });
    }, [pathname]);

    /**
     * Fetches grading profiles when necessary.
     */
    useEffect(() => {
        if (userDetails && schoolYearDto && schoolLevelTemplateDto && state.fetchProfileFlag) {
            const options = {
                action: "getGradingProfile",
                guid: userDetails.uid,
                schoolYearKey: schoolYearDto?.key,
                token
            };
            dispatch({ type: "SET_LOADER", payload: true });

            ElementaryProgressReportDao(options).then((response) => {
                if (response) {
                    dispatch({ type: "SET_FETCH_FLAG", payload: false });
                    dispatch({ type: "SET_PROFILES", payload: response.data.payload || [] });
                }
                dispatch({ type: "SET_LOADER", payload: false });
            });
        }
    }, [schoolYearDto, schoolLevelTemplateDto, state.fetchProfileFlag, token, userDetails]);

    /**
     * Hides the loader once user details, school year, and time period are available.
     */
    useEffect(() => {
        if (userDetails && schoolYearDto && currentTimePeriodDto) {
            dispatch({ type: "SET_LOADER", payload: false });
        }
    }, [currentTimePeriodDto, schoolYearDto, userDetails]);

    return (
        <div className="gutter-95" style={{ padding: "0px 0px" }}>
            <BreadCrumb
                style={{ marginTop: "-10px" }}
                labelObj={state.labelObj}
                setSelectedLink={(link) => dispatch({ type: "SET_SELECTED_LINK", payload: link })}
            />
            <div style={{ display: "flex" }}>
                <TeacherSidebarMenu
                    dispatch={dispatch}
                    gradingProfiles={state.gradingProfiles}
                    navCollapsed={state.navCollapsed}
                    selectedLink={state.selectedLink}
                    setLabelObj={(obj) => dispatch({ type: "SET_LABEL_OBJ", payload: obj })}
                    setNavCollapsed={() => dispatch({ type: "TOGGLE_NAV" })}
                    setSelectedLink={(link) => dispatch({ type: "SET_SELECTED_LINK", payload: link })}
                    state={state}
                />
                {state.selectedLink === "HOME" && !state.loader && (
                    <main className="teacher-home-wrapper">
                        <section className="section-container top">
                            <div className="sub-section-title">Welcome to EPR</div>
                            <div className="sub-section-line" />
                        </section>
                        <section className="section-container bottom">
                            <div className="sub-section-title">Updates</div>
                            <div className="sub-section-line" />

                            <div className="released-status-container">
                                {releasedGrades.length > 0 ? (
                                    <div className="released-grades-list">
                                        <span className="grades-ready-title">Grades ready for teachers: </span>
                                        <span>
                                            {releasedGrades
                                                .map((grade) => gradeTranslation[grade.gradeLevel])
                                                .join(", ")}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="no-grades-released">
                                        No grades have been released to teachers at this time. Please try again later.
                                    </div>
                                )}
                            </div>
                        </section>
                    </main>
                )}
                {state.selectedLink === "HOME/SUMMARY" && !state.loader && (
                    <EprSummary
                        dispatch={dispatch}
                        releasedGrades={releasedGrades}
                        state={state}
                        teacherGradeIfReleased={teacherGradeIfReleased}
                    />
                )}
                {state.selectedLink.includes("HOME/SUMMARY/ROSTER") && !state.loader && (
                    <ManageRoster dispatch={dispatch} state={state} />
                )}
                {state.selectedLink.includes("HOME/SUMMARY/HOMEROOM-GROUPS") && !state.loader && (
                    <HomeroomGroups dispatch={dispatch} state={state} />
                )}
            </div>
            {state.loader && <LoadingSvg />}
        </div>
    );
};

export default TeacherHome;
