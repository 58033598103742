import React from "react";
import PropTypes from "prop-types";

import AtAGlance from "./AtAGlance";
import Timeline from "./Timeline";

import "../../styles/SchoolHome.scss";

/**
 * This is the School Home page for school admin epr
 * @name SchoolHome
 * @returns {JSX.Element}
 */
const SchoolHome = ({ applicableGrades, combinedGrades, loader, status, statusClass, timePeriodDto }) => {
    return (
        <div className="school-home-wrapper">
            <Timeline loader={loader} status={status} statusClass={statusClass} timePeriodDto={timePeriodDto} />
            <AtAGlance
                applicableGrades={applicableGrades}
                combinedGrades={combinedGrades}
                loader={loader}
                statusClass={statusClass}
            />
        </div>
    );
};

SchoolHome.propTypes = {
    applicableGrades: PropTypes.instanceOf(Array),
    combinedGrades: PropTypes.instanceOf(Array),
    loader: PropTypes.bool,
    status: PropTypes.string,
    statusClass: PropTypes.string,
    timePeriodDto: PropTypes.instanceOf(Array)
};

export default SchoolHome;
