import React from "react";
import { MathJax } from "better-react-mathjax";
import Accordion from "react-bootstrap/Accordion";
import PropTypes from "prop-types";

import "../../styles/ManageEprContent.scss";

/**
 * Epr Reportable (checkbox) Content Component
 * @name EprContentReportable
 * @param {string|number} contentKey
 * @param {string} grade
 * @param {func} handleAddClick
 * @param {bool} isAccordionButton
 * @param {bool} reportFlag
 * @param {string|Node} title
 * @param {string} type
 * @return {JSX.element}
 */

const EprContentReportable = ({
    contentKey,
    count,
    grade,
    handleAddClick,
    reportFlag,
    title,
    type,
    isAccordionButton = false
}) => {
    const countString = count === 1 ? "content item selected" : "content items selected";
    return isAccordionButton ? (
        <Accordion.Header className="reportable-content-wrapper-accordion">
            <div className="rc-accordion-title">
                <input
                    id={`${contentKey}-${grade}`}
                    type="checkbox"
                    style={{ marginRight: "8px" }}
                    checked={reportFlag}
                    onChange={() => handleAddClick({ grade, type, gradeToContentKey: contentKey, reportFlag })}
                />
                <div style={{ display: "flex", justifyContent: "space-between", width: "88%" }}>
                    <p className="content-title">{title}</p>
                    <p style={{ fontStyle: "italic" }} className="content-title">
                        {count}
                        <span style={{ marginLeft: "8px" }}>{countString}</span>
                    </p>
                </div>
            </div>
        </Accordion.Header>
    ) : (
        <div className="reportable-content-wrapper">
            <input
                id={`${contentKey}-${grade}`}
                type="checkbox"
                style={{ marginRight: "8px", marginTop: "4px" }}
                checked={reportFlag}
                onChange={() => handleAddClick({ grade, type, gradeToContentKey: contentKey, reportFlag })}
            />
            {type === "evidenceOutcomes" || type === "evidenceOutcomeChildList" ? (
                <MathJax hideUntilTypeset={"first"}>
                    <p className={"content-title"} dangerouslySetInnerHTML={{ __html: title }} />
                </MathJax>
            ) : (
                <MathJax hideUntilTypeset={"first"}>
                    <p className={"content-title"}>{title}</p>
                </MathJax>
            )}
        </div>
    );
};

EprContentReportable.propTypes = {
    contentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    count: PropTypes.number,
    grade: PropTypes.string.isRequired,
    handleAddClick: PropTypes.func.isRequired,
    isAccordionButton: PropTypes.bool,
    reportFlag: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.oneOf(["cdeStandards", "gles", "evidenceOutcomes", "evidenceOutcomeChildList"]).isRequired
};

EprContentReportable.defaultProps = {
    count: 0,
    isAccordionButton: false,
    reportFlag: false,
    title: ""
};

export default EprContentReportable;
