import React from "react";

const Layout = () => {
    return (
        <div>
            <h1>This is the Layout page!</h1>
            <p>This page is only used to build components and colors</p>
            <br />
            <hr />
            {/* -------------------------------------------------------------------------- */}
        </div>
    );
};

export default Layout;
