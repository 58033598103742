import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import GradingAccordionItem from "./GradingAccordionItem";
import UserDao from "../../dao/UserDao";

import { GlobalContext } from "../contextProvider/ContextProvider";

/**
 * GradingAccordions component displays a list of expandable grading profiles.
 * It fetches the owner's display name and organizes profiles based on grade levels.
 *
 * @component
 * @param {Object}
 * @param {Function} dispatch
 * @param {Array} gradingProfiles
 * @param {Function} setShowSaved
 * @param {Function} setSavedMessage
 * @param {Object} state
 * @returns {JSX.Element}
 */
const GradingAccordions = ({ dispatch, gradingProfiles, setShowSaved, setSavedMessage, state }) => {
    const { state: globalState } = useContext(GlobalContext);
    const { currentTimePeriodDto, schoolLevelTemplateDto, token } = globalState;

    const [ownerDisplayName, setOwnerDisplayName] = useState("");

    const schoolLevelForCurrent =
        currentTimePeriodDto.name === "1st Semester"
            ? schoolLevelTemplateDto.firstSemester
            : schoolLevelTemplateDto.secondSemester;
    const gradeObjects = schoolLevelForCurrent.gradeToSubjects;

    /**
     * Gets the display name for the owner of the grading profiles
     */
    useEffect(() => {
        if (gradingProfiles.length > 0) {
            const ownerGuid = gradingProfiles[0].ownerGuid;
            const options = {
                action: "userDetailsByGuidRead",
                guid: ownerGuid,
                token
            };

            UserDao(options).then((response) => {
                if (response && response.data && response.data.payload) {
                    const { displayName } = response.data.payload;
                    setOwnerDisplayName(displayName);
                }
            });
        }
    }, [gradingProfiles, token]);

    return (
        <div>
            {gradingProfiles
                .sort((a, b) => a.grade - b.grade)
                .map((profile) => {
                    const matchingGrade = gradeObjects.find((grade) => grade.profileGradeId === profile.grade);
                    if (matchingGrade) {
                        return (
                            <GradingAccordionItem
                                key={profile.key}
                                dispatch={dispatch}
                                grade={matchingGrade}
                                gradingProfile={profile}
                                ownerDisplayName={ownerDisplayName}
                                setSavedMessage={setSavedMessage}
                                setShowSaved={setShowSaved}
                                state={state}
                            />
                        );
                    }
                })}
        </div>
    );
};

GradingAccordions.propTypes = {
    dispatch: PropTypes.func.isRequired,
    gradingProfiles: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            grade: PropTypes.number.isRequired,
            ownerGuid: PropTypes.number.isRequired
        })
    ).isRequired,
    setShowSaved: PropTypes.func.isRequired,
    setSavedMessage: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired
};

export default GradingAccordions;
