import React, { useContext, useEffect, useState } from "react";

import { GlobalContext } from "../contextProvider/ContextProvider";
import ActionButton from "../formInputs/buttons/ActionButton";
import Icon from "../icon/Icon";
import RbA from "../rba/RbA";
import { logout } from "../../utils/auth/Auth";
import { EMPLOYEE_DASHBOARD } from "../../utils/auth/config";
import { isUserDistrictAdmin } from "../../utils/isUser.jsx";

import "../../styles/Header.scss";

/**
 * This is returning the header content with buttons and links
 * @name Header
 * @return {JSX.Element}
 */
const Header = () => {
    const { state } = useContext(GlobalContext);
    const { userDetails } = state || {};
    const { category, givenName, location, surname } = userDetails || {};

    const [isDistrict, setIsDistrict] = useState(false);

    const getLocationShortName = () => {
        return userDetails?.userAttributeDto?.userAttributeMap?.CURRENT_PREDOMINANT_SCHOOL?.shortName || "";
    };

    /* Determine if user is district admin */
    useEffect(() => {
        if (isUserDistrictAdmin(userDetails)) {
            setIsDistrict(true);
        }
    }, [userDetails]);

    const diplayLocation = () => {
        if (isDistrict) {
            return location;
        }
        return getLocationShortName();
    };

    /**
     * Allow the User to "close" the session and window
     * @name closeTab
     */
    const closeTab = () => {
        // remove all sessionStorage
        sessionStorage.clear();
        window.opener = null;
        window.open(EMPLOYEE_DASHBOARD, "_self");
        window.close();
    };

    return (
        <RbA allowedRoles={["EPR_ADMIN", "PRINCIPAL", "ASSISTANT_PRINCIPAL"]}>
            <nav className={`header-container`}>
                {userDetails && (
                    <>
                        <div className="header-name-container">
                            <a
                                aria-label="Guardian Dashboard Page Link"
                                href={EMPLOYEE_DASHBOARD}
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "black"
                                }}
                            >
                                <Icon className="header-dcsd-icon" height={42} iconName="DCSD" width={42} />
                            </a>

                            <h5>
                                Welcome, {givenName} {surname}
                            </h5>
                        </div>
                        <div className="header-position-container">
                            <h5>
                                {category} at {diplayLocation()}
                            </h5>
                        </div>
                        <div className="header-right" style={{ transform: "scale(0.7, 0.7)" }}>
                            <div className="nav-button-container">
                                <a
                                    aria-label="Resources Button"
                                    className="a-action-button-dup"
                                    href="https://www.dcsdk12.org"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <span className="nav-button-text">
                                        Resources
                                        <Icon className="nav-button-icon" fill="white" iconName="WRENCH" width={30} />
                                    </span>
                                </a>
                                <ActionButton aria-label="Exit Button" className="action-button-reg" onClick={closeTab}>
                                    <span className="nav-button-text">
                                        Dashboard
                                        <Icon className="nav-button-icon" fill="white" iconName="HOME" width={30} />
                                    </span>
                                </ActionButton>
                                <ActionButton aria-label="Logout Button" className="action-button-reg" onClick={logout}>
                                    <span className="nav-button-text">
                                        Logout
                                        <Icon className="nav-button-icon" fill="white" iconName="LOGOUT" width={30} />
                                    </span>
                                </ActionButton>
                            </div>
                        </div>
                    </>
                )}
            </nav>
        </RbA>
    );
};

export default Header;
