import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog.jsx";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao.jsx";
import LoadingSvg from "../loadingSvg/LoadingSvg.jsx";
import ManageTemplateTable from "./ManageTemplateTable.jsx";

import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/SchoolTemplate.scss";

/**
 * This is the School Template page for epr for create and manage
 * @name SchoolTemplate
 * @returns {JSX.Element}
 */
const SchoolTemplate = ({
    applicableGrades,
    combinedGrades,
    handleClearSchoolOnDelete,
    setLabelObj,
    setSelectedLink,
    setTemplateTouchedFlag,
    status
}) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState("false");
    const { state } = useContext(GlobalContext);

    const { schoolYearDto, schoolLevelTemplateDto, token, userDetails } = state;
    const locationKey = userDetails?.userAttributeDto?.userAttributeMap?.CURRENT_PREDOMINANT_SCHOOL?.locationKey;
    const locationName = userDetails?.userAttributeDto?.userAttributeMap?.CURRENT_PREDOMINANT_SCHOOL?.name;

    const getLabel = () => {
        if (!combinedGrades) {
            return "Create  ";
        } else {
            return "Manage ";
        }
    };

    const handleCreate = () => {
        const options = {
            action: "createSchoolTemplates",
            guid: userDetails.uid,
            locationKey,
            schoolYearKey: schoolYearDto.key,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response && !response.errors) {
                setTemplateTouchedFlag(true);

                toast.success(`School EPR template successfully created for ${schoolYearDto.name} school year`, {
                    autoClose: 3000
                });
                setLoader(false);
            } else {
                toast.error(`School EPR template could not be created. Please try again later.`, {
                    autoClose: false
                });
                setLoader(false);
            }
        });
    };

    const handleDelete = () => {
        const options = {
            action: "deleteTemplate",
            locationKey,
            schoolYearKey: schoolYearDto.key,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response && !response.errors) {
                setTemplateTouchedFlag(false);
                handleClearSchoolOnDelete();
                toast.success(`School EPR template successfully deleted for ${schoolYearDto.name} school year`, {
                    autoClose: 3000
                });
                setOpen("false");
            } else {
                toast.error(`School EPR template could not be deleted. Please try again later.`, {
                    autoClose: false
                });
            }
            setLoader(false);
        });
    };

    const handleOnClick = (linkName) => {
        const array = linkName.split("/").map((str) => {
            return { label: str, path: `/${str}` };
        });
        setSelectedLink(linkName);
        setLabelObj(array);
        navigate(`/${linkName.split(" ")[0].toLowerCase()}`);
    };

    return (
        <div className="template-wrapper">
            <div className="template-title-section">
                {!loader && <h4 className="page-title">{getLabel()}School Template</h4>}
                {!loader && combinedGrades && (
                    <div style={{ height: "30px" }}>
                        <ActionButton
                            label="Delete School Template"
                            className="action-button-delete"
                            onClick={() => setOpen("confirm-school-delete")}
                        />
                    </div>
                )}
            </div>
            {!combinedGrades && !schoolLevelTemplateDto && !loader && (
                <section className="create-template">
                    {status === "ready" && (
                        <>
                            <div>
                                <div className="golden-rod">The district template is now available!</div>
                                Click &quot;Create School Template&quot; to initiate creation of the{" "}
                                {schoolYearDto.name} school template for {locationName}. Once created, you will be able
                                to review all reportable content items for each grade and subject as defined by the
                                district.
                            </div>
                            <div className="create-btn-wrapper">
                                <ActionButton
                                    className="action-button-reg"
                                    disable={status === "waiting"}
                                    label="Create School Template"
                                    onClick={() => handleCreate()}
                                />
                            </div>
                        </>
                    )}
                    {status === "waiting" && (
                        <div className="golden-rod">
                            The district template for {schoolYearDto.name} is not yet available to use. Please check
                            back soon!
                        </div>
                    )}
                </section>
            )}
            {combinedGrades && !loader && (
                <>
                    <div className="school-manage-helper-text">
                        Track your progress on defining the reporting periods for your school templates, and release
                        completed templates to your teachers.
                    </div>
                    <section className="manage-template">
                        <ManageTemplateTable
                            applicableGrades={applicableGrades}
                            canRelease
                            combinedGrades={combinedGrades}
                            setLoader={setLoader}
                            setTemplateTouchedFlag={setTemplateTouchedFlag}
                        />
                        <div className="create-btn-wrapper">
                            <ActionButton
                                className="action-button-reg"
                                disable={status === "waiting"}
                                label="Define Reporting Periods"
                                onClick={() => handleOnClick("HOME/TEMPLATE/DEFINE REPORTING PERIODS")}
                            />
                        </div>
                    </section>
                </>
            )}
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-delete"
                            label="Confirm Delete"
                            onClick={() => {
                                handleDelete();
                            }}
                        />
                    </div>
                }
                ariaLabel="Delete Confirmation Dialog"
                hasCloseX={false}
                id="confirm-school-delete"
                open={open}
                title="Delete Template Confirmation"
            >
                <div>
                    Please confirm you would like to delete the school EPR template. Please note that this action will
                    necessitate the creation of a new template and subsequent defining of reporting periods.
                </div>
            </DcsdDialog>
            {loader && <LoadingSvg />}
        </div>
    );
};

SchoolTemplate.propTypes = {
    applicableGrades: PropTypes.instanceOf(Array),
    combinedGrades: PropTypes.instanceOf(Array),
    handleClearSchoolOnDelete: PropTypes.func,
    setLabelObj: PropTypes.func,
    setSelectedLink: PropTypes.func,
    setTemplateTouchedFlag: PropTypes.func,
    status: PropTypes.string
};

export default SchoolTemplate;
