import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";

import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao.jsx";
import EprContentReportable from "../display/EprContentReportable.jsx";
import Icon from "../icon/Icon.jsx";
import LoadingSvg from "../loadingSvg/LoadingSvg.jsx";
import RbA from "../rba/RbA.jsx";
import TabButton from "../formInputs/buttons/TabButton.jsx";

import { getTotalCountByGrade, getTotalCountByStandard } from "../../utils/ManageEpr/getContentCounts.jsx";
import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/ManageTemplateTable.scss";

/**
 * This is the district template page for create, view, and delete
 * @name ManageDistrictTemplate
 * @returns {JSX.Element}
 */
const ManageDistrictTemplate = ({ allContent, templateReleased }) => {
    const allowedRolesArray = ["EPR_ADMIN"];

    const [allGrades, setAllGrades] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reviewedLoader, setReviewedLoader] = useState(false);
    const [selectedGrade, setSelectedGrade] = useState({ key: "1", name: "K", gradeLevel: "K", defaultGrade: true });
    const [selectedSubject, setSelectedSubject] = useState("ELA");

    const { dispatch, state } = useContext(GlobalContext);
    const { districtTemplateDto, schoolYearDto, token, userDetails } = state;

    const getStandardCount = (standard) => {
        const standardTotal = getTotalCountByStandard(standard);
        return standardTotal;
    };

    const handleComplete = (profileGradeId, profileSubjectId, isCompleted) => {
        const options = {
            action: "completeSubjectGradeTemplate",
            guid: userDetails.uid,
            profileGradeId,
            profileSubjectIds: `${profileSubjectId}`,
            isCompleted: !isCompleted,
            token
        };
        setReviewedLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                const options = {
                    action: "createDistrictTemplate",
                    guid: userDetails.uid,
                    schoolYearKey: schoolYearDto.key,
                    token
                };
                ElementaryProgressReportDao(options).then((response) => {
                    if (response) {
                        const { payload } = response.data;
                        dispatch({
                            type: "DistrictTemplateDto",
                            districtTemplateDto: payload
                        });
                    } else {
                        window.refresh();
                    }
                    setReviewedLoader(false);
                });
            } else {
                toast.error(`Subject could not be marked as reviewed. Please try again later. `, {
                    autoClose: false
                });
                setReviewedLoader(false);
            }
        });
    };

    const subjectAreas = districtTemplateDto.gradeToSubjects[0].subjectToStandards.map((subject) => {
        return subject.subjectName;
    });

    /**
     * If there are no selectable grades loaded get all grades
     */
    useEffect(() => {
        if (allGrades.length === 0) {
            const options = {
                action: "allGradesRead",
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    const defaultGrades = payload.filter((grade) => grade.defaultGrade === true);
                    setAllGrades(defaultGrades);
                }
                setLoader(false);
            });
        }
    }, [allGrades, setAllGrades, token]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <div className="gutter-90">
                {loader && <LoadingSvg />}
                {!loader && (
                    <section className="review-content-section">
                        <div className="dt-grade-btn-container">
                            {allGrades
                                .filter((grade) => grade.defaultGrade === true)
                                .map((grade, index) => {
                                    const { gradeLevel, name } = grade;
                                    const isSelected = selectedGrade?.gradeLevel === gradeLevel;
                                    const gradeButton = allContent?.find(
                                        (contentGrade) => contentGrade.gradeId === parseInt(grade.key, 10)
                                    );
                                    const gradeProfileObj = districtTemplateDto?.gradeToSubjects.find(
                                        (gradeObj) => gradeObj.gradeLevel === gradeLevel
                                    );

                                    const gradeCount = gradeButton
                                        ? getTotalCountByGrade(gradeButton.subjectToStandards)
                                        : "-";
                                    const isGradeComplete = gradeProfileObj.subjectToStandards.every(
                                        (sub) => sub.completed === true
                                    );
                                    return (
                                        <div className="btn-container" key={`${grade.key}-${index}`}>
                                            <TabButton
                                                className={isSelected ? "tab-button-selected" : "tab-button"}
                                                onClick={() => setSelectedGrade(grade)}
                                                size="large"
                                                width={135}
                                            >
                                                <div>
                                                    <p className="primary">{name}</p>
                                                    <p className="secondary">{gradeCount} Total Items</p>
                                                </div>
                                            </TabButton>
                                            {isGradeComplete && (
                                                <div className="completed-status-on-grade-button">
                                                    <Icon height={25} fill="green" iconName="COMPLETE" width={25} />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                        {!selectedGrade && (
                            <>
                                <hr />
                                <div className="select-grade-message">Select a grade to review.</div>
                            </>
                        )}
                        {districtTemplateDto && selectedGrade && (
                            <div className="dt-review-grey-container">
                                <div className="dt-review-sub-section-title">
                                    {selectedGrade?.name === "K" ? "Kindergarten" : `${selectedGrade.name} Grade`} -{" "}
                                    {selectedSubject}
                                </div>
                                <hr style={{ height: "2px", backgroundColor: "#EDEDED", color: "#EDEDED" }} />
                                <div style={{ display: "flex" }}>
                                    <div className="content-area-container">
                                        {subjectAreas.map((sub) => {
                                            const subjectObject = districtTemplateDto?.gradeToSubjects
                                                .find((grade) => grade.gradeLevel === selectedGrade.gradeLevel)
                                                .subjectToStandards.find((subject) => subject.subjectName === sub);
                                            const { completed } = subjectObject || false;
                                            const missingContent =
                                                districtTemplateDto?.gradeToSubjects
                                                    .find((grade) => grade.gradeLevel === selectedGrade.gradeLevel)
                                                    .subjectToStandards.find((subject) => subject.subjectName === sub)
                                                    .cdeStandards.length === 0;
                                            return (
                                                <div
                                                    key={sub}
                                                    className={
                                                        selectedSubject === sub
                                                            ? "subject-button-selected"
                                                            : "subject-button"
                                                    }
                                                    onClick={() => setSelectedSubject(sub)}
                                                >
                                                    {sub}
                                                    {completed && <Icon width={24} height={24} iconName="COMPLETED" />}
                                                    {!completed && districtTemplateDto && missingContent && (
                                                        <Icon
                                                            fill={selectedSubject === sub ? "#FFF" : "#CB321A"}
                                                            width={24}
                                                            height={24}
                                                            iconName="ERROR_WARNING"
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="content-container">
                                        {allContent
                                            ?.find((grade) => grade.gradeLevel === selectedGrade.gradeLevel)
                                            .subjectToStandards.map((subject) => {
                                                const grade = allContent?.find(
                                                    (grade) => grade.gradeLevel === selectedGrade.gradeLevel
                                                );
                                                const { gradeLevel } = grade;
                                                if (subject.subjectName === selectedSubject) {
                                                    const gradeProfileObj = districtTemplateDto?.gradeToSubjects.find(
                                                        (grade) => grade.gradeLevel === selectedGrade.gradeLevel
                                                    );

                                                    const subProfileObj = gradeProfileObj?.subjectToStandards.find(
                                                        (subject) => subject.subjectName === selectedSubject
                                                    );
                                                    const complete = subProfileObj?.completed;

                                                    return (
                                                        <div key={subject.subjectId}>
                                                            {subProfileObj.cdeStandards.length > 0 ? (
                                                                <div
                                                                    key={subject.profileSubjectId}
                                                                    style={{ marginBottom: "24px" }}
                                                                >
                                                                    <Accordion>
                                                                        {subject.cdeStandards.map((standard, index) => {
                                                                            const {
                                                                                gles,
                                                                                gradeToContentKey,
                                                                                reportFlag,
                                                                                standardName
                                                                            } = standard;
                                                                            const displayStandardName =
                                                                                standardName?.substring(
                                                                                    standardName.indexOf(".") + 1
                                                                                );
                                                                            const itemPerStandardCount =
                                                                                getStandardCount(standard);
                                                                            return (
                                                                                <Accordion.Item
                                                                                    className="dt-std-accordion-item"
                                                                                    eventKey={index}
                                                                                    key={`${grade.gradeLevel}-${standard.standardName}`}
                                                                                >
                                                                                    <EprContentReportable
                                                                                        contentKey={gradeToContentKey}
                                                                                        count={itemPerStandardCount}
                                                                                        grade={grade.gradeLevel}
                                                                                        isAccordionButton
                                                                                        readOnly
                                                                                        reportFlag={reportFlag}
                                                                                        title={
                                                                                            <>
                                                                                                <b>
                                                                                                    Standard {index + 1}
                                                                                                    :
                                                                                                </b>{" "}
                                                                                                {displayStandardName}
                                                                                            </>
                                                                                        }
                                                                                        type="cdeStandards"
                                                                                    />
                                                                                    <Accordion.Body>
                                                                                        {gles.map((gle) => {
                                                                                            const {
                                                                                                gleShortName,
                                                                                                gleName,
                                                                                                evidenceOutcomes,
                                                                                                gradeToContentKey,
                                                                                                reportFlag
                                                                                            } = gle;
                                                                                            const displayGleName =
                                                                                                gleName.substring(
                                                                                                    gleName.indexOf(
                                                                                                        "."
                                                                                                    ) + 1
                                                                                                );
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginLeft:
                                                                                                            "24px"
                                                                                                    }}
                                                                                                    key={`${gradeLevel}-${gleName}`}
                                                                                                >
                                                                                                    <EprContentReportable
                                                                                                        contentKey={
                                                                                                            gradeToContentKey
                                                                                                        }
                                                                                                        grade={
                                                                                                            gradeLevel
                                                                                                        }
                                                                                                        readOnly
                                                                                                        reportFlag={
                                                                                                            reportFlag
                                                                                                        }
                                                                                                        title={
                                                                                                            <>
                                                                                                                <b>
                                                                                                                    {
                                                                                                                        gleShortName
                                                                                                                    }
                                                                                                                    :
                                                                                                                </b>{" "}
                                                                                                                {
                                                                                                                    displayGleName
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                        type="gles"
                                                                                                    />
                                                                                                    <div
                                                                                                        style={{
                                                                                                            marginLeft:
                                                                                                                "32px",
                                                                                                            fontSize:
                                                                                                                "1rem"
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>
                                                                                                            Evidence
                                                                                                            Outcomes:
                                                                                                        </b>
                                                                                                    </div>
                                                                                                    {evidenceOutcomes.map(
                                                                                                        (eo) => {
                                                                                                            const {
                                                                                                                body,
                                                                                                                evidenceOutcomeChildList,
                                                                                                                gradeToContentKey,
                                                                                                                reportFlag
                                                                                                            } = eo;
                                                                                                            return (
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        marginLeft:
                                                                                                                            "40px"
                                                                                                                    }}
                                                                                                                    key={`${gradeLevel}-${gradeToContentKey}`}
                                                                                                                >
                                                                                                                    <EprContentReportable
                                                                                                                        contentKey={
                                                                                                                            gradeToContentKey
                                                                                                                        }
                                                                                                                        grade={
                                                                                                                            gradeLevel
                                                                                                                        }
                                                                                                                        readOnly
                                                                                                                        reportFlag={
                                                                                                                            reportFlag
                                                                                                                        }
                                                                                                                        title={
                                                                                                                            body
                                                                                                                        }
                                                                                                                        type="evidenceOutcomes"
                                                                                                                    />
                                                                                                                    {evidenceOutcomeChildList?.length >
                                                                                                                        0 &&
                                                                                                                        evidenceOutcomeChildList?.map(
                                                                                                                            (
                                                                                                                                cEo
                                                                                                                            ) => {
                                                                                                                                return (
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            marginLeft:
                                                                                                                                                "48px"
                                                                                                                                        }}
                                                                                                                                        key={`${gradeLevel}-${cEo.gradeToContentKey}`}
                                                                                                                                    >
                                                                                                                                        <EprContentReportable
                                                                                                                                            contentKey={
                                                                                                                                                cEo.gradeToContentKey
                                                                                                                                            }
                                                                                                                                            grade={
                                                                                                                                                gradeLevel
                                                                                                                                            }
                                                                                                                                            reportFlag={
                                                                                                                                                cEo.reportFlag
                                                                                                                                            }
                                                                                                                                            readOnly
                                                                                                                                            title={
                                                                                                                                                cEo.body
                                                                                                                                            }
                                                                                                                                            type="evidenceOutcomeChildList"
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                );
                                                                                                                            }
                                                                                                                        )}
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            );
                                                                        })}
                                                                    </Accordion>
                                                                </div>
                                                            ) : (
                                                                <div className="no-content-warning">
                                                                    <div className="no-content-warning-title">
                                                                        <Icon iconName="ERROR_WARNING" fill="#FEBF31" />
                                                                        <span>No Reportable Content</span>
                                                                    </div>
                                                                    <hr />
                                                                    No reportable content has been selected for this
                                                                    grade and subject. If this is accurate, please
                                                                    affirm by clicking the “Reviewed” checkbox. If this
                                                                    is not accurate, you will need to first delete the
                                                                    default district EPR template and then return to the
                                                                    Manage EPR Content section to select reportable
                                                                    content for the grade and subject.{" "}
                                                                    <div
                                                                        style={{ marginTop: "8px", fontWeight: "500" }}
                                                                    >
                                                                        <u>
                                                                            Please note that you will need to
                                                                            re-complete all content review for all
                                                                            grades once you create the new default
                                                                            district EPR template as this information
                                                                            will not be persisted after delete.{" "}
                                                                        </u>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="complete-check-wrapper">
                                                                <label className="complete-check-btn">
                                                                    {reviewedLoader && <LoadingSvg />}
                                                                    <input
                                                                        disabled={reviewedLoader || templateReleased}
                                                                        type="checkbox"
                                                                        checked={complete}
                                                                        onChange={() =>
                                                                            handleComplete(
                                                                                gradeProfileObj.profileGradeId,
                                                                                subProfileObj.profileSubjectId,
                                                                                complete
                                                                            )
                                                                        }
                                                                    />
                                                                    <span>Reviewed</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </section>
                )}
            </div>
        </RbA>
    );
};

ManageDistrictTemplate.propTypes = {
    allContent: PropTypes.instanceOf(Array),
    templateReleased: PropTypes.bool
};

ManageDistrictTemplate.defaultProps = {
    allContent: null
};

export default ManageDistrictTemplate;
