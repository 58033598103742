const getStandardCount = (standard) => (standard.reportFlag === true ? 1 : 0);
const getGleCount = (standard) => {
    return standard.gles?.reduce((acc, val) => {
        if (val.reportFlag === true) {
            return acc + 1;
        }
        return acc;
    }, 0);
};

const getEvidenceOutcomeCount = (standard) => {
    return standard.gles?.reduce((totalCount, gle) => {
        let eoCount = gle.evidenceOutcomes?.reduce((acc, val) => {
            if (val.reportFlag === true) {
                return acc + 1;
            }
            return acc;
        }, 0);
        totalCount = totalCount + eoCount;
        return totalCount;
    }, 0);
};

const getEvidenceOutcomeChildListCount = (standard) => {
    return standard.gles?.reduce((totalCount, gle) => {
        gle.evidenceOutcomes?.forEach((eo) => {
            let childCount = eo.evidenceOutcomeChildList?.reduce((acc, val) => {
                if (val.reportFlag === true) {
                    return acc + 1;
                }
                return acc;
            }, 0);
            childCount === undefined ? (childCount = 0) : childCount;
            totalCount = totalCount + childCount;
            return totalCount;
        });
        return totalCount === isNaN(totalCount) ? 0 : totalCount;
    }, 0);
};

export const getTotalCountByStandard = (standard) => {
    return (
        getStandardCount(standard) +
        getGleCount(standard) +
        getEvidenceOutcomeCount(standard) +
        getEvidenceOutcomeChildListCount(standard)
    );
};

export const getTotalBySubjectPerGrade = (subject) => {
    return subject.cdeStandards.reduce((total, standard) => {
        const stdCount = getTotalCountByStandard(standard);
        return (total += stdCount);
    }, 0);
};

export const getTotalCountByGrade = (subjects) => {
    let count = 0;
    subjects.forEach((subject) => {
        subject.cdeStandards.forEach((standard) => {
            count = count + getTotalCountByStandard(standard);
        });
    });
    return count;
};
