import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AdminRow from "../components/display/AdminRow.jsx";
import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import RbA from "../components/rba/RbA.jsx";
import SchoolAdmin from "./SchoolAdmin.jsx";
import TeacherHome from "./TeacherHome.jsx";

import { GlobalContext } from "../components/contextProvider/ContextProvider";
import { isUserDistrictAdmin, isUserSchoolAdmin } from "../utils/isUser.jsx";

import "../styles/global/Global.scss";
import "../styles/Home.scss";

/**
 * This is the home page for epr
 * @name Home
 * @returns {JSX.Element}
 */
const Home = () => {
    const [isDistrict, setIsDistrict] = useState(false);
    const [isSchoolAdmin, setIsSchoolAdmin] = useState(false);

    const allowedRolesArray = ["EPR_ADMIN", "PRINCIPAL", "ASSISTANT_PRINCIPAL", "TEACHER"];

    const { state } = useContext(GlobalContext);
    const { userDetails } = state || {};
    const navigate = useNavigate();

    /**
     * @name handleRouting
     * @returns
     */
    const handleRouting = (e) => {
        const { name } = e.target;
        navigate(`/${name}`);
    };

    /* Determine if user is district level or school and if school if they are admin */
    useEffect(() => {
        if (isUserDistrictAdmin(userDetails)) {
            setIsDistrict(true);
        }
        if (isUserSchoolAdmin(userDetails)) {
            setIsSchoolAdmin(true);
        }
    }, [userDetails]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            {isDistrict && <BreadCrumb labelObj={[{ label: "Home", path: "/home" }]} />}
            <ToastContainer style={{ width: "50%" }} />
            {isDistrict && (
                <div className="gutter-90">
                    <hr />
                    <AdminRow
                        handleRouting={handleRouting}
                        header="Select Reportable EPR Content"
                        icon="HAMBURGER"
                        message1="Select all reportable content items from the Colorado Academic Standards as the basis for the default district EPR template"
                        name="manage-epr-content"
                        userDetails={userDetails}
                    />
                    <hr />
                    <AdminRow
                        handleRouting={handleRouting}
                        header="Manage District EPR Template"
                        icon="HAMBURGER"
                        message1="Create, validate and release the default district EPR template for the current school year"
                        name="district-template"
                        userDetails={userDetails}
                    />
                    <hr />
                </div>
            )}
            {!isDistrict && isSchoolAdmin && <SchoolAdmin />}
            {!isDistrict && !isSchoolAdmin && <TeacherHome />}
        </RbA>
    );
};

export default Home;
