import { SERVICE_HOST } from "../utils/auth/config";

export const EPR_SERVICE = `${SERVICE_HOST}/epr-sb/v0`;

export const EPR_REPORTABLE_CONTENT = `${EPR_SERVICE}/content`;
export const EPR_REPORTABLE_BY_CONTENT_AREA = `${EPR_REPORTABLE_CONTENT}/by_content_area`; // <contentAreaId>/index.json
export const EPR_REPORTABLE_CONTENT_UPDATE = `${EPR_REPORTABLE_CONTENT}/update_report_flag`; // <contentAreaId>/index.json
export const REFERENCE_DATA = `${EPR_SERVICE}/reference_data`;
export const ALL_CONTENT_AREAS = `${REFERENCE_DATA}/content_area/index.json`;
export const ALL_GRADES = `${REFERENCE_DATA}/grade/index.json`;
