import React from "react";
import PropTypes from "prop-types";

import "../../../styles/MoveButton.scss";

/**
 * A button for moving items up or down
 * @name MoveButton
 * @param {string} ariaLabel
 * @param {string} direction
 * @param {bool} disabled
 * @param {func} onClick
 * @return {JSX.Element}
 * @constructor
 */
const MoveButton = ({ ariaLabel, direction, disabled, onClick }) => (
    <button aria-label={ariaLabel} className="button-icon-large" onClick={onClick} disabled={disabled}>
        <i className={`bi bi-caret-${direction}-fill`}></i>
    </button>
);

MoveButton.propTypes = {
    ariaLabel: PropTypes.string,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default MoveButton;
