import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../styles/SidebarNav.scss";

const TeacherSidebarMenu = ({ state, dispatch }) => {
    const { gradingProfiles, navCollapsed, selectedLink } = state;
    const navigate = useNavigate();

    const handleOnClick = (linkName) => {
        const labelArray = linkName.split("/").map((str) => ({ label: str, path: `/${str}` }));
        dispatch({ type: "SET_SELECTED_LINK", payload: linkName });
        dispatch({
            type: "SET_LABEL_OBJ",
            payload: linkName === "HOME" ? [{ label: "Home", path: "/home" }] : labelArray
        });
        navigate(`/${linkName.split(" ")[0].toLowerCase()}`);
    };

    return !navCollapsed ? (
        <div className="expanded-nav-container">
            <div
                className="expanded-nav-arrow-row"
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        dispatch({ type: "TOGGLE_NAV" });
                    }
                }}
                onClick={() => dispatch({ type: "TOGGLE_NAV" })}
                tabIndex={0}
            >
                <i className="bi bi-arrow-bar-left white arrow-icon"></i>
            </div>
            <div
                className={`expanded-nav-row ${selectedLink === "HOME" ? "selected-row" : "not-selected-row"}`}
                onClick={() => handleOnClick("HOME")}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOnClick("HOME");
                    }
                }}
                tabIndex={0}
            >
                <i className={`bi bi-house-fill ${selectedLink === "HOME" ? "dk-blue" : "white"}`}></i>
                <span className={`expanded-nav-link ${selectedLink === "HOME" ? "dk-blue" : "white"}`}>Home</span>
            </div>
            <div
                className={`expanded-nav-row ${selectedLink === "HOME/SUMMARY" ? "selected-row" : "not-selected-row"}`}
                onClick={() => handleOnClick("HOME/SUMMARY")}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOnClick("HOME/SUMMARY");
                    }
                }}
                tabIndex={0}
            >
                <i className={`bi bi-card-checklist ${selectedLink === "HOME/SUMMARY" ? "dk-blue" : "white"}`}></i>
                <span className={`expanded-nav-link ${selectedLink === "HOME/SUMMARY" ? "dk-blue" : "white"}`}>
                    EPR Summary
                </span>
            </div>
            {gradingProfiles && gradingProfiles.length > 0 && selectedLink.includes("HOME/SUMMARY") && (
                <div>
                    <div
                        className={`expanded-nav-sub-row ${selectedLink.includes("HOME/SUMMARY/ROSTER") ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME/SUMMARY/ROSTER")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME/SUMMARY/ROSTER");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i
                            className={`bi bi-people-fill ${selectedLink.includes("HOME/SUMMARY/ROSTER") ? "dk-blue" : "white"}`}
                        ></i>
                        <span
                            className={`expanded-nav-link ${selectedLink.includes("HOME/SUMMARY/ROSTER") ? "dk-blue" : "white"}`}
                        >
                            EPR Roster
                        </span>
                    </div>
                    <div
                        className={`expanded-nav-sub-row ${selectedLink.includes("HOME/SUMMARY/HOMEROOM-GROUPS") ? "selected-row" : "not-selected-row"}`}
                        onClick={() => handleOnClick("HOME/SUMMARY/HOMEROOM-GROUPS")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOnClick("HOME/SUMMARY/HOMEROOM-GROUPS");
                            }
                        }}
                        tabIndex={0}
                    >
                        <i
                            className={`bi bi-person-rolodex ${selectedLink.includes("HOME/SUMMARY/HOMEROOM-GROUPS") ? "dk-blue" : "white"}`}
                        ></i>
                        <span
                            className={`expanded-nav-link ${selectedLink.includes("HOME/SUMMARY/HOMEROOM-GROUPS") ? "dk-blue" : "white"}`}
                        >
                            Homeroom Groups
                        </span>
                    </div>
                </div>
            )}
        </div>
    ) : (
        <div className="collapsed-nav-container">
            <div
                className="collapsed-nav-row"
                onClick={() => dispatch({ type: "TOGGLE_NAV" })}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        dispatch({ type: "TOGGLE_NAV" });
                    }
                }}
                tabIndex={0}
            >
                <i className={`bi bi-arrow-bar-right white arrow-icon`}></i>
            </div>
            <div
                className={`collapsed-nav-row ${selectedLink === "HOME" ? "selected-row" : "not-selected-row"}`}
                onClick={() => handleOnClick("HOME")}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOnClick("HOME");
                    }
                }}
                tabIndex={0}
            >
                <i className={`bi bi-house-fill ${selectedLink === "HOME" ? "dk-blue" : "white"}`}></i>
            </div>
            <div
                className={`collapsed-nav-row ${selectedLink === "HOME/SUMMARY" ? "selected-row" : selectedLink.includes("HOME/SUMMARY/ROSTER") || selectedLink.includes("HOMEROOM") ? "child-selected" : "not-selected-row"}`}
                onClick={() => handleOnClick("HOME/SUMMARY")}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOnClick("HOME/SUMMARY");
                    }
                }}
                tabIndex={0}
            >
                <i className={`bi bi-card-checklist ${selectedLink === "HOME/SUMMARY" ? "dk-blue" : "white"}`}></i>
            </div>
        </div>
    );
};

TeacherSidebarMenu.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default TeacherSidebarMenu;
