import React from "react";
import { Route, Routes } from "react-router-dom";
import { MathJaxContext } from "better-react-mathjax";
import Layout from "./components/Layout";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import LoadTest from "./components/LoadTest";
import DistrictTemplate from "./segments/DistrictTemplate";
import Home from "./segments/Home";
import ManageEprContent from "./segments/ManageEprContent";

/**
 * Routing for EPR
 * @name App
 * @return {{}}
 */
const App = () => {
    const config = {
        loader: { load: ["input/tex", "output/svg"] },
        tex: {
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ]
        }
    };
    return (
        <MathJaxContext config={config} src={"https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"}>
            <Routes>
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Home />} />
                </Route>
                <Route path="/home" element={<PrivateRoute />}>
                    <Route path="/home" element={<Home />} />
                </Route>
                <Route path="/manage-epr-content" element={<PrivateRoute />}>
                    <Route path="/manage-epr-content" element={<ManageEprContent />} />
                </Route>
                <Route path="/district-template" element={<PrivateRoute />}>
                    <Route path="/district-template" element={<DistrictTemplate />} />
                </Route>
                <Route path="/home/template" element={<PrivateRoute />}>
                    <Route path="/home/template" element={<Home />} />
                </Route>
                <Route path="/home/template/define" element={<PrivateRoute />}>
                    <Route path="/home/template/define" element={<Home />} />
                </Route>
                <Route path="/home/sles" element={<PrivateRoute />}>
                    <Route path="/home/sles" element={<Home />} />
                </Route>
                {/* Teacher Routes */}
                <Route path="/home/summary" element={<PrivateRoute />}>
                    <Route path="/home/summary" element={<Home />} />
                </Route>
                <Route path="/home/summary/roster" element={<PrivateRoute />}>
                    <Route path="/home/summary/roster" element={<Home />} />
                    <Route path="/home/summary/roster/:gradingProfileId" element={<Home />} />
                </Route>
                <Route path="/home/summary/homeroom-groups" element={<PrivateRoute />}>
                    <Route path="/home/summary/homeroom-groups" element={<Home />} />
                    <Route path="/home/summary/homeroom-groups/:gradingProfileId" element={<Home />} />
                </Route>
                <Route path="/notFound" element={<NotFound />} />
                <Route default element={<NotFound />} />
                {/* Development ONLY */}
                {process.env.NODE_ENV !== "production" && (
                    <>
                        <Route path="/backdoor" exact element={<Login />} />
                        <Route path="/layout" element={<Layout />} />

                        <Route path="/loadtest" exact element={<NotFound />} />

                        <Route path="/loadtest/:userName" exact element={<PrivateRoute />}>
                            <Route path="/loadtest/:userName" exact element={<LoadTest />} />
                        </Route>
                    </>
                )}
            </Routes>
        </MathJaxContext>
    );
};

export default App;
