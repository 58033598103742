import React, { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import PropTypes from "prop-types";

import EprContentReportable from "./EprContentReportable";
import { getTotalCountByStandard } from "../../utils/ManageEpr/getContentCounts";

import "../../styles/ManageEprContent.scss";

/**
 * Epr Content Component
 * @name EprContent
 * @param {[]} allGrades
 * @param {func} handleAddClick
 * @param {{}} reportableContent
 * @param {[]} selectedGrades
 * @return {JSX.element}
 * @constructor
 */
const EprContent = ({ allGrades, handleAddClick, reportableContent, selectedGrades }) => {
    const getCountFlagTrue = (standard) => {
        const standardTotal = getTotalCountByStandard(standard);
        return standardTotal;
    };

    return (
        <div className="outcomes-container">
            {reportableContent?.gradeLevels?.length > 0 &&
                reportableContent.gradeLevels.map((grade) => {
                    const { gradeLevel, cdeStandards } = grade;
                    const gradeLevelName = allGrades?.find((grade) => grade.gradeLevel === gradeLevel).name;
                    const showGrade = selectedGrades.find(
                        (selectedGrade) => selectedGrade.gradeLevel === grade.gradeLevel
                    );
                    return (
                        showGrade && (
                            <div className="grade-container" key={gradeLevel}>
                                <h4 className="grade-name">
                                    {gradeLevelName === "K" ? "Kindergarten" : `${gradeLevelName} Grade`}
                                </h4>
                                <Accordion alwaysOpen>
                                    {cdeStandards.map((standard, index) => {
                                        const { gles, gradeToContentKey, reportFlag, standardName } = standard;
                                        const displayStandardName = standardName.substring(
                                            standardName.indexOf(".") + 1
                                        );
                                        const itemPerStandardCount = getCountFlagTrue(standard);
                                        return (
                                            <Accordion.Item
                                                style={{
                                                    marginBottom: "12px",
                                                    border: "1px solid #A7A9AC",
                                                    borderRadius: "5px",
                                                    overflow: "auto"
                                                }}
                                                eventKey={index}
                                                key={`${gradeLevel}-${standard.standardName}`}
                                            >
                                                <EprContentReportable
                                                    contentKey={gradeToContentKey}
                                                    count={itemPerStandardCount}
                                                    grade={gradeLevel}
                                                    handleAddClick={handleAddClick}
                                                    isAccordionButton
                                                    reportFlag={reportFlag}
                                                    title={
                                                        <Fragment>
                                                            <b>Standard {index + 1}:</b> {displayStandardName}
                                                        </Fragment>
                                                    }
                                                    type="cdeStandards"
                                                />
                                                <Accordion.Body>
                                                    {gles.map((gle) => {
                                                        const {
                                                            gleShortName,
                                                            gleName,
                                                            evidenceOutcomes,
                                                            gradeToContentKey,
                                                            reportFlag
                                                        } = gle;
                                                        const displayGleName = gleName.substring(
                                                            gleName.indexOf(".") + 1
                                                        );
                                                        return (
                                                            <div
                                                                style={{ marginLeft: "16px" }}
                                                                key={`${gradeLevel}-${gleName}`}
                                                            >
                                                                <EprContentReportable
                                                                    contentKey={gradeToContentKey}
                                                                    grade={gradeLevel}
                                                                    handleAddClick={handleAddClick}
                                                                    reportFlag={reportFlag}
                                                                    title={
                                                                        <Fragment>
                                                                            <b>{gleShortName}:</b> {displayGleName}
                                                                        </Fragment>
                                                                    }
                                                                    type="gles"
                                                                />
                                                                <div style={{ marginLeft: "24px" }}>
                                                                    <b>Evidence Outcomes:</b>
                                                                </div>
                                                                {evidenceOutcomes.map((eo) => {
                                                                    const {
                                                                        body,
                                                                        evidenceOutcomeChildList,
                                                                        gradeToContentKey,
                                                                        reportFlag
                                                                    } = eo;
                                                                    return (
                                                                        <div
                                                                            style={{ marginLeft: "32px" }}
                                                                            key={`${gradeLevel}-${gradeToContentKey}`}
                                                                        >
                                                                            <EprContentReportable
                                                                                contentKey={gradeToContentKey}
                                                                                grade={gradeLevel}
                                                                                handleAddClick={handleAddClick}
                                                                                reportFlag={reportFlag}
                                                                                title={body}
                                                                                type="evidenceOutcomes"
                                                                            />
                                                                            {evidenceOutcomeChildList?.length > 0 &&
                                                                                evidenceOutcomeChildList?.map((cEo) => {
                                                                                    return (
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft: "40px"
                                                                                            }}
                                                                                            key={`${gradeLevel}-${cEo.gradeToContentKey}`}
                                                                                        >
                                                                                            <EprContentReportable
                                                                                                contentKey={
                                                                                                    cEo.gradeToContentKey
                                                                                                }
                                                                                                grade={gradeLevel}
                                                                                                handleAddClick={
                                                                                                    handleAddClick
                                                                                                }
                                                                                                reportFlag={
                                                                                                    cEo.reportFlag
                                                                                                }
                                                                                                title={cEo.body}
                                                                                                type="evidenceOutcomeChildList"
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        )
                    );
                })}
        </div>
    );
};

EprContent.propTypes = {
    allGrades: PropTypes.instanceOf(Array),
    handleAddClick: PropTypes.func.isRequired,
    reportableContent: PropTypes.oneOfType([PropTypes.object]).isRequired,
    selectedGrades: PropTypes.instanceOf(Array)
};

EprContent.defaultProps = {
    allGrades: [],
    selectedGrades: []
};

export default EprContent;
