// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.toolbar {
  align-items: center;
  background: #c0def0;
  color: #19608f;
  display: flex;
  font-size: 4rem;
  font-weight: 900;
  height: 80px;
  padding: 0 12px 0 36px;
  width: 100%;
}
.toolbar .divider {
  border-left: 1px solid white;
  height: 60px;
  margin: 0px 24px;
}
.toolbar .toolbar-link-home {
  color: #19608f;
  text-decoration: none;
}
.toolbar span {
  font-size: 1.2rem;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/Toolbar.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AAPA;EACI,mBAAA;EACA,mBDKQ;ECJR,cDEM;ECDN,aAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;AAUJ;AARI;EACI,4BAAA;EACA,YAAA;EACA,gBAAA;AAUR;AAPI;EACI,cDbE;ECcF,qBAAA;AASR;AANI;EACI,iBAAA;EACA,gBAAA;AAQR","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// EPR colors\n// $dashboard-blue: #007bff; this color does not pass Accessibility. Updated version below.\n$dashboard-blue: #0d6efd;\n$dk-blue: #19608f;\n$lt-blue: #7DB7CC;\n$pale-blue: #c0def0;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$DCSD-logo-green: #84ae30;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$red-hover: #E2381D;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$lt-important: #FED883;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n","@import \"../styles/global/Colors\";\n@import \"../styles/global/globalMixins\";\n\n.toolbar {\n    align-items: center;\n    background: $pale-blue;\n    color: $dk-blue;\n    display: flex;\n    font-size: 4rem;\n    font-weight: 900;\n    height: 80px;\n    padding: 0 12px 0 36px;\n    width: 100%;\n\n    .divider {\n        border-left: 1px solid white;\n        height: 60px;\n        margin: 0px 24px;\n    }\n\n    .toolbar-link-home {\n        color: $dk-blue;\n        text-decoration: none;\n    }\n\n    span {\n        font-size: 1.2rem;\n        font-weight: 600;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
