import React from "react";
import { MathJax } from "better-react-mathjax";
import Accordion from "react-bootstrap/Accordion";
import PropTypes from "prop-types";

import "../../styles/ManageEprContent.scss";
import Icon from "../icon/Icon";

/**
 * Epr Reportable (checkbox) Content Component
 * @name EprContentReportable
 * @param {string|number} contentKey
 * @param {string} grade
 * @param {func} handleAddClick
 * @param {bool} isAccordionButton
 * @param {bool} reportFlag
 * @param {string|Node} title
 * @param {string} type
 * @return {JSX.element}
 */

const EprContentReportable = ({
    contentKey,
    count,
    grade,
    handleAddClick,
    readOnly,
    reportFlag,
    templateExists,
    title,
    type,
    isAccordionButton = false
}) => {
    const countString = count === 1 ? "content item selected" : "content items selected";

    const getIcon = (flag) => {
        return (
            <div className="square-wrapper">
                <Icon
                    height={15}
                    iconName={flag ? "CHECK_CIRCLE" : "X_CIRCLE"}
                    fill={flag ? "#698B26" : "#000"}
                    width={15}
                />
            </div>
        );
    };
    return isAccordionButton ? (
        <Accordion.Header className="reportable-content-wrapper-accordion" data-bs-toggle="collapse">
            <div className="rc-accordion-title">
                <label
                    className={
                        readOnly && reportFlag
                            ? "read-selected-header-label selectable-content-label"
                            : "selectable-content-label"
                    }
                >
                    <div className="selectable-input-wrapper">
                        {!readOnly && (
                            <input
                                id={`${contentKey}-${grade}`}
                                checked={reportFlag}
                                disabled={templateExists && grade !== "SEVENTH" && grade !== "EIGHTH"}
                                onChange={() =>
                                    handleAddClick({ grade, type, gradeToContentKey: contentKey, reportFlag })
                                }
                                style={{ marginRight: "24px" }}
                                type="checkbox"
                            />
                        )}
                        {readOnly && getIcon(reportFlag)}
                        <p className="content-title">{title}</p>
                    </div>
                    <p className="content-title italic">
                        {count}
                        <span style={{ marginLeft: "8px" }}>{countString}</span>
                    </p>
                </label>
            </div>
        </Accordion.Header>
    ) : (
        <div className="reportable-content-wrapper">
            <label className={readOnly && reportFlag ? "read-selected-child-label" : "selectable-child-label"}>
                {!readOnly && (
                    <input
                        id={`${contentKey}-${grade}`}
                        checked={reportFlag}
                        disabled={templateExists && grade !== "SEVENTH" && grade !== "EIGHTH"}
                        onChange={() => handleAddClick({ grade, type, gradeToContentKey: contentKey, reportFlag })}
                        className="active-check-input"
                        type="checkbox"
                    />
                )}
                {readOnly && getIcon(reportFlag)}
                {type === "gradeEvidenceOutcome" || type === "gradeEvidenceOutcomeChild" ? (
                    <MathJax hideUntilTypeset={"first"} tabIndex={-1}>
                        <p className={"content-title"} dangerouslySetInnerHTML={{ __html: title }} />
                    </MathJax>
                ) : (
                    <MathJax hideUntilTypeset={"first"} tabIndex={-1}>
                        <p className={"content-title"}>{title}</p>
                    </MathJax>
                )}
            </label>
        </div>
    );
};

EprContentReportable.propTypes = {
    contentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    count: PropTypes.number,
    grade: PropTypes.string.isRequired,
    handleAddClick: PropTypes.func,
    isAccordionButton: PropTypes.bool,
    readOnly: PropTypes.bool,
    reportFlag: PropTypes.bool,
    templateExists: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string.isRequired
};

EprContentReportable.defaultProps = {
    count: 0,
    handleAddClick: null,
    isAccordionButton: false,
    readOnly: false,
    reportFlag: false,
    templateExists: false,
    title: ""
};

export default EprContentReportable;
