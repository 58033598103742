import React from "react";
import PropTypes from "prop-types";

import ManageTemplateTable from "./ManageTemplateTable";
import LoadingSvg from "../loadingSvg/LoadingSvg.jsx";

import "../../styles/SchoolHome.scss";

/**
 * This is the At a Glance section on school admin home
 * @name AtAGlance
 * @returns {JSX.Element}
 */
const AtAGlance = ({ applicableGrades, combinedGrades, loader, statusClass }) => {
    return (
        <div className="at-a-glance-container">
            <span className="sub-section-title">At a Glance</span>
            <div className="sub-section-line" />
            {!loader && statusClass === "waiting" && (
                <div>Welcome, the template for this school year is not yet available.</div>
            )}
            {!loader && statusClass === "ready" && (
                <div>
                    The district template is ready to pull.
                    <div>Proceed to Template to create the EPR template for your school.</div>
                    <br />
                    <div>
                        Once you have created the template you will be able to define reporting periods and release to
                        teachers.
                    </div>
                </div>
            )}
            {!loader && statusClass === "in-progress" && combinedGrades && (
                <div className="glance-manage-table">
                    <ManageTemplateTable applicableGrades={applicableGrades} combinedGrades={combinedGrades} />
                </div>
            )}
            {loader && (
                <div>
                    <LoadingSvg />
                </div>
            )}
        </div>
    );
};
export default AtAGlance;

AtAGlance.propTypes = {
    applicableGrades: PropTypes.instanceOf(Array),
    combinedGrades: PropTypes.instanceOf(Array),
    loader: PropTypes.bool,
    statusClass: PropTypes.string
};
