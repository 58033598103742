import { PropTypes } from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import { ROSTER_BY_SECTION, TEACHER_SECTIONS } from "../const/TeacherInfoConst";

/**
 * Data Access for the Teacher Info API
 * @name TeacherInfoDao
 * @param props
 * @return {null|*}
 */
const TeacherInfoDao = (props) => {
    const { action, sectionId, token, username } = props;
    const bearer = `Bearer ${token}`;
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };

    switch (action) {
        case "rosterBySectionRead":
            options.method = "GET";
            options.url = `${ROSTER_BY_SECTION}/${sectionId}/index.json`;
            break;
        case "teacherSectionsRead":
            options.method = "GET";
            options.url = `${TEACHER_SECTIONS}/${username}/index.json`;
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

TeacherInfoDao.propTypes = {
    action: PropTypes.string.isRequired,
    sectionId: PropTypes.string,
    token: PropTypes.string.isRequired,
    username: PropTypes.string
};

TeacherInfoDao.defaultProps = {
    sectionId: null,
    username: null
};

export default TeacherInfoDao;
