import React, { useContext, useEffect, useState } from "react";

import { GlobalContext } from "./contextProvider/ContextProvider";
import { isUserDistrictAdmin, isUserSchoolAdmin } from "../utils/isUser.jsx";

import "../styles/Toolbar.scss";

const Toolbar = () => {
    const { state } = useContext(GlobalContext);
    const { userDetails } = state || {};
    const { location } = userDetails || {};
    const [isDistrict, setIsDistrict] = useState(false);
    const [isSchoolAdmin, setIsSchoolAdmin] = useState(false);

    /* Determine if user is district admin or school admin */
    useEffect(() => {
        if (isUserDistrictAdmin(userDetails)) {
            setIsDistrict(true);
        }
        if (isUserSchoolAdmin(userDetails)) {
            setIsSchoolAdmin(true);
        }
    }, [userDetails]);

    return (
        <div className="toolbar">
            EPR <div className="divider"></div>
            {isSchoolAdmin && !isDistrict && <span>{location}</span>}
            {isDistrict && <span>District Admin</span>}
        </div>
    );
};

export default Toolbar;
