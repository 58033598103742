// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-svg svg {
  position: fixed;
  margin: auto;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/loadingSvg/loadingSvg.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;AACJ","sourcesContent":[".loading-svg svg {\n    position: fixed;\n    margin: auto;\n    top: 30px;\n    bottom: 30px;\n    left: 30px;\n    right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
