import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
import Icon from "../icon/Icon";
import LoadingSvg from "../loadingSvg/LoadingSvg";
import MoveButton from "../formInputs/buttons/MoveButton";
import UserDao from "../../dao/UserDao";

import { getTodayWithTime } from "../../utils/DateFormatter";
import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/SchoolHome.scss";
import "../../styles/SchoolLevelExpectations.scss";

const SchoolLevelExpectations = ({ fullYearTimePeriodKey }) => {
    const { state } = useContext(GlobalContext);
    const { schoolYearDto, token, userDetails } = state;
    const locationKey = userDetails?.userAttributeDto?.userAttributeMap?.CURRENT_PREDOMINANT_SCHOOL?.locationKey;

    const [historicalSles, setHistoricalSles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState("false");
    const [schoolYears, setSchoolYears] = useState(null);
    const [selectedYear, setSelectedYear] = useState("");
    const [sles, setSles] = useState();
    const [touched, setTouched] = useState(false);

    const [autoSaveMessage, setAutoSaveMessage] = useState("");

    const handleSelectChange = (e) => {
        setSelectedYear(e.target.value);
        if (e.target.value !== "") {
            const options = {
                action: "getSchoolLevelExpectations",
                locationKey: locationKey,
                schoolYearKey: schoolYears.find((year) => year.name === e.target.value).key,
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload) {
                    const historicalSles = response.data.payload;
                    historicalSles.forEach((item, idx) => {
                        if (item.ordinal === null) {
                            item.ordinal = idx;
                        }
                    });
                    setHistoricalSles(historicalSles);
                }
                setLoader(false);
            });
        } else {
            setHistoricalSles([]);
        }
    };

    const handleCopySles = () => {
        const selectedYearObj = schoolYears.find((year) => year.name === selectedYear);
        const options = {
            action: "copySchoolLevelExpectations",
            locationKey: locationKey,
            data: {
                fromSchoolYearKey: selectedYearObj.key,
                toSchoolYearKey: schoolYearDto.key,
                lastUpdaterGuid: userDetails.uid
            },
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response && response.data && response.data.payload) {
                toast.success(`School Level Expectations successfully copied and saved.`, {
                    autoClose: 3000
                });
                setSles(response.data.payload);
            }
            setLoader(false);
        });
    };

    const handleAddSle = () => {
        const newItem = {
            key: null,
            locationKey: locationKey,
            schoolYearKey: schoolYearDto.key,
            timePeriod: fullYearTimePeriodKey,
            highLight: false,
            ordinal: sles.length,
            lastUpdateGuid: userDetails.uid,
            expectation: "",
            sleChildList: []
        };

        setSles([...sles, newItem]);
    };

    const handleAddChild = (index) => {
        const newChild = {
            key: null,
            ordinal: sles[index].sleChildList.length,
            expectation: ""
        };

        const updatedSles = [...sles];
        updatedSles[index].sleChildList.push(newChild);
        setSles(updatedSles);
    };

    const handleEdit = (index, value, childIndex = null) => {
        setTouched(true);
        const updatedSles = [...sles];
        if (childIndex === null) {
            updatedSles[index].expectation = value;
        } else {
            updatedSles[index].sleChildList[childIndex].expectation = value;
        }
        setSles(updatedSles);
    };

    const moveItem = (list, parentIndex, childIndex = null, direction) => {
        setTouched(true);
        const newList = [...list];

        if (childIndex === null) {
            const [item] = newList.splice(parentIndex, 1);
            newList.splice(parentIndex + direction, 0, item);
        } else {
            const childList = [...newList[parentIndex].sleChildList];
            const [child] = childList.splice(childIndex, 1);
            childList.splice(childIndex + direction, 0, child);
            newList[parentIndex].sleChildList = childList;
        }

        // Recalculate ordinals
        return newList.map((item, idx) => ({
            ...item,
            ordinal: idx,
            sleChildList: item.sleChildList.map((child, cIdx) => ({
                ...child,
                ordinal: cIdx
            }))
        }));
    };

    const handleSave = useCallback(
        (sleData, autoSave = false) => {
            const options = {
                action: "updateSchoolLevelExpectations",
                locationKey: locationKey,
                schoolYearKey: schoolYearDto.key,
                params: { lastUpdaterGuid: userDetails.uid },
                data: sleData,
                token
            };

            if (!autoSave) {
                setLoader(true);
            }

            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload) {
                    if (!autoSave) {
                        toast.success("School Level Expectations successfully saved.", { autoClose: 3000 });
                    } else {
                        setAutoSaveMessage(`Saved: ${getTodayWithTime()}`);
                        setTimeout(() => setAutoSaveMessage(""), 3000);
                    }
                    setSles(response.data.payload);
                }

                if (!autoSave) {
                    setLoader(false);
                }
                setTouched(false);
            });
        },
        [locationKey, schoolYearDto.key, userDetails.uid, token] // Dependencies
    );

    const handleDeleteSle = (index, sleKey) => {
        const updatedSles = [...sles];
        updatedSles.splice(index, 1);

        updatedSles.forEach((item, idx) => {
            item.ordinal = idx;
        });

        setSles(updatedSles);

        if (sleKey) {
            const options = {
                action: "deleteSchoolLevelExpectations",
                params: {
                    idList: sleKey
                },
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response && !response.errors) {
                    toast.success(`School Level Expectation successfully removed.`, {
                        autoClose: 3000
                    });
                    handleSave(updatedSles);
                }
                setLoader(false);
            });
        }
    };

    const handleDeleteChild = (index, childIndex, childKey) => {
        const updatedSles = [...sles];
        updatedSles[index].sleChildList.splice(childIndex, 1);

        updatedSles[index].sleChildList.forEach((child, idx) => {
            child.ordinal = idx;
        });

        setSles(updatedSles);
        if (childKey) {
            const options = {
                action: "deleteSchoolLevelExpectationChildren",
                params: {
                    idList: childKey
                },
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response) {
                    toast.success(`Sub-school Level Expectation successfully removed.`, {
                        autoClose: 3000
                    });
                    handleSave(updatedSles);
                    setLoader(false);
                }
            });
        }
    };

    const handleDeleteAll = () => {
        const options = {
            action: "deleteAllSchoolLevelExpectations",
            locationKey: locationKey,
            schoolYearKey: schoolYearDto.key,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response && !response.errors) {
                toast.success(`School Level Expectations successfully deleted.`, {
                    autoClose: 3000
                });
                setSles([]);
                setOpen("false");
            }
            setLoader(false);
        });
    };

    /**
     * Get SLEs if they exist
     */
    useEffect(() => {
        if (schoolYearDto?.key && locationKey && token) {
            const options = {
                action: "getSchoolLevelExpectations",
                locationKey: locationKey,
                schoolYearKey: schoolYearDto.key,
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload) {
                    setSles(response.data.payload);
                }
                setLoader(false);
            });
        }
    }, [locationKey, schoolYearDto, token]);

    /**
     * Get school years
     */
    useEffect(() => {
        if (!schoolYears && token) {
            const options = {
                action: "schoolYearsRead",
                token
            };
            setLoader(true);
            UserDao(options).then((response) => {
                if (response && response.data && response.data.payload) {
                    const prevFiveYears = response.data.payload
                        .filter((year) => year.key < schoolYearDto.key)
                        .slice(-5);
                    setSchoolYears(prevFiveYears);
                }
                setLoader(false);
            });
        }
    }, [schoolYearDto.key, schoolYears, token]);

    useEffect(() => {
        if (touched) {
            const autoSaveTimer = setTimeout(() => handleSave(sles, true), 2000);
            return () => clearTimeout(autoSaveTimer); // Cancel if user keeps typing
        }
    }, [handleSave, sles, touched]);

    return (
        <div className="template-wrapper">
            <div className="template-title-section">
                <h4 className="page-title" style={{ marginBottom: "0px" }}>
                    Setup School Level Expectations for the {schoolYearDto?.name} School Year
                </h4>
            </div>
            <section>
                {sles?.length === 0 && (
                    <div className="sles-helper-text">
                        You may select School Level Expectations from a previous school year to copy forward and use
                        this year. If you elect to copy, you will have the opportunity to edit the expectations, add new
                        expectations and re-order the expectations. Alternatively, you may click &quot;Begin&quot; to
                        create new SLEs.
                    </div>
                )}
                {sles?.length > 0 && (
                    <div className="sles-helper-text">
                        Please review and edit School Level Expectations. If you want to add a new SLE, please click
                        &quot;Add SLE&quot; button. You may re-order expectations and sub-expectations by clicking the
                        up or down arrow to the left. Once you are satisfied, please click the &quot;Save&quot; button.
                    </div>
                )}
                {sles && sles.length === 0 && (
                    <div className="copy-or-create-container">
                        <div className={historicalSles.length > 0 ? "card extra-width" : "card"}>
                            <div className="card-body">
                                <p className="card-title">Copy from:</p>
                                <div style={{ display: "flex" }}>
                                    <select
                                        className="form-select"
                                        id="sleYearSelect"
                                        label="Copy from previous year:"
                                        name="sle-year-select"
                                        value={selectedYear}
                                        onChange={(e) => handleSelectChange(e)}
                                    >
                                        <option value="">Select school year</option>
                                        {schoolYears?.map((year, index) => {
                                            const { key, name } = year;
                                            const uniqueKey = `area-${key}-${index}`;
                                            return (
                                                <option key={uniqueKey} value={name}>
                                                    {name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div style={{ marginRight: "-10px" }}>
                                        <ActionButton
                                            className="action-button-reg"
                                            label="Clear"
                                            onClick={() => {
                                                setSelectedYear("");
                                                setHistoricalSles([]);
                                            }}
                                        />
                                    </div>
                                </div>
                                {historicalSles && historicalSles.length > 0 && (
                                    <>
                                        <div className="preview">
                                            <h4>Previewing {selectedYear} SLEs:</h4>
                                            <hr />
                                            <ol>
                                                {historicalSles?.map((sle) => (
                                                    <li key={sle.key}>
                                                        {sle.expectation}
                                                        <ol>
                                                            {sle.sleChildList?.map((child) => (
                                                                <li key={child.key}>{child.expectation}</li>
                                                            ))}
                                                        </ol>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                        <div className="copy-btn-container">
                                            <ActionButton
                                                className="action-button-reg"
                                                label={`Copy ${selectedYear} SLEs`}
                                                onClick={handleCopySles}
                                            />
                                        </div>
                                    </>
                                )}
                                {selectedYear && historicalSles.length === 0 && (
                                    <div className="preview">No SLEs available for selected year.</div>
                                )}
                            </div>
                        </div>
                        <div className="separator">&ndash; OR &ndash;</div>
                        <div className={historicalSles.length > 0 ? "card less-width" : "card"}>
                            <div className="card-body">
                                <p className="card-title">Create New SLEs</p>
                                <ActionButton className="action-button-reg" label="Begin" onClick={handleAddSle} />
                            </div>
                        </div>
                    </div>
                )}
                {sles && sles.length > 0 && (
                    <div className="sles-container">
                        <div className={autoSaveMessage ? "auto-save-bar" : "auto-save-bar-hidden"}>
                            {autoSaveMessage}
                        </div>
                        <div className="sle-button-wrapper">
                            <div>
                                <ActionButton
                                    className="action-button-reg"
                                    disabled={loader}
                                    onClick={handleAddSle}
                                    label={"Add SLE"}
                                />
                                <ActionButton
                                    className="action-button-reg"
                                    disabled={loader}
                                    onClick={() => handleSave(sles)}
                                    label="Save"
                                />
                                <ActionButton
                                    className="action-button-delete"
                                    disabled={loader}
                                    onClick={() => setOpen("delete-all")}
                                    label="Delete All SLEs"
                                />
                            </div>
                        </div>
                        {!loader &&
                            sles?.map((item, index) => (
                                <div className="sle-and-children" key={item.ordinal}>
                                    <div className="sle-wrapper" key={item.ordinal}>
                                        <div className="ordinal-movement-wrapper">
                                            <MoveButton
                                                ariaLabel={`Move School Level Expectation ${index + 1} up`}
                                                direction="up"
                                                disabled={index === 0}
                                                onClick={() => setSles(moveItem(sles, index, null, -1))}
                                            />
                                            <label htmlFor={`sle-textarea-${index}`}>{item.ordinal + 1}</label>
                                            <MoveButton
                                                ariaLabel={`Move School Level Expectation ${index + 1} down`}
                                                direction="down"
                                                disabled={index === sles.length - 1}
                                                onClick={() => setSles(moveItem(sles, index, null, 1))}
                                            />
                                        </div>

                                        <textarea
                                            aria-labelledby={`sle-textarea-${index}`}
                                            className="sle-text-area"
                                            id={`sle-textarea-${index}`}
                                            maxLength={3000}
                                            type="text"
                                            value={item.expectation}
                                            onChange={(e) => handleEdit(index, e.target.value)}
                                        />
                                        <div className="icon-btn-wrapper">
                                            <button
                                                aria-label="Add sub-expectation"
                                                className="button-icon-right"
                                                disabled={loader}
                                                onClick={() => handleAddChild(index)}
                                            >
                                                <Icon fill="#FFF" iconName="PLUS_ICON" />
                                            </button>
                                            <button
                                                aria-label="Delete SLE"
                                                className="button-icon-right"
                                                disabled={loader}
                                                onClick={() => handleDeleteSle(index, item.key)}
                                            >
                                                <Icon fill="#FFF" iconName="TRASHCAN" />
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        {item.sleChildList.map((child, childIndex) => (
                                            <div className="sle-child-wrapper" key={child.ordinal}>
                                                <div className="ordinal-movement-wrapper">
                                                    <MoveButton
                                                        ariaLabel={`Move sub-expectation ${childIndex + 1} up`}
                                                        direction="up"
                                                        disabled={childIndex === 0}
                                                        onClick={() => setSles(moveItem(sles, index, childIndex, -1))}
                                                    />
                                                    <label htmlFor={`sub-sle-textarea-${childIndex}`}>
                                                        {item.ordinal + 1}.{child.ordinal + 1}
                                                    </label>
                                                    <MoveButton
                                                        ariaLabel={`Move sub-expectation ${childIndex + 1} down`}
                                                        direction="down"
                                                        disabled={childIndex === sles[index].sleChildList.length - 1}
                                                        onClick={() => setSles(moveItem(sles, index, childIndex, 1))}
                                                    />
                                                </div>
                                                <textarea
                                                    aria-labelledby={`sub-sle-textarea-${childIndex}`}
                                                    className="sle-child-text-area"
                                                    id={`sub-sle-textarea-${childIndex}`}
                                                    maxLength={3000}
                                                    type="text"
                                                    value={child.expectation}
                                                    onChange={(e) => handleEdit(index, e.target.value, childIndex)}
                                                />
                                                <div className="icon-btn-wrapper">
                                                    <button
                                                        aria-label="Delete sub-expectation"
                                                        className="button-icon"
                                                        disabled={loader}
                                                        onClick={() => handleDeleteChild(index, childIndex, child.key)}
                                                    >
                                                        <Icon fill="#FFF" iconName="TRASHCAN" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </section>
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-delete"
                            label="Confirm Delete"
                            onClick={() => {
                                handleDeleteAll();
                            }}
                        />
                    </div>
                }
                ariaLabel="Delete Confirmation Dialog"
                hasCloseX={false}
                id="delete-all"
                open={open}
                title="Delete SLEs Confirmation"
            >
                <div>
                    Please confirm you would like to delete all School Level Expectations for the {schoolYearDto.name}{" "}
                    year.
                </div>
            </DcsdDialog>
            {loader && <LoadingSvg />}
        </div>
    );
};

export default SchoolLevelExpectations;

SchoolLevelExpectations.propTypes = {
    fullYearTimePeriodKey: PropTypes.string
};
