const getStandardCount = (standard) => (standard.reportFlag === true ? 1 : 0);
const getGleCount = (standard) => {
    return standard.gles.reduce((acc, val) => {
        if (val.reportFlag === true) {
            return acc + 1;
        }
        return acc;
    }, 0);
};

const getEvidenceOutcomeCount = (standard) => {
    return standard.gles.reduce((totalCount, gle) => {
        let eoCount = gle.evidenceOutcomes.reduce((acc, val) => {
            if (val.reportFlag === true) {
                return acc + 1;
            }
            return acc;
        }, 0);
        totalCount = totalCount + eoCount;
        return totalCount;
    }, 0);
};

const getEvidenceOutcomeChildListCount = (standard) => {
    return standard.gles.reduce((totalCount, gle) => {
        gle.evidenceOutcomes.forEach((eo) => {
            let childCount = eo.evidenceOutcomeChildList.reduce((acc, val) => {
                if (val.reportFlag === true) {
                    return acc + 1;
                }
                return acc;
            }, 0);
            totalCount = totalCount + childCount;
            return totalCount;
        });
        return totalCount;
    }, 0);
};

export const getTotalCountByStandard = (standard) => {
    return (
        getStandardCount(standard) +
        getGleCount(standard) +
        getEvidenceOutcomeCount(standard) +
        getEvidenceOutcomeChildListCount(standard)
    );
};
