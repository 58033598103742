import React, { useContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";

import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/StudentRosterTable.scss";

/**
 * StudentRosterTable component displays a sortable and filterable table of students
 * associated with a selected grading profile. It allows teachers to remove students or
 * clear the entire roster.
 *
 * @component
 * @param {Function} dispatch
 * @param {Array} gradingProfileStudents
 * @param {Function} handleCheckboxChange
 * @param {Function} handleSelectAll
 * @param {Bool} isGroup
 * @param {Object} selectedEpr
 * @param {Array} selectedStudents
 * @param {Function} setDeltaStudents
 * @param {Function} setShowSectionSelect
 * @param {Function} setStudentsNotAdded
 * @param {Object} state
 * @returns {JSX.Element}
 */
const StudentRosterTable = ({
    dispatch,
    gradingProfileStudents,
    handleCheckboxChange,
    handleSelectAll,
    isGroup,
    selectedEpr,
    selectedStudents,
    setDeltaStudents,
    setShowSectionSelect,
    setStudentsNotAdded,
    state
}) => {
    const [data, setData] = useState(gradingProfileStudents);
    const [filterText, setFilterText] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "lastName", direction: "ascending" });
    const [open, setOpen] = useState("false");

    const { state: globalState } = useContext(GlobalContext);
    const { token } = globalState || {};
    const navigate = useNavigate();

    const filteredData = useMemo(() => {
        if (!filterText) return data;
        return data.filter((item) =>
            Object.keys(item).some((key) => item[key]?.toString().toLowerCase().includes(filterText.toLowerCase()))
        );
    }, [data, filterText]);

    /**
     * Sorts students based on the selected column and direction.
     */
    const sortedData = useMemo(() => {
        if (!sortConfig.key) return filteredData;
        return [...filteredData].sort((a, b) => {
            let aValue = a[sortConfig.key]?.toString().toLowerCase();
            let bValue = b[sortConfig.key]?.toString().toLowerCase();
            return sortConfig.direction === "ascending" ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
        });
    }, [filteredData, sortConfig]);

    /**
     * Handles sorting logic when a column header is clicked.
     * @param {string} key
     */
    const requestSort = (key) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === "ascending" ? "descending" : "ascending"
        }));
    };

    const renderSortIcons = (columnKey) => {
        if (sortConfig.key === columnKey) {
            if (sortConfig.direction === "ascending") {
                return (
                    <span className="icon-container">
                        <i className="bi bi-caret-up-fill" style={{ height: "16px" }} />
                        <i className="bi bi-caret-down" style={{ height: "16px", marginBottom: "5px" }} />
                    </span>
                );
            } else {
                return (
                    <span className="icon-container">
                        <i className="bi bi-caret-up" style={{ height: "16px" }} />
                        <i className="bi bi-caret-down-fill" style={{ height: "16px", marginBottom: "5px" }} />
                    </span>
                );
            }
        }
        return (
            <span className="icon-container">
                <i className="bi bi-caret-up" style={{ height: "16px" }} />
                <i className="bi bi-caret-down" style={{ height: "16px", marginBottom: "5px" }} />
            </span>
        );
    };

    /**
     * Removes a student from the grading profile.
     * @param {string} student
     */
    const handleDelete = (student) => {
        const options = {
            action: "removeStudentsFromProfile",
            params: { ids: student.key },
            gradingProfile: selectedEpr.key,
            token
        };
        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data?.payload) {
                const updatedProfile = response.data.payload;

                dispatch({ type: "SET_SELECTED_EPR", payload: updatedProfile });
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedProfile.key ? updatedProfile : profile
                    )
                });
                toast.success(`Student ${student.firstName} ${student.lastName} successfully removed.`, {
                    autoClose: 3000
                });
            }
        });
    };

    const handleClear = () => {
        const options = {
            action: "clearStudentsFromProfile",
            gradingProfile: selectedEpr.key,
            token
        };
        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data?.payload) {
                setDeltaStudents([]);
                setStudentsNotAdded([]);
                setShowSectionSelect(true);
                const updatedProfile = response.data.payload;

                dispatch({ type: "SET_SELECTED_EPR", payload: updatedProfile });
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedProfile.key ? updatedProfile : profile
                    )
                });
                toast.success("EPR roster successfully cleared.", { autoClose: 3000 });
            }
        });
    };

    /**
     * Updates the student data when the grading profile students change.
     */
    useEffect(() => {
        setData(gradingProfileStudents);
    }, [gradingProfileStudents]);

    return (
        <div className={`student-roster-table ${isGroup && "group-table"}`}>
            {!isGroup && (
                <div className="filter-wrapper">
                    <h5>Student Roster ({gradingProfileStudents.length} Students)</h5>
                    <label className="roster-table-filter-label">
                        Filter Students:
                        <input
                            className="roster-table-filter"
                            type="text"
                            placeholder="Filter students"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </label>
                </div>
            )}
            {isGroup && (
                <div className="filter-wrapper">
                    <div className="select-students-for-group">
                        Select Students for Group ({selectedStudents.length})
                    </div>
                    <label className="roster-table-filter-label">
                        Filter Students:
                        <input
                            className="roster-table-filter"
                            type="text"
                            placeholder="Filter students"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </label>
                </div>
            )}
            <div style={{ maxHeight: "350px", overflow: "scroll" }}>
                <table className="table table-striped">
                    <thead>
                        <tr style={{ position: "sticky", top: "0" }}>
                            <th onClick={() => requestSort("lastName")}>Last Name {renderSortIcons("lastName")}</th>
                            <th onClick={() => requestSort("firstName")}>First Name {renderSortIcons("firstName")}</th>
                            <th onClick={() => requestSort("studentNumber")}>
                                Student Number {renderSortIcons("studentNumber")}
                            </th>
                            <th className={isGroup ? "checkbox-header" : ""} style={{ paddingBottom: "15px" }}>
                                {!isGroup ? (
                                    "Remove"
                                ) : (
                                    <div>
                                        {" "}
                                        <input
                                            type="checkbox"
                                            checked={
                                                selectedEpr.gradingProfileStudents.length === selectedStudents.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                        <label style={{ display: "none" }}>Select All</label>
                                    </div>
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((student) => (
                            <tr key={student.key}>
                                <td>{student.lastName}</td>
                                <td>{student.firstName}</td>
                                <td>{student.studentNumber}</td>
                                <td>
                                    {!isGroup ? (
                                        <button className="transparent-button" onClick={() => handleDelete(student)}>
                                            <i className="bi bi-trash3" />
                                        </button>
                                    ) : (
                                        <input
                                            checked={selectedStudents.includes(student.studentNumber)}
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(student.studentNumber)}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                        {sortedData.length === 0 && (
                            <tr>
                                <td colSpan={4}>No students found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {!isGroup && (
                <div className="back-btn-wrapper">
                    <ActionButton
                        className="ghost-button-delete"
                        label="Clear Roster"
                        onClick={() => setOpen("clear-roster-confirmation")}
                    />
                    <ActionButton label="Back to EPR Summary" onClick={() => navigate(`/home/summary`)} />
                </div>
            )}
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label="Confirm"
                            onClick={() => {
                                handleClear();
                            }}
                        />
                    </div>
                }
                ariaLabel="Confirmation Clear Roster Dialog"
                hasCloseX={false}
                id="clear-roster-confirmation"
                open={open}
                title="Clear Current EPR Roster"
            >
                <div>
                    Please confirm that you wish to clear your current EPR roster.
                    <span style={{ fontStyle: "italic", textDecoration: "underline", marginLeft: "4px" }}>
                        Please note, you will lose any completed work with this action.
                    </span>
                </div>
            </DcsdDialog>
        </div>
    );
};

StudentRosterTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    gradingProfileStudents: PropTypes.array.isRequired,
    handleCheckboxChange: PropTypes.func,
    handleSelectAll: PropTypes.func,
    isGroup: PropTypes.bool,
    selectedEpr: PropTypes.object.isRequired,
    selectedStudents: PropTypes.array,
    setDeltaStudents: PropTypes.func,
    setSelectAll: PropTypes.func,
    setShowSectionSelect: PropTypes.func,
    setStudentsNotAdded: PropTypes.func,
    state: PropTypes.object.isRequired
};

StudentRosterTable.defaultProps = {
    isGroup: false,
    handleCheckboxChange: null,
    handleSelectAll: null,
    selectedStudents: [],
    setDeltaStudents: null,
    setSelectAll: null,
    setShowSectionSelect: null,
    setStudentsNotAdded: null
};

export default StudentRosterTable;
