const GradingProfilesReducer = (state, action) => {
    switch (action.type) {
        case "SET_PROFILES":
            return { ...state, gradingProfiles: action.payload };
        case "SET_SELECTED_EPR":
            return { ...state, selectedEpr: action.payload };
        case "SET_FETCH_FLAG":
            return { ...state, fetchProfileFlag: action.payload };
        case "SET_LOADER":
            return { ...state, loader: action.payload };
        case "TOGGLE_NAV":
            return { ...state, navCollapsed: !state.navCollapsed };
        case "SET_SELECTED_LINK":
            return { ...state, selectedLink: action.payload };
        case "SET_LABEL_OBJ":
            return { ...state, labelObj: action.payload };
        default:
            return state;
    }
};

export default GradingProfilesReducer;
