import React, { useCallback, useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
import LoadingSvg from "../loadingSvg/LoadingSvg";

import { GlobalContext } from "../contextProvider/ContextProvider";
import { getTodayWithTime } from "../../utils/DateFormatter";

import "../../styles/GradingAccordion.scss";

/**
 * GradingAccordionItem component represents an expandable panel for a grading profile.
 * It allows users to manage comments, subjects, and the roster for a specific grade.
 *
 * @component
 * @param {Function} dispatch
 * @param {Object} grade
 * @param {Object} gradingProfile
 * @param {string} ownerDisplayName
 * @param {Function} setSavedMessage
 * @param {Function} setShowSaved
 * @param {Object} state
 * @returns {JSX.Element}
 */
const GradingAccordionItem = ({
    dispatch,
    grade,
    gradingProfile,
    ownerDisplayName,
    setSavedMessage,
    setShowSaved,
    state
}) => {
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const saveTimerRef = useRef(null);

    const { state: globalState } = useContext(GlobalContext);
    const { token } = globalState || {};

    const [isExpanded, setIsExpanded] = useState(false);
    const [open, setOpen] = useState("false");
    const [profileData, setProfileData] = useState(gradingProfile);
    const [showProfileComment, setShowProfileComment] = useState(true);

    const initialSubjectVisibility = grade.subjectToStandards.reduce((acc, subject) => {
        acc[subject.templateId] = false;
        return acc;
    }, {});
    const [subjectCommentsVisible, setSubjectCommentsVisible] = useState(initialSubjectVisibility);

    /**
     * Handles saving the grading profile comments to the database.
     */
    const handleSave = useCallback(() => {
        // eslint-disable-next-line no-unused-vars
        const { gradingProfileStudents, ...dataToSave } = profileData;
        const options = {
            action: "updateGradingProfileComments",
            gradingProfile: profileData.key,
            data: dataToSave,
            token
        };

        ElementaryProgressReportDao(options).then((response) => {
            if (response?.data?.payload) {
                const updatedProfile = response.data.payload;
                setShowSaved(true);
                setSavedMessage(`Saved: ${getTodayWithTime()}`);
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.map((profile) =>
                        profile.key === updatedProfile.key ? updatedProfile : profile
                    )
                });
            }
        });
    }, [profileData, token, dispatch, setShowSaved, setSavedMessage, state.gradingProfiles]);

    /**
     * Navigates to the Manage Roster page for the selected grading profile.
     */
    const handleManageRosterClick = () => {
        dispatch({ type: "SET_SELECTED_EPR", payload: gradingProfile });
        navigate(`/home/summary/roster/${gradingProfile.key}`);
    };

    /**
     * Navigates to the homeroom group page for the selected grading profile.
     */
    const handleHomeroomGroupClick = () => {
        dispatch({ type: "SET_SELECTED_EPR", payload: gradingProfile });
        navigate(`/home/summary/homeroom-groups/${gradingProfile.key}`);
    };

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    /**
     * Handles updating the profile comment text.
     * @param {Object} e
     */
    const handleProfileCommentChange = (e) => {
        setProfileData({
            ...profileData,
            comment: e.target.value
        });
    };

    /**
     * Handles updating subject-specific comments.
     * @param {number} templateId
     * @param {Object} e
     */
    const handleSubjectCommentChange = (templateId, e) => {
        const updatedCriteriaList = profileData.gradingCriteriaList.map((item) => {
            if (item.parentTemplate === templateId) {
                return { ...item, comment: e.target.value };
            }
            return item;
        });
        setProfileData({
            ...profileData,
            gradingCriteriaList: updatedCriteriaList
        });
    };

    /**
     * Toggles the visibility of subject-specific comments.
     * @param {number} templateId
     */
    const toggleSubjectCommentVisibility = (templateId) => {
        setSubjectCommentsVisible((prev) => ({
            ...prev,
            [templateId]: !prev[templateId]
        }));
    };

    /**
     * Handles deleting the grading profile and updates global state accordingly.
     */
    const handleDelete = () => {
        const options = {
            action: "deleteGradingProfile",
            gradingProfile: gradingProfile.key,
            token
        };
        dispatch({ type: "SET_LOADER", payload: true });

        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                dispatch({
                    type: "SET_PROFILES",
                    payload: state.gradingProfiles.filter((profile) => profile.key !== gradingProfile.key)
                });
                dispatch({
                    type: "SET_SELECTED_EPR",
                    payload: ""
                });
                toast.success("EPR successfully deleted.", { autoClose: 3000 });
                setOpen("false");
            } else {
                toast.error("Failed to delete EPR", { autoClose: false });
            }
            dispatch({ type: "SET_LOADER", payload: false });
        });
    };

    /**
     * Handles saving profile data (comments) with a delay to reduce API calls.
     */
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (saveTimerRef.current) {
            clearTimeout(saveTimerRef.current);
        }
        saveTimerRef.current = setTimeout(() => {
            handleSave();
        }, 3000);
        return () => clearTimeout(saveTimerRef.current);
    }, [handleSave, profileData]);

    return (
        <div className="grading-accordion">
            <div className={`accordion-header ${isExpanded ? "expanded" : "collapsed"}`} onClick={toggleAccordion}>
                <div>
                    <span className="owner-name">{ownerDisplayName}</span> -{" "}
                    <span className="teacher-epr-grade-name">{profileData.gradeName}</span> (
                    {profileData.gradingProfileStudents.length} Students)
                </div>
                <div className="accordion-icon">
                    <i className={`${isExpanded ? "bi bi-chevron-up" : "bi bi-chevron-down"} h4`} />
                </div>
            </div>
            {isExpanded && (
                <div className="accordion-body">
                    <div className="accordion-btn-nav-wrapper">
                        <ActionButton
                            className="action-button-reg"
                            label="Manage Roster"
                            onClick={() => handleManageRosterClick()}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label={
                                profileData.homeroomGroups.length > 0
                                    ? "Manage Homeroom Group(s)"
                                    : "Create Homeroom Group(s)"
                            }
                            onClick={() => handleHomeroomGroupClick()}
                        />
                        <ActionButton
                            className="action-button-delete"
                            label="Delete EPR"
                            onClick={() => setOpen("confirm-delete-homeroom-epr")}
                        />
                    </div>
                    <div className="profile-comment-section">
                        <div className={`profile-comment-input-wrapper ${showProfileComment ? "show" : "hide"}`}>
                            <label htmlFor="profile-comment">
                                This is an overall comment that will appear for every student on your homeroom EPR:
                            </label>
                            <textarea
                                id="profile-comment"
                                value={profileData.comment || ""}
                                onChange={handleProfileCommentChange}
                                placeholder="Enter overall comment for all students"
                                className="profile-comment-textarea"
                            />
                        </div>

                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <button
                                onClick={() => setShowProfileComment(!showProfileComment)}
                                className="toggle-comment-btn"
                            >
                                {showProfileComment ? "Hide " : "View "} Comments for All Students
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div className="epr-subjects">
                        {grade.subjectToStandards.map((subject) => {
                            const criteria = profileData.gradingCriteriaList.find(
                                (item) => item.parentTemplate === subject.templateId
                            );
                            return (
                                <div key={subject.templateId} className="subject-card">
                                    <div className="subject-header">
                                        <span className="teacher-epr-subject-name">{subject.subjectName}</span>
                                        <div className="subject-header-right">
                                            <ActionButton
                                                label="Input Ratings"
                                                className="action-button-reg"
                                                onClick={() => {}}
                                            />
                                            <ActionButton
                                                label="View"
                                                className="action-button-reg"
                                                onClick={() => {}}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="toggle-subject-comment-btn"
                                        onClick={() => toggleSubjectCommentVisibility(subject.templateId)}
                                    >
                                        {subjectCommentsVisible[subject.templateId] ? "Hide " : "View "}
                                        Comment
                                    </button>
                                    {subjectCommentsVisible[subject.templateId] && (
                                        <div
                                            className={`subject-comment-wrapper ${subjectCommentsVisible[subject.templateId] ? "show" : "hide"}`}
                                        >
                                            <label
                                                htmlFor={`subject-comment-${subject.templateId}`}
                                                className="visually-hidden"
                                            >
                                                {`Add comment for ${subject.subjectName}`}
                                            </label>
                                            <textarea
                                                id={`subject-comment-${subject.templateId}`}
                                                value={criteria ? criteria.comment || "" : ""}
                                                onChange={(e) => handleSubjectCommentChange(subject.templateId, e)}
                                                placeholder={`Add comment for ${subject.subjectName}`}
                                                className="subject-comment-textarea"
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label="Confirm"
                            onClick={() => {
                                handleDelete();
                            }}
                        />
                    </div>
                }
                ariaLabel="Confirmation Delete Epr Dialog"
                hasCloseX={false}
                id="confirm-delete-homeroom-epr"
                open={open}
                title="Delete EPR Confirmation"
            >
                <div>
                    Please confirm you would like to delete your homeroom EPR.
                    <span style={{ fontStyle: "italic", marginLeft: "4px", textDecoration: "underline" }}>
                        Please note, this action cannot be undone and will result in the loss of all previously entered
                        data, including student ratings and student comments.
                    </span>
                </div>
            </DcsdDialog>
            {state.loader && <LoadingSvg />}
        </div>
    );
};

GradingAccordionItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    grade: PropTypes.shape({
        subjectToStandards: PropTypes.arrayOf(
            PropTypes.shape({
                templateId: PropTypes.number.isRequired,
                subjectName: PropTypes.string.isRequired
            })
        ).isRequired
    }).isRequired,
    gradingProfile: PropTypes.shape({
        key: PropTypes.string.isRequired,
        gradeName: PropTypes.string.isRequired,
        gradingProfileStudents: PropTypes.array.isRequired,
        gradingCriteriaList: PropTypes.array.isRequired,
        comment: PropTypes.string
    }).isRequired,
    ownerDisplayName: PropTypes.string.isRequired,
    setSavedMessage: PropTypes.func.isRequired,
    setShowSaved: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired
};

export default GradingAccordionItem;
