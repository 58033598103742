import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import {
    ALL_CONTENT_AREAS,
    ALL_GRADES,
    EPR_COMPLETE_GRADE_SUBJECT,
    EPR_CREATE_DISTRICT_TEMPLATE,
    EPR_DELETE_TEMPLATE,
    EPR_GET_TIME_PERIOD,
    EPR_LOCATIONS_WITH_TEMPLATES,
    EPR_RELEASE_TEMPLATE,
    EPR_REPORTABLE_CONTENT,
    EPR_REPORTABLE_BY_CONTENT_AREA,
    EPR_REPORTABLE_CONTENT_UPDATE,
    EPR_TEMPLATE
} from "../const/ElementaryProgressReportConst";

/**
 * Data Access for the Elementary Progress Report Service
 * @name ElementaryProgressReportDao
 * @param props
 * @return {null|*}
 */
const ElementaryProgressReportDao = (props) => {
    const {
        action,
        contentAreaKey,
        data,
        guid,
        gradeLevels,
        isCompleted,
        isReleased,
        isReleasedToTeachers,
        locationKey,
        params,
        profileGradeId,
        profileId,
        profileSubjectIds,
        schoolYearKey,
        timePeriodId,
        token
    } = props;
    const bearer = `Bearer ${token}`;
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };
    switch (action) {
        case "allContentAreasRead":
            options.method = "GET";
            options.url = `${ALL_CONTENT_AREAS}`;
            break;
        case "allGradesRead":
            options.method = "GET";
            options.url = `${ALL_GRADES}`;
            break;
        case "completeSubjectGradeTemplate":
            options.method = "PUT";
            options.url = `${EPR_COMPLETE_GRADE_SUBJECT}/${profileGradeId}/detail.json?completed=${isCompleted}&lastUpdaterGuid=${guid}&profileSubjectIds=${profileSubjectIds}`;
            break;
        case "completeGradeTemplate":
            options.method = "PUT";
            options.url = `${EPR_COMPLETE_GRADE_SUBJECT}/${profileGradeId}/detail.json?completed=${isCompleted}&lastUpdaterGuid=${guid}`;
            break;
        case "createDistrictTemplate":
            options.method = "POST";
            options.params = params;
            options.url = `${EPR_CREATE_DISTRICT_TEMPLATE}/${schoolYearKey}/detail.json?lastUpdaterGuid=${guid}`;
            break;
        case "deleteTemplate":
            options.method = "DELETE";
            options.params = params;
            options.url = `${EPR_DELETE_TEMPLATE}/${schoolYearKey}/${locationKey}/detail.json?lastUpdaterGuid=${guid}`;
            break;
        case "eprReportableByContentAreaAndGrades":
            options.method = "GET";
            options.params = params;
            options.url = `${EPR_REPORTABLE_BY_CONTENT_AREA}/${contentAreaKey}/index.json?gradeLevels=${gradeLevels}`;
            break;
        case "getAllContent":
            options.method = "GET";
            options.url = `${EPR_REPORTABLE_CONTENT}/index.json`;
            break;
        case "getLocationsWithTemplates":
            options.method = "GET";
            options.url = `${EPR_LOCATIONS_WITH_TEMPLATES}/${schoolYearKey}/index.json`;
            break;
        case "getTemplateByLocationAndTimePeriod":
            options.method = "GET";
            options.url = `${EPR_TEMPLATE}/${schoolYearKey}/${locationKey}/${timePeriodId}/index.json`;
            break;
        case "getTimePeriodId":
            options.method = "GET";
            options.url = `${EPR_GET_TIME_PERIOD}/${schoolYearKey}/index.json`;
            break;
        case "releaseTemplate":
            options.method = "PUT";
            options.url = `${EPR_RELEASE_TEMPLATE}/${profileId}/detail.json?templatesReleased=${isReleased}&lastUpdaterGuid=${guid}&releasedToTeachers=${isReleasedToTeachers}`;
            break;
        case "updateEprReportableContent":
            options.method = "PUT";
            options.params = params;
            options.data = data;
            options.url = `${EPR_REPORTABLE_CONTENT_UPDATE}/${contentAreaKey}/index.json?gradeLevels=${gradeLevels}`;
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

ElementaryProgressReportDao.propTypes = {
    action: PropTypes.string.isRequired,
    contentAreaKey: PropTypes.number,
    data: PropTypes.objectOf([PropTypes.object]),
    gradeLevels: PropTypes.string,
    guid: PropTypes.number,
    isCompleted: PropTypes.bool,
    isReleased: PropTypes.bool,
    isReleasedToTeachers: PropTypes.bool,
    locationKey: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    params: PropTypes.objectOf([PropTypes.object]),
    profileGradeId: PropTypes.number,
    profileId: PropTypes.number,
    profileSubjectIds: PropTypes.string,
    schoolYearKey: PropTypes.number,
    timePeriodId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    token: PropTypes.string.isRequired
};

ElementaryProgressReportDao.defaultProps = {
    contentAreaKey: null,
    params: null,
    schoolYearKey: null
};

export default ElementaryProgressReportDao;
