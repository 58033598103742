import React, { useContext } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao";
import Icon from "../icon/Icon";

import { gradeTranslation } from "../../utils/SchoolAdmin/translations";
import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/ManageTemplateTable.scss";

const ManageTemplateTable = ({ applicableGrades, canRelease, combinedGrades, setLoader, setTemplateTouchedFlag }) => {
    const { dispatch, state } = useContext(GlobalContext);
    const { token, userDetails } = state;

    const getGradeDisplay = (grade) => {
        if (grade.grade !== "K") {
            return `${gradeTranslation[grade.grade]} grade`;
        } else {
            return "kindergarten";
        }
    };
    const handleRelease = (grade) => {
        const { firstSem, secondSem } = grade.semesterProfiles;

        const firstSemOptions = {
            action: "releaseTemplate",
            params: {
                lastUpdaterGuid: userDetails.uid,
                releasedToTeachers: false,
                templatesReleased: !grade.released,
                profileGradeId: firstSem.profileGradeId
            },
            profileId: grade.firstSemProfileId,
            token
        };
        const secondSemOptions = {
            action: "releaseTemplate",
            params: {
                lastUpdaterGuid: userDetails.uid,
                releasedToTeachers: false,
                templatesReleased: !grade.released,
                profileGradeId: secondSem.profileGradeId
            },
            profileId: grade.secondSemProfileId,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(firstSemOptions).then((response) => {
            if (response && !response.errors) {
                ElementaryProgressReportDao(secondSemOptions).then((response) => {
                    if (response && !response.errors) {
                        toast.success(
                            `The ${getGradeDisplay(grade)} template has been successfully ${grade.released === true ? "unreleased" : "released"} for teacher use.`,
                            {
                                autoClose: 3000
                            }
                        );
                        dispatch({
                            type: "SchoolLevelTemplateDto",
                            schoolLevelTemplateDto: null
                        });
                        setTemplateTouchedFlag(true);
                        setLoader(false);
                    } else {
                        toast.error(
                            `The ${getGradeDisplay(grade)} template could not be ${grade.released === true ? "unreleased" : "released"}. Please try again later.`,
                            {
                                autoClose: false
                            }
                        );
                        setLoader(false);
                    }
                });
            } else {
                toast.error(
                    `The ${getGradeDisplay(grade)} template could not be ${grade.released === true ? "unreleased" : "released"}.  Please try again later.`,
                    {
                        autoClose: false
                    }
                );
                setLoader(false);
            }
        });
    };

    return (
        <>
            <div className="table-header">
                <div className="header-item-container first" id="text">
                    Status
                </div>
                {combinedGrades.map((grade) => {
                    return (
                        <div key={grade.grade} className="header-item-container" id="text">
                            {grade.grade}
                        </div>
                    );
                })}
            </div>
            <div className="status-container">
                <div className="item-container first-col">
                    <span>Completed</span>
                </div>
                {combinedGrades.map((grade) => {
                    const isKinderApplicable = applicableGrades?.find((appGrade) => appGrade.tag === "KINDERGARTEN");
                    const isApplicableGrade =
                        applicableGrades?.find((appGrade) => appGrade.tag === grade.grade) ||
                        (grade.grade === "K" && isKinderApplicable);
                    return (
                        <div key={grade.grade} className="item-container">
                            {grade.complete === true && isApplicableGrade && <Icon fill="green" iconName="COMPLETE" />}
                            {grade.complete === false && isApplicableGrade && <Icon iconName="NOT_STARTED" />}
                            {!isApplicableGrade && <span style={{ color: "black" }}>&mdash;</span>}
                        </div>
                    );
                })}
            </div>
            <div className="status-container">
                <div className="item-container first-col">
                    {canRelease ? <span>Release to Teachers</span> : <span>Released</span>}
                </div>
                {combinedGrades.map((grade) => {
                    const isKinderApplicable = applicableGrades?.find((appGrade) => appGrade.tag === "KINDERGARTEN");
                    const isApplicableGrade =
                        applicableGrades?.find((appGrade) => appGrade.tag === grade.grade) ||
                        (grade.grade === "K" && isKinderApplicable);
                    return (
                        <div key={grade.grade} className="item-container">
                            {grade.released === true && isApplicableGrade && !canRelease && (
                                <Icon fill="green" iconName="COMPLETE" />
                            )}
                            {grade.released === false && isApplicableGrade && (!grade.complete || !canRelease) && (
                                <Icon iconName="NOT_STARTED" />
                            )}
                            {isApplicableGrade && grade.complete && canRelease && (
                                <div className="release-complete-check-wrapper">
                                    <label className={`release-check-btn`}>
                                        <input
                                            type="checkbox"
                                            checked={grade.released}
                                            onChange={() => handleRelease(grade)}
                                        />
                                        <span>{grade.released ? "Released" : "Release"}</span>
                                    </label>
                                </div>
                            )}
                            {!isApplicableGrade && <span style={{ color: "black" }}>&mdash;</span>}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

ManageTemplateTable.propTypes = {
    applicableGrades: PropTypes.instanceOf(Array),
    canRelease: PropTypes.bool,
    combinedGrades: PropTypes.instanceOf(Array),
    setLoader: PropTypes.func,
    setTemplateTouchedFlag: PropTypes.func
};

ManageTemplateTable.defaultProps = {
    canRelease: false
};

export default ManageTemplateTable;
