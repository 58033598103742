// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: "Montserrat", sans-serif !important;
}

body {
  -webkit-font-smoothing: antialiased;
}

hr {
  height: 1px;
  background: #444343;
  border: none;
}

.gutter-80 {
  margin: 0 auto;
  max-width: 1800px;
  padding: 24px 8px;
  width: 80%;
}

.gutter-90 {
  margin: 0 auto;
  max-width: 1800px;
  padding: 24px 8px;
  width: 90%;
}

.gutter-95 {
  margin: 0 auto;
  max-width: 1800px;
  padding: 24px 8px;
  width: 95%;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/global/Global.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AARA;EACI,gDAAA;AAWJ;;AAPA;EACI,mCAAA;AAUJ;;AAPA;EACI,WAAA;EACA,mBDaK;ECZL,YAAA;AAUJ;;AAPA;EACI,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,UAAA;AAUJ;;AAPA;EACI,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,UAAA;AAUJ;;AAPA;EACI,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,UAAA;AAUJ","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// EPR colors\n$dashboard-blue: #007bff;\n$dk-blue: #19608f;\n$lt-blue: #7DB7CC;\n$pale-blue: #c0def0;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$DCSD-logo-green: #84ae30;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n","@import \"../../styles/global/Colors\";\n@import \"../../styles/global/globalMixins\";\n* {\n    font-family: \"Montserrat\", sans-serif !important;\n}\n\n// This is used for \"bolding\" text in chrome\nbody {\n    -webkit-font-smoothing: antialiased;\n}\n\nhr {\n    height: 1px;\n    background: $grey4a;\n    border: none;\n}\n\n.gutter-80 {\n    margin: 0 auto;\n    max-width: 1800px;\n    padding: 24px 8px;\n    width: 80%;\n}\n\n.gutter-90 {\n    margin: 0 auto;\n    max-width: 1800px;\n    padding: 24px 8px;\n    width: 90%;\n}\n\n.gutter-95 {\n    margin: 0 auto;\n    max-width: 1800px;\n    padding: 24px 8px;\n    width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
