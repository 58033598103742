import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import { GlobalContext } from "../components/contextProvider/ContextProvider";
import ManageDistrictTemplate from "../components/display/ManageDistrictTemplate.jsx";
import ActionButton from "../components/formInputs/buttons/ActionButton.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";
import DcsdDialog from "../components/modals/DcsdDialog.jsx";
import RbA from "../components/rba/RbA.jsx";
import ElementaryProgressReportDao from "../dao/ElementaryProgressReportDao.jsx";
import { getTotalBySubjectPerGrade } from "../utils/ManageEpr/getContentCounts.jsx";

import "../styles/DistrictTemplate.scss";

/**
 * This is the district template page for create, view/mark complete, release, and delete
 * @name DistrictTemplate
 * @returns {JSX.Element}
 */
const DistrictTemplate = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { districtTemplateDto, schoolYearDto, timePeriodDto, token, userDetails } = state;

    const [allContent, setAllContent] = useState(null);
    const [isDistrictTemplateComplete, setIsDistrictTemplateComplete] = useState(false);
    const [isDistrictTemplateReleased, setIsDistrictTemplateReleased] = useState(false);

    const [missingCombos, setMissingCombos] = useState([]);
    const [missingCombosFlag, setMissingCombosFlag] = useState(false);

    const [loader, setLoader] = useState(false);
    const [locationsWithTemplates, setLocationsWithTemplates] = useState([]);
    const [open, setOpen] = useState("false");
    const [timePeriodId, setTimePeriodId] = useState(null);

    const allowedRolesArray = ["EPR_ADMIN"];

    const getLabel = () => {
        if (districtTemplateDto && !loader) {
            return "- Manage";
        }
        if (!districtTemplateDto && !loader) {
            return "- Create";
        }
        return "";
    };

    const getTemplateStatus = () => {
        if (!districtTemplateDto) {
            return "Not Yet Created";
        }
        if (isDistrictTemplateReleased) {
            return "Released to Schools";
        }
        if (isDistrictTemplateComplete && !isDistrictTemplateReleased) {
            return "Reviewed";
        }
        if (districtTemplateDto && !isDistrictTemplateComplete && !isDistrictTemplateReleased) {
            return "Under Review";
        }
    };

    const getTemplateClassName = () => {
        if (!districtTemplateDto) {
            return "not-yet-created";
        }
        if (isDistrictTemplateReleased) {
            return "released-to-schools";
        }
        if (isDistrictTemplateComplete && !isDistrictTemplateReleased) {
            return "reviewed";
        }
        if (districtTemplateDto && !isDistrictTemplateComplete && !isDistrictTemplateReleased) {
            return "under-review";
        }
    };

    const handleCreate = () => {
        const options = {
            action: "createDistrictTemplate",
            guid: userDetails.uid,
            schoolYearKey: schoolYearDto.key,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                const { payload } = response.data;
                dispatch({
                    type: "DistrictTemplateDto",
                    districtTemplateDto: payload
                });
                toast.success(
                    `Default district EPR template successfully created for ${schoolYearDto.name} school year`,
                    {
                        autoClose: 3000
                    }
                );
            } else {
                toast.error(`Default district EPR template could not be created. Please try again later. `, {
                    autoClose: false
                });
            }
            setLoader(false);
        });
    };

    const handleDelete = () => {
        const options = {
            action: "deleteTemplate",
            locationKey: 0, // locationKey for district is ALWAYS 0
            schoolYearKey: schoolYearDto.key,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                if (response.data.errors && response.data.errors.length > 0) {
                    toast.error(response.data.errors[0], {
                        autoClose: false
                    });
                } else {
                    dispatch({
                        type: "DistrictTemplateDto",
                        districtTemplateDto: null
                    });
                    setOpen("false");
                    toast.success(
                        `Default district EPR template successfully deleted for ${schoolYearDto.name} school year.`,
                        {
                            autoClose: 3000
                        }
                    );
                }
            } else {
                toast.error(`Default district EPR template could not be deleted. Please try again later.`, {
                    autoClose: false
                });
            }
            setLoader(false);
            setOpen("false");
        });
    };

    const handleRelease = () => {
        const options = {
            action: "releaseTemplate",
            params: {
                lastUpdaterGuid: userDetails.uid,
                templatesReleased: true,
                releasedToTeachers: false
            },
            profileId: districtTemplateDto.profileId,
            token
        };
        setLoader(true);
        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                dispatch({
                    type: "DistrictTemplateDto",
                    districtTemplateDto: null
                });
                toast.success(
                    `Default district EPR template successfully released for ${schoolYearDto.name} school year`,
                    {
                        autoClose: 3000
                    }
                );
            } else {
                toast.error(`Default district EPR template could not be released. Please try again later. `, {
                    autoClose: false
                });
            }
            setLoader(false);
            setOpen("false");
        });
    };
    /**
     * Check for completely reviewed (if 'some' completed returns false then it is not complete)
     * Check for released (if 'some' (any) grade is released they are all released and template is released)
     */
    useEffect(() => {
        if (districtTemplateDto) {
            const isComplete = !districtTemplateDto.gradeToSubjects.find((grade) => {
                return grade.subjectToStandards.some((subject) => subject.completed === false);
            });
            setIsDistrictTemplateComplete(isComplete);

            const isReleased = districtTemplateDto.gradeToSubjects.some((grade) => grade.templatesReleased === true);
            setIsDistrictTemplateReleased(isReleased);
        }
    }, [districtTemplateDto, setIsDistrictTemplateComplete]);

    /**
     * Get time periodId in order to get district template if it exists
     */
    useEffect(() => {
        if (timePeriodDto) {
            const fullYearKey = timePeriodDto.find((time) => time.name === "Full Year").key;
            setTimePeriodId(fullYearKey);
        }
    }, [timePeriodDto]);

    /**
     * Get district template if it exists and has not been fetched yet
     */
    useEffect(() => {
        if (schoolYearDto?.key && timePeriodId && !districtTemplateDto && token) {
            const options = {
                action: "getTemplateByLocationAndTimePeriod",
                locationKey: 0,
                timePeriodId: timePeriodId,
                schoolYearKey: schoolYearDto.key,
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload?.profileId !== null) {
                    const { payload } = response.data;
                    dispatch({
                        type: "DistrictTemplateDto",
                        districtTemplateDto: payload
                    });
                }
                setLoader(false);
            });
        }
    }, [dispatch, districtTemplateDto, schoolYearDto, timePeriodId, token]);

    const checkGradeAndSubject = (dto) => {
        const missingArray = dto.reduce((array, grade) => {
            const missing = grade.subjectToStandards
                .filter((subject) => {
                    return grade.gradeId !== 8 && grade.gradeId !== 9 && getTotalBySubjectPerGrade(subject) === 0;
                })
                .map((missingSub) => {
                    return { subjectName: missingSub.subjectName, gradeName: grade.gradeName };
                });
            if (missing.length > 0) {
                array.push(missing);
            }
            return array;
        }, []);
        setMissingCombos(missingArray.flat());
        setMissingCombosFlag(true);
    };
    /**
     * Get all reportable content
     */
    useEffect(() => {
        if (!allContent && token) {
            const options = {
                action: "getAllContent",
                token
            };
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data && response.data.payload?.profileId !== null) {
                    const { payload } = response.data;
                    setAllContent(payload);
                    checkGradeAndSubject(payload);
                }
            });
        }
    }, [allContent, token]);

    /**
     * Get locations with templates if district template is released
     */
    useEffect(() => {
        if (token && schoolYearDto) {
            const options = {
                action: "getLocationsWithTemplates",
                schoolYearKey: schoolYearDto.key,
                token
            };
            ElementaryProgressReportDao(options).then((response) => {
                if (response && response.data) {
                    const { payload } = response.data;
                    const schoolNames = payload.map((school) => school.locationName);
                    setLocationsWithTemplates(schoolNames);
                }
            });
        }
    }, [schoolYearDto, setLocationsWithTemplates, token]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <BreadCrumb
                labelObj={[
                    { label: "Home", path: "/home" },
                    { label: `District EPR Template ${getLabel()}`, path: "/district-template" }
                ]}
            />
            <ToastContainer style={{ width: "50%" }} />
            <div className="gutter-90" style={{ paddingTop: "8px" }}>
                {!loader && (
                    <div className="flex-centered-container">
                        <h4 className="dt-page-title">District EPR Template Management</h4>
                        <div className={`district-template-status ${getTemplateClassName()}`}>
                            Template Status: <span>{getTemplateStatus()}</span>
                        </div>
                    </div>
                )}
                {!loader && schoolYearDto && !districtTemplateDto && (
                    <>
                        <div>
                            Based on the selected reportable content items, you will generate the default district EPR
                            template. Once created, you will have the opportunity to review and complete by
                            grade/subject. Once you have validated all grades and subjects on the default district EPR
                            template, you will have the opportunity to release for use by neighborhood elementary
                            schools.
                        </div>
                        <div className="create-dt-btn-wrapper">
                            <ActionButton
                                className="action-button-reg"
                                disable={districtTemplateDto || !missingCombosFlag}
                                label="Create District Template"
                                onClick={
                                    missingCombos.length === 0
                                        ? () => handleCreate()
                                        : () => setOpen("warning-content-missing")
                                }
                                width="300px"
                            />
                        </div>
                    </>
                )}
                {!loader && districtTemplateDto && (
                    <div className="manage-template-container">
                        <div className="flex-centered-container">
                            <h4 className="manage-template-title">
                                Review and Release {schoolYearDto?.name} District Template
                            </h4>
                            <div className="dt-button-wrapper">
                                <ActionButton
                                    className="action-button-delete"
                                    label="Delete Template"
                                    onClick={
                                        locationsWithTemplates.length === 0
                                            ? () => setOpen("confirm-delete")
                                            : () => setOpen("delete-error")
                                    }
                                />
                                <ActionButton
                                    disable={!isDistrictTemplateComplete || isDistrictTemplateReleased || loader}
                                    label="Release Template to Schools"
                                    onClick={() => setOpen("confirm-release")}
                                />
                            </div>
                        </div>
                        <div className="manage-helper-text">
                            <span className="manage-district-callout">
                                Please select a grade and then a subject to review the accuracy of EPR content items.{" "}
                            </span>
                            Once you have reviewed the reportable content items for each subject, please click the
                            “Reviewed” checkbox. Once you have completed review of all subjects for all grades, you may
                            release the default district EPR template to schools for their use.
                        </div>
                        <ManageDistrictTemplate allContent={allContent} templateReleased={isDistrictTemplateReleased} />
                    </div>
                )}
            </div>
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-reg"
                            label="Release to Schools"
                            onClick={() => {
                                handleRelease();
                            }}
                        />
                    </div>
                }
                ariaLabel="Release Confirmation Dialog"
                hasCloseX={false}
                id="confirm-release"
                open={open}
                title={"Confirm Template Release for School Use"}
            >
                <div>
                    <div style={{ marginBottom: "24px" }}>
                        Please confirm that your review of all reportable content for all grades and subjects is
                        complete, and that you are ready to release the default district EPR template for use by
                        schools.{" "}
                    </div>

                    <div>
                        Please note that once you release, building leaders will pull down a version for their school.
                        Next, they will configure the specific reporting period for all reportable content items (by
                        grade and subject) that are part of the default template.{" "}
                        <u style={{ fontWeight: "600" }}>
                            Any future changes to the default template will impact this work by building leaders.{" "}
                        </u>
                    </div>
                </div>
            </DcsdDialog>
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <ActionButton
                            className="action-button-delete"
                            label="Confirm Delete"
                            onClick={() => {
                                handleDelete();
                            }}
                        />
                    </div>
                }
                ariaLabel="Delete Confirmation Dialog"
                hasCloseX={false}
                id="confirm-delete"
                open={open}
                title={"Delete Template Confirmation"}
            >
                <div>
                    Please confirm you would like to delete the default district EPR template. Please note that this
                    action will necessitate the creation of a new default template and subsequent content item review.
                </div>
            </DcsdDialog>
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton className="action-button-reg" label="Close" onClick={() => setOpen("false")} />
                    </div>
                }
                ariaLabel="Template Delete Unavailable"
                hasCloseX={false}
                id="delete-error"
                open={open}
                title={<span className="warning-modal-title-color">Template Delete Unavailable</span>}
            >
                <div>
                    The following schools have already begun using the default district EPR template:
                    <ul style={{ marginTop: "8px" }}>
                        {locationsWithTemplates.map((name) => {
                            return (
                                <li key={name}>
                                    <b>{name}</b>
                                </li>
                            );
                        })}
                    </ul>
                    <div style={{ margin: "32px 0px" }}>
                        If the default district EPR template{" "}
                        <span style={{ textDecoration: "underline", fontWeight: "bold" }}>does</span> require deletion
                        due to some issue with associated content items, please contact the school leader at each school
                        to request that they delete their school’s version of the template. Please make sure that the
                        school leader is aware that any work they have started will need to be redone once the corrected
                        district template has been released.{" "}
                    </div>
                </div>
            </DcsdDialog>
            <DcsdDialog
                actions={
                    <div className="modal-btn-group">
                        <ActionButton
                            className="action-button-cancel"
                            label="Cancel"
                            onClick={() => setOpen("false")}
                        />
                        <Link to="/manage-epr-content">
                            <ActionButton className="action-button-reg" label="Return to Manage Selectable Content" />
                        </Link>
                        <ActionButton
                            className="action-button-reg"
                            label="Yes, Create District Template"
                            onClick={() => {
                                handleCreate();
                                setOpen("false");
                            }}
                        />
                    </div>
                }
                ariaLabel="Warning - Reportable Content Missing"
                hasCloseX={false}
                id="warning-content-missing"
                open={open}
                title={<span className="warning-modal-title-color">Warning &ndash; Reportable Content Missing</span>}
            >
                <div>
                    The following subject(s) for the following grade(s) <i>do not</i> have reportable content
                    identified:
                    <ul style={{ marginTop: "8px" }}>
                        {missingCombos.map((combo, index) => {
                            const uniqueKey = `${combo.gradeName}-${combo.subjectName}-${index}`;
                            return (
                                <li key={uniqueKey}>
                                    <b>
                                        {combo.gradeName} Grade {combo.subjectName}
                                    </b>
                                </li>
                            );
                        })}
                    </ul>
                    <div style={{ marginBottom: "32px", marginTop: "16px", fontWeight: "600" }}>
                        Do you wish to proceed with creation of the default district EPR template?
                    </div>
                </div>
            </DcsdDialog>
            {loader && <LoadingSvg />}
        </RbA>
    );
};

export default DistrictTemplate;
