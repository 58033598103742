import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import {
    ALL_CONTENT_AREAS,
    ALL_GRADES,
    EPR_REPORTABLE_BY_CONTENT_AREA,
    EPR_REPORTABLE_CONTENT_UPDATE
} from "../const/ElementaryProgressReportConst";

/**
 * Data Access for the Elementary Progress Report Service
 * @name ElementaryProgressReportDao
 * @param props
 * @return {null|*}
 */
const ElementaryProgressReportDao = (props) => {
    const { action, contentAreaKey, data, gradeLevels, params, token } = props;
    const bearer = `Bearer ${token}`;
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };
    switch (action) {
        case "updateEprReportableContent":
            options.method = "PUT";
            options.params = params;
            options.data = data;
            options.url = `${EPR_REPORTABLE_CONTENT_UPDATE}/${contentAreaKey}/index.json?gradeLevels=${gradeLevels}`;
            break;
        case "eprReportableByContentAreaAndGrades":
            options.method = "GET";
            options.params = params;
            options.url = `${EPR_REPORTABLE_BY_CONTENT_AREA}/${contentAreaKey}/index.json?gradeLevels=${gradeLevels}`;
            break;
        case "allContentAreasRead":
            options.method = "GET";
            options.url = `${ALL_CONTENT_AREAS}`;
            break;
        case "allGradesRead":
            options.method = "GET";
            options.url = `${ALL_GRADES}`;
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

ElementaryProgressReportDao.propTypes = {
    action: PropTypes.string.isRequired,
    contentAreaKey: PropTypes.number,
    data: PropTypes.objectOf([PropTypes.object]),
    gradeLevels: PropTypes.string,
    params: PropTypes.objectOf([PropTypes.object]),
    token: PropTypes.string.isRequired
};

ElementaryProgressReportDao.defaultProps = {
    contentAreaKey: null,
    params: null
};

export default ElementaryProgressReportDao;
