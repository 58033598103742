import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/Tooltip";

import Icon from "../icon/Icon.jsx";
import TabButton from "../formInputs/buttons/TabButton";

import { gradeTranslation } from "../../utils/SchoolAdmin/translations.jsx";

import "../../styles/DefineReportingPeriods.scss";

/**
 * This is the Define Reporting Periods page for epr
 * @name DefineReportingPeriods
 * @param {[]} allGrades
 * @param {[]} combinedGrades
 * @param {string} selectedGrade
 * @param {func} setSelectedGrade
 * @returns {JSX.Element}
 */
const DefineGradeButtons = ({ allGrades, applicableGrades, combinedGrades, selectedGrade, setSelectedGrade }) => {
    return (
        <div className="grade-btn-container-define">
            {allGrades?.map((grade, index) => {
                const gradeObj = combinedGrades.find((obj) => obj.grade === grade);
                const isSelected = grade === selectedGrade;
                const isKinderApplicable = applicableGrades.find((appGrade) => appGrade.tag === "KINDERGARTEN");
                const isApplicableGrade =
                    applicableGrades.find((appGrade) => appGrade.tag === grade) ||
                    (grade === "K" && isKinderApplicable);

                return (
                    <div key={`${grade}-${index}`} className="define-btn-wrapper">
                        <TabButton
                            className={isSelected ? "tab-button-selected" : "tab-button"}
                            onClick={() => setSelectedGrade(grade)}
                            size="large"
                        >
                            <div>
                                <p className="primary">{gradeTranslation[grade]}</p>
                            </div>
                        </TabButton>
                        {gradeObj.complete && (
                            <div className="completed-status-define">
                                <Icon height={25} fill="green" iconName="COMPLETE" width={25} />
                            </div>
                        )}
                        {!isApplicableGrade && (
                            <div style={{ cursor: "default" }}>
                                <OverlayTrigger
                                    delay={{ hide: 5, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip className="tooltip-window" {...props}>
                                            Grade not applicable to your school
                                        </Tooltip>
                                    )}
                                    placement="top"
                                >
                                    <div className="not-applicable-circle">NA</div>
                                </OverlayTrigger>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

DefineGradeButtons.propTypes = {
    allGrades: PropTypes.instanceOf(Array),
    applicableGrades: PropTypes.instanceOf(Array),
    combinedGrades: PropTypes.instanceOf(Array),
    selectedGrade: PropTypes.string,
    setSelectedGrade: PropTypes.func
};

export default DefineGradeButtons;
