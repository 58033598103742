import React from "react";

import ActionButton from "./formInputs/buttons/ActionButton";
import DcsdDialog from "./modals/DcsdDialog";
/**
 * @todo Need to switch out this for a different bootstrap dialog
 * @returns a simple looking dialog to tell the user they dont have access to this page
 */
const NotFound = () => {
    return (
        <DcsdDialog
            actions={
                <a href="https://www.dcsdk12.org/">
                    <ActionButton
                        ariaLabel="Back to DCSD Home"
                        className="action-button-reg"
                        label="Back to DCSD Home"
                    ></ActionButton>
                </a>
            }
            ariaLabel="Page Not Found"
            hasCloseX={false}
            id="page-not-found"
            open="page-not-found"
            title="Page Not Found"
        >
            <div>Current page is unavailable.</div>
        </DcsdDialog>
    );
};

export default NotFound;
