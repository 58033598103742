/**
 * @name isValidDate
 * @param {*} dateString
 * @returns Bool (true / false)
 * found this on stack overflow
 * https://stackoverflow.com/questions/18758772/how-do-i-validate-a-date-in-this-format-yyyy-mm-dd-using-jquery
 */
export const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
};

export const formatDate = (dateString = null) => {
    if (dateString) {
        const myDate = new Date(dateString.replace(/-/g, "/"));
        const Y = myDate.getFullYear();
        const month = myDate.toLocaleString("default", { month: "long" });
        const dd = myDate.getDate();

        return `${month} ${dd}, ${Y}`;
    }

    return dateString;
};

export const formatDateAndTime = (dateString = null) => {
    if (dateString) {
        const myDate = new Date(dateString.replace(/-/g, "/"));
        const Y = myDate.getFullYear();
        const month = myDate.toLocaleString("default", { month: "long" });
        const dd = myDate.getDate();
        const hh = myDate.getHours();
        let meridian = "am";
        if (hh > 12) {
            meridian = "pm";
        }

        return `${month} ${dd}, ${Y} at ${hh}${meridian}`;
    }
};

export const getToday = () => {
    const myDate = new Date();
    const mm = (myDate.getMonth() + 1).toString().padStart(2, "0");
    const dd = myDate.getDate().toString().padStart(2, "0");

    return `${mm}-${dd}`;
};

export const getSingleYear = () => {
    const myDate = new Date();
    const Y = myDate.getFullYear();

    return `${Y}`;
};

export const getTodayWithYear = () => {
    const myDate = new Date();
    const Y = myDate.getFullYear();
    const mm = (myDate.getMonth() + 1).toString().padStart(2, "0");
    const dd = myDate.getDate().toString().padStart(2, "0");

    return `${Y}-${mm}-${dd}`;
};

export const getTodayWithTime = () => {
    const myDate = new Date();
    const Y = myDate.getFullYear();
    const mm = (myDate.getMonth() + 1).toString().padStart(2, "0");
    const dd = myDate.getDate().toString().padStart(2, "0");
    const hh = myDate.getHours().toString().padStart(2, "0");
    const ii = myDate.getMinutes().toString().padStart(2, "0");
    const ss = myDate.getSeconds().toString().padStart(2, "0");

    return `${Y}-${mm}-${dd} ${hh}:${ii}:${ss}`;
};

export const getEpoch = (dateString) => {
    if (dateString) {
        return Date.parse(dateString.replace(/-/g, "/"));
    }

    return dateString;
};

export const getDateFromEpoch = (epoch) => {
    const myDate = new Date(0);
    myDate.setUTCSeconds(epoch);
    const Y = myDate.getFullYear();
    const mm = (myDate.getMonth() + 1).toString().padStart(2, "0");
    const dd = myDate.getDate().toString().padStart(2, "0");
    const hh = myDate.getHours().toString().padStart(2, "0");
    const ii = myDate.getMinutes().toString().padStart(2, "0");
    const ss = myDate.getSeconds().toString().padStart(2, "0");

    return `${Y}-${mm}-${dd}@${hh}:${ii}:${ss}`;
};
