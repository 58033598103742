import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import DefineBySubject from "./DefineBySubject.jsx";
import DefineGradeButtons from "./DefineGradeButtons.jsx";
import LoadingSvg from "../loadingSvg/LoadingSvg";

import "../../styles/DefineReportingPeriods.scss";

/**
 * This is the Define Reporting Periods page for epr
 * @name DefineReportingPeriods
 * @param {[]} combinedGrades
 * @param {bool} isDistrictTemplateReleased
 * @param {bool} isSchoolTemplate
 * @param {bool} loader
 * @param {string} selectedContentArea
 * @param {string} selectedGrade
 * @param {func} setLabelObj
 * @param {func} setSelectedContentArea
 * @param {func} setSelectedGrade
 * @param {func} setSelectedLinke
 * @returns {JSX.Element}
 */
const DefineReportingPeriods = ({
    applicableGrades,
    combinedGrades,
    isDistrictTemplateReleased,
    isSchoolTemplate,
    loader,
    selectedContentArea,
    selectedGrade,
    setLabelObj,
    setSelectedContentArea,
    setSelectedGrade,
    setSelectedLink,
    setTemplateTouchedFlag
}) => {
    const [isApplicableGrade, setIsApplicableGrade] = useState(false);
    const [selectedGradeObject, setSelectedGradeObject] = useState(null);
    const allGrades = combinedGrades?.map((gradeObj) => gradeObj.grade);

    /**
     * Get is applicable grade
     */
    useEffect(() => {
        if (combinedGrades && selectedGrade) {
            const isKinderApplicable = applicableGrades?.find((appGrade) => appGrade.tag === "KINDERGARTEN");
            const isApplicable =
                applicableGrades?.find((appGrade) => appGrade.tag === selectedGrade) ||
                (selectedGrade === "K" && isKinderApplicable);
            setIsApplicableGrade(Boolean(isApplicable));
        }
    }, [applicableGrades, combinedGrades, selectedGrade]);
    /**
     * Get the selected grades data
     */
    useEffect(() => {
        if (combinedGrades && selectedGrade) {
            const selectedGradeObject = combinedGrades.find((grade) => grade.grade === selectedGrade);
            setSelectedGradeObject(selectedGradeObject);
        }
    }, [combinedGrades, selectedGrade]);

    return (
        <div className="template-wrapper">
            <div className="template-title-section">
                <h4 className="page-title">Define Reporting Periods</h4>
            </div>
            <div className="helper-text-define">
                {isDistrictTemplateReleased && isSchoolTemplate ? (
                    <>
                        Please define the reporting period (1st Semester only, 2nd Semester only, both Semesters, or Not
                        Reported (NR)) for each grade by subject.
                        <ol style={{ lineHeight: "24px" }}>
                            <li>
                                Select a grade and select a content area to begin setting the reporting periods for each
                                content item from the district template.
                            </li>
                            <li>
                                Once you have the reporting period defined for all content items, click &quot;Save and
                                Complete&quot; for the content area.
                            </li>
                            <li>
                                Once the reporting period has been defined for all grades and all content areas, you are
                                ready to release the school templates to teachers by individual grade or for multiple
                                grades at once.
                            </li>
                        </ol>
                        <hr />
                    </>
                ) : (
                    <>
                        No school template exists for this school year. Please create the school template for your
                        building to begin reporting period definition by grade and subject.
                    </>
                )}
            </div>
            <DefineGradeButtons
                allGrades={allGrades}
                applicableGrades={applicableGrades}
                combinedGrades={combinedGrades}
                selectedGrade={selectedGrade}
                setSelectedGrade={setSelectedGrade}
            />
            {selectedGrade &&
                !loader &&
                selectedGradeObject?.semesterProfiles.firstSem.subjectToStandards.map((subject) => {
                    return (
                        <DefineBySubject
                            key={subject.subjectId}
                            combinedGrades={combinedGrades}
                            isApplicableGrade={isApplicableGrade}
                            isReleased={selectedGradeObject.released}
                            selectedContentArea={selectedContentArea}
                            selectedGrade={selectedGrade}
                            selectedGradeObject={selectedGradeObject}
                            setLabelObj={setLabelObj}
                            setSelectedContentArea={setSelectedContentArea}
                            setSelectedGradeObject={setSelectedGradeObject}
                            setSelectedLink={setSelectedLink}
                            setTemplateTouchedFlag={setTemplateTouchedFlag}
                            subject={subject}
                        />
                    );
                })}
            {loader && <LoadingSvg />}
        </div>
    );
};

DefineReportingPeriods.propTypes = {
    applicableGrades: PropTypes.instanceOf(Array),
    combinedGrades: PropTypes.instanceOf(Array),
    isDistrictTemplateReleased: PropTypes.bool,
    isSchoolTemplate: PropTypes.bool,
    loader: PropTypes.bool,
    selectedContentArea: PropTypes.string,
    selectedGrade: PropTypes.string,
    setLabelObj: PropTypes.func,
    setSelectedContentArea: PropTypes.func,
    setSelectedGrade: PropTypes.func,
    setSelectedLink: PropTypes.func,
    setTemplateTouchedFlag: PropTypes.func
};

export default DefineReportingPeriods;
