import React, { useContext, useEffect, useReducer, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import Icon from "../components/icon/Icon.jsx";
import DcsdDialog from "../components/modals/DcsdDialog.jsx";
import { GlobalContext } from "../components/contextProvider/ContextProvider.jsx";
import EprContent from "../components/display/EprContent.jsx";
import ActionButton from "../components/formInputs/buttons/ActionButton.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";
import RbA from "../components/rba/RbA.jsx";
import ElementaryProgressReportDao from "../dao/ElementaryProgressReportDao.jsx";
import FormReducer from "../utils/FormReducer";
import { getTotalCountByStandard } from "../utils/ManageEpr/getContentCounts.jsx";

import "../styles/ManageEprContent.scss";
import "react-toastify/dist/ReactToastify.css";

/**
 * This page allows district admin to manage reportable content
 * @name ManageEprContent
 * @returns {JSX.Element}
 */

const ManageEprContent = () => {
    const [loader, setLoader] = useState(false);

    const [allContentAreas, setAllContentAreas] = useState([]);
    const [selectedContentArea, setContentArea] = useState("");
    const [tempSelectedContentArea, setTempSelectedContentArea] = useState("");
    const [open, setOpen] = useState("false");

    const [allGrades, setAllGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);

    const [reportableContent, setReportableContent] = useState(null);
    const [gradeStatusArray, setGradeStatus] = useState([]);

    const allowedRolesArray = ["EPR_ADMIN"];
    const { state } = useContext(GlobalContext);
    const { token } = state;

    const initialFormState = {
        cdeStandards: [],
        gles: [],
        evidenceOutcomes: [],
        childEvidenceOutcomes: []
    };

    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);

    /**
     * Add or remove clicked grade from selected grades array
     * @name handleOnGradeClick
     * @param {{}} grade
     */
    const handleOnGradeClick = (grade) => {
        if (selectedGrades.find((prevAddedGrade) => prevAddedGrade.key === grade.key)) {
            const tempGrades = [...selectedGrades];
            const newGradesArray = tempGrades.filter((prevGrade) => prevGrade.key !== grade.key);
            setSelectedGrades(newGradesArray);
        } else {
            const tempGrades = [...selectedGrades];
            const newGradesArray = [...tempGrades, grade];
            setSelectedGrades(newGradesArray);
        }
    };

    /**
     * Update formstate for the PUT call for changed content objects
     * @name handleFormUpdate
     * @param {{}} clickedObj
     */

    const handleFormUpdate = (clickedObj) => {
        const tempFormState = formState;
        const contentTypeArray =
            clickedObj.type !== "evidenceOutcomeChildList"
                ? tempFormState[clickedObj.type]
                : tempFormState.childEvidenceOutcomes;
        let newArray;
        if (contentTypeArray.find((prevAddedObj) => prevAddedObj.gradeToContentKey === clickedObj.gradeToContentKey)) {
            newArray = contentTypeArray.map((prevAddedObj) => {
                if (prevAddedObj.gradeToContentKey === clickedObj.gradeToContentKey) {
                    prevAddedObj.reportFlag = !prevAddedObj.reportFlag;
                }
                return prevAddedObj;
            });
        } else {
            const basicObject = {
                gradeToContentKey: clickedObj.gradeToContentKey,
                reportFlag: !clickedObj.reportFlag
            };
            newArray = [...contentTypeArray, { ...basicObject }];
        }
        formDispatch({
            type: "text",
            field: clickedObj.type !== "evidenceOutcomeChildList" ? clickedObj.type : "childEvidenceOutcomes",
            payload: newArray
        });
    };

    /**
     * Determine what type of content was clicked and call appropriate handler
     * @name handleAddClick
     * @param {{}} clickedObj
     */
    const handleAddClick = (clickedObj) => {
        handleFormUpdate(clickedObj);
        switch (clickedObj.type) {
            case "cdeStandards":
                return handleStandardAddClick(clickedObj);
            case "gles":
                return handleGleAddClick(clickedObj);
            case "evidenceOutcomes":
                return handleEoAddClick(clickedObj);
            case "evidenceOutcomeChildList":
                return handleChildEoAddClick(clickedObj);
            default:
                return;
        }
    };

    /**
     * Flip the reportFlag boolean for given clicked reportable content
     * @name makeChange
     * @param {{}} parentObject
     * @param {{}} clickedObj
     * @return {JSX.Element}
     */
    const makeChange = (parentObject, clickedObj) => {
        return parentObject[clickedObj.type].map((value) => {
            if (value.gradeToContentKey === clickedObj.gradeToContentKey) {
                value.reportFlag = !clickedObj.reportFlag;
            }
            return value;
        });
    };

    /**
     * Controlled input change handler
     * @name handleStandardAddClick
     * @param {{}} obj
     */
    const handleStandardAddClick = (obj) => {
        let tempReportableContent = { ...reportableContent };
        const gradeLevelsArray = tempReportableContent.gradeLevels;
        const newGradeLevels = gradeLevelsArray.map((grade) => {
            if (grade.gradeLevel === obj.grade) {
                return {
                    ...grade,
                    cdeStandards: makeChange(grade, obj)
                };
            } else {
                return grade;
            }
        });

        setReportableContent({ ...tempReportableContent, gradeLevels: newGradeLevels });
    };

    /**
     * Controlled input change handler
     * @name handleGleAddClick
     * @param {{}} obj
     */
    const handleGleAddClick = (obj) => {
        let tempReportableContent = { ...reportableContent };
        const gradeLevelsArray = tempReportableContent.gradeLevels;
        const newGradeLevels = gradeLevelsArray.map((grade) => {
            if (grade.gradeLevel === obj.grade) {
                return {
                    ...grade,
                    cdeStandards: grade.cdeStandards.map((standard) => {
                        return {
                            ...standard,
                            gles: makeChange(standard, obj)
                        };
                    })
                };
            } else {
                return grade;
            }
        });
        setReportableContent({ ...tempReportableContent, gradeLevels: newGradeLevels });
    };

    /**
     * Controlled input change handler
     * @name handleEoAddClick
     * @param {{}} obj
     */
    const handleEoAddClick = (obj) => {
        let tempReportableContent = { ...reportableContent };
        const gradeLevelsArray = tempReportableContent.gradeLevels;
        const newGradeLevels = gradeLevelsArray.map((grade) => {
            if (grade.gradeLevel === obj.grade) {
                return {
                    ...grade,
                    cdeStandards: grade.cdeStandards.map((standard) => {
                        return {
                            ...standard,
                            gles: standard.gles.map((gle) => {
                                return {
                                    ...gle,
                                    evidenceOutcomes: makeChange(gle, obj)
                                };
                            })
                        };
                    })
                };
            } else {
                return grade;
            }
        });
        setReportableContent({ ...tempReportableContent, gradeLevels: newGradeLevels });
    };

    /**
     * Controlled input change handler
     * @name handleChildEoAddClick
     * @param {{}} obj
     */
    const handleChildEoAddClick = (obj) => {
        let tempReportableContent = { ...reportableContent };
        const gradeLevelsArray = tempReportableContent.gradeLevels;
        const newGradeLevels = gradeLevelsArray.map((grade) => {
            if (grade.gradeLevel === obj.grade) {
                return {
                    ...grade,
                    cdeStandards: grade.cdeStandards.map((standard) => {
                        return {
                            ...standard,
                            gles: standard.gles.map((gle) => {
                                return {
                                    ...gle,
                                    evidenceOutcomes: gle.evidenceOutcomes.map((eo) => {
                                        return {
                                            ...eo,
                                            evidenceOutcomeChildList: makeChange(eo, obj)
                                        };
                                    })
                                };
                            })
                        };
                    })
                };
            } else {
                return grade;
            }
        });
        setReportableContent({ ...tempReportableContent, gradeLevels: newGradeLevels });
    };

    /**
     * @name handleSubmit
     * @param {bool} changeContentArea
     */
    const handleSubmit = (changeContentArea = false) => {
        setLoader(true);
        const options = {
            action: "updateEprReportableContent",
            data: formState,
            contentAreaKey: allContentAreas.find((area) => area.name === selectedContentArea).key,
            gradeLevels: "K, FIRST, SECOND, THIRD, FOURTH, FIFTH, SIXTH, SEVENTH, EIGHTH",
            token
        };
        ElementaryProgressReportDao(options).then((response) => {
            if (response && response.data && response.data.payload) {
                toast.success(`Your EPR Content selections saved successfully.`, { autoClose: 3000 });
                setReportableContent(null);
                setSelectedGrades([]);
                const { payload } = response.data;
                if (changeContentArea) {
                    setContentArea(tempSelectedContentArea);
                } else {
                    setReportableContent(payload);
                }
                formDispatch({
                    type: "reset",
                    payload: initialFormState
                });
            } else {
                toast.error(`Something went wrong while saving your EPR Content selections. Please try again later.`, {
                    autoClose: 3000
                });
            }
            setLoader(false);
        });
    };

    /**
     * @name handleSelectChange
     * @param {string} val
     */
    const handleSelectChange = (val) => {
        if (JSON.stringify(formState) !== JSON.stringify(initialFormState)) {
            setOpen("content-change");
            setTempSelectedContentArea(val);
        } else {
            setReportableContent(null);
            setSelectedGrades([]);
            setContentArea(val);
        }
    };

    /**
     * If a content area is selected get current selected content item counts for each grade to display status.
     */
    useEffect(() => {
        if (reportableContent && selectedContentArea && selectedGrades) {
            let gradeStatusArray = [];
            reportableContent.gradeLevels.forEach((grade) => {
                const gradeCount = grade.cdeStandards.reduce((gradeTotal, standard) => {
                    const standardTotal = getTotalCountByStandard(standard);
                    gradeTotal = gradeTotal + standardTotal;
                    return gradeTotal;
                }, 0);
                const obj = { gradeLevel: grade.gradeLevel, contentCount: gradeCount };
                gradeStatusArray = [...gradeStatusArray, obj];
                setGradeStatus(gradeStatusArray);
            });
        }
    }, [reportableContent, selectedContentArea, selectedGrades, setGradeStatus]);

    /**
     * If there are no content areas loaded get all content areas for select
     */
    useEffect(() => {
        if (allContentAreas.length === 0) {
            const options = {
                action: "allContentAreasRead",
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    setAllContentAreas(payload);
                }
                setLoader(false);
            });
        }
    }, [allContentAreas, setAllContentAreas, token]);
    /**
     * If there are no selectable grades loaded get all grades
     */
    useEffect(() => {
        if (allGrades.length === 0) {
            const options = {
                action: "allGradesRead",
                token
            };
            setLoader(true);
            ElementaryProgressReportDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    setAllGrades(payload);
                }
                setLoader(false);
            });
        }
    }, [allGrades, setAllGrades, token]);

    /**
     * If there is a selected content area fetch reportable content for all grades
     */
    useEffect(() => {
        if (selectedContentArea && !reportableContent) {
            const options = {
                action: "eprReportableByContentAreaAndGrades",
                contentAreaKey: allContentAreas.find((area) => area.name === selectedContentArea).key,
                gradeLevels: "K, FIRST, SECOND, THIRD, FOURTH, FIFTH, SIXTH, SEVENTH, EIGHTH",
                token
            };
            ElementaryProgressReportDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    const payloadGradesWithKeys = payload.gradeLevels.map((gradeLevel) => {
                        gradeLevel.key = allGrades.find(
                            (allGrade) => allGrade.gradeLevel === gradeLevel.gradeLevel
                        ).key;
                        return gradeLevel;
                    });
                    const updatedPayload = { ...payload, gradeLevels: payloadGradesWithKeys };
                    setReportableContent(updatedPayload);
                }
            });
        }
    }, [allContentAreas, allGrades, reportableContent, selectedContentArea, setReportableContent, token]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <BreadCrumb
                labelObj={[
                    { label: "Home", path: "/home" },
                    { label: "Manage EPR Content", path: "/manage-epr-content" }
                ]}
            />
            {!loader && allContentAreas && allGrades && (
                <div className="gutter-95">
                    <ToastContainer style={{ width: "50%" }} />
                    <div className="manage-header-container">
                        <h4>Manage EPR Content</h4>
                    </div>
                    <div className="selection-container">
                        <label className="select-label" htmlFor="contentAreaSelect">
                            <h6>Select Content Area:</h6>
                            <select
                                className="content-area-select"
                                id="contentAreaSelect"
                                label="Select Content Area:"
                                name="content-area-select"
                                value={selectedContentArea}
                                onChange={(e) => handleSelectChange(e.target.value)}
                            >
                                <option value="">Select content area</option>
                                {allContentAreas.map((area, index) => {
                                    const { key, name } = area;
                                    const uniqueKey = `area-${key}-${index}`;
                                    return (
                                        <option key={uniqueKey} value={area.name}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </select>
                        </label>
                        <div className="grades-wrapper">
                            <h6>Select One or More Grades:</h6>
                        </div>
                        <div className="grade-btn-container">
                            {allGrades.map((grade, index) => {
                                const { gradeLevel, name } = grade;
                                const isSelected = selectedGrades.find((selGrade) => selGrade.key === grade.key);
                                const gradeContentCount = gradeStatusArray?.find(
                                    (gradeStatusObj) => gradeStatusObj.gradeLevel === gradeLevel
                                )?.contentCount;

                                const gradeStatus = gradeContentCount > 0 ? "COMPLETE" : "NOT_STARTED";
                                return (
                                    <div key={`${grade.key}-${index}`}>
                                        <ActionButton
                                            className={isSelected ? "grade-button-selected" : "grade-button"}
                                            onClick={() => handleOnGradeClick(grade)}
                                        >
                                            <div>
                                                <p className="primary">{name}</p>
                                            </div>
                                        </ActionButton>
                                        {reportableContent && (
                                            <div className="content-status-on-grade-button">
                                                <Icon height={20} iconName={gradeStatus} width={20} />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {reportableContent?.gradeLevels.length > 0 && selectedGrades.length > 0 && (
                        <>
                            <EprContent
                                allGrades={allGrades}
                                handleAddClick={handleAddClick}
                                reportableContent={reportableContent}
                                selectedGrades={selectedGrades}
                            />
                            <div className="epr-save-btn">
                                <ActionButton disable={loader} onClick={() => handleSubmit()} label="Save Content" />
                            </div>
                        </>
                    )}
                </div>
            )}
            <DcsdDialog hasCloseX={false} id="content-change" open={open} title="Switch Content Area">
                <div>
                    You have unsaved changes in <strong>{selectedContentArea}</strong> that will be lost if you switch
                    to a different content area. Please either save your changes or you may elect to continue without
                    saving.
                </div>
                <div className="breadCrumb-btn-group">
                    <ActionButton className="action-button-cancel" label="Cancel" onClick={() => setOpen("false")} />
                    <ActionButton
                        className="action-button-reg"
                        label="Save and Continue"
                        onClick={() => {
                            handleSubmit(true);
                            setOpen("false");
                        }}
                    />
                    <ActionButton
                        className="action-button-reg"
                        label="Continue Without Saving"
                        onClick={() => {
                            setReportableContent(null);
                            setSelectedGrades([]);
                            setContentArea(tempSelectedContentArea);
                            setOpen("false");
                            formDispatch({
                                type: "reset",
                                payload: initialFormState
                            });
                        }}
                    />
                </div>
            </DcsdDialog>
            {loader && <LoadingSvg />}
        </RbA>
    );
};

export default ManageEprContent;
