import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import ActionButton from "../formInputs/buttons/ActionButton";
import ElementaryProgressReportDao from "../../dao/ElementaryProgressReportDao.jsx";
import GradingAccordions from "../display/GradingAccordions.jsx";
import LoadingSvg from "../loadingSvg/LoadingSvg.jsx";

import { GlobalContext } from "../contextProvider/ContextProvider.jsx";
import { gradeTranslation } from "../../utils/SchoolAdmin/translations.jsx";

import "../../styles/EprSummary.scss";
import "../../styles/TeacherHome.scss";

/**
 * EprSummary component provides a summary view of existing grading profiles (EPRs)
 * and allows users to create new ones for available grades.
 *
 * @component
 * @param {Object} props
 * @param {Function} dispatch
 * @param {Object} state
 * @param {Array} releasedGrades
 * @param {Object} [teacherGradeIfReleased]
 * @returns {JSX.Element}
 */
const EprSummary = ({ dispatch, releasedGrades, state, teacherGradeIfReleased }) => {
    const { gradingProfiles } = state;

    const { state: globalState } = useContext(GlobalContext);
    const { currentTimePeriodDto, schoolLevelTemplateDto, schoolYearDto, token, userDetails } = globalState || {};

    const [loader, setLoader] = useState(false);
    const [selectedGradeToCreate, setSelectedGradeToCreate] = useState("");
    const [savedMessage, setSavedMessage] = useState("");
    const [showAddAnother, setShowAddAnother] = useState(false);
    const [showSaved, setShowSaved] = useState(false);

    const navigate = useNavigate();

    /**
     * Handles selection change for the grade dropdown.
     * @param {Object} e
     */
    const handleSelectChange = (e) => {
        setSelectedGradeToCreate(e.target.value);
    };

    const handleCreate = () => {
        const parentProfileId =
            currentTimePeriodDto.name === "1st Semester"
                ? schoolLevelTemplateDto.firstSemester.profileId
                : schoolLevelTemplateDto.secondSemester.profileId;
        const selectedGradeObj = releasedGrades.find((grade) => grade.gradeLevel === selectedGradeToCreate);
        const options = {
            action: "createGradingProfile",
            data: {
                parentProfile: parentProfileId,
                grade: selectedGradeObj.profileGradeId,
                schoolYearKey: schoolYearDto.key,
                ownerGuid: userDetails.uid
            },
            token
        };
        setLoader(true);

        ElementaryProgressReportDao(options).then((response) => {
            if (response) {
                dispatch({ type: "SET_FETCH_FLAG", payload: true });
                dispatch({ type: "SET_SELECTED_EPR", payload: response.data.payload });
                toast.success(`EPR successfully created for ${currentTimePeriodDto.name}`, {
                    autoClose: 3000
                });
                navigate(`/home/summary/roster/${response.data.payload.key}`);
                dispatch({ type: "SET_SELECTED_LINK", payload: "HOME/SUMMARY/ROSTER" });
            } else {
                toast.error(`EPR could not be created for ${currentTimePeriodDto.name}. Please try again later.`, {
                    autoClose: false
                });
            }
            setLoader(false);
        });
    };

    /**
     * Determines the first missing grade that the teacher has not created an EPR for.
     */
    useEffect(() => {
        if (gradingProfiles.length > 0 && releasedGrades.length > 0) {
            const existingGrades = gradingProfiles.map((profile) => profile.grade);
            const firstMissingGrade = releasedGrades.find((grade) => !existingGrades.includes(grade.profileGradeId));

            if (firstMissingGrade) {
                setSelectedGradeToCreate(firstMissingGrade.gradeLevel);
            } else if (releasedGrades.length > 0) {
                setSelectedGradeToCreate("");
            }
        }
    }, [gradingProfiles, releasedGrades]);

    /**
     * Updates selected grade when the teacher's released grade is available.
     */
    useEffect(() => {
        if (teacherGradeIfReleased && !selectedGradeToCreate) {
            setSelectedGradeToCreate(teacherGradeIfReleased.gradeLevel);
        }
    }, [teacherGradeIfReleased, selectedGradeToCreate]);

    return (
        <div className="right-side-wrapper">
            <div className="right-page-title">
                <h4>{gradingProfiles?.length === 0 ? "EPR Setup" : "EPR Summary"}</h4>
                <h5>
                    <span style={{ fontWeight: "700" }}>{currentTimePeriodDto.name}</span> | {schoolYearDto.name}
                </h5>
            </div>
            <hr />
            {(!gradingProfiles || gradingProfiles.length === 0) && releasedGrades.length === 0 && (
                <div>No grades have been released yet. Please check again later.</div>
            )}
            {gradingProfiles.length === 0 && releasedGrades.length > 0 && (
                <>
                    <div className="setup-helper-text">
                        To begin, select a grade to create your homeroom EPR.
                        <div style={{ marginTop: "4px" }}>
                            Next, you will be prompted to assign students to the homeroom EPR based on your IC homeroom
                            section.
                        </div>
                        <div style={{ marginTop: "4px" }}>
                            Finally, you will have the opportunity to share your homeroom EPR with other contributing
                            professionals at your school.
                        </div>
                    </div>
                    <div className="add-additional-epr" style={{ display: "flex", marginTop: "16px" }}>
                        <label className="inline-select-label">
                            <div className="select-title">Create new EPR for grade:</div>
                            <select
                                className="grade-select"
                                value={selectedGradeToCreate}
                                onChange={handleSelectChange}
                            >
                                {releasedGrades.map((grade) => (
                                    <option key={grade.gradeLevel} value={grade.gradeLevel}>
                                        {gradeTranslation[grade.gradeLevel] === "K"
                                            ? "Kindergarten"
                                            : `${gradeTranslation[grade.gradeLevel]} grade`}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <ActionButton label="Create New EPR" onClick={handleCreate} />
                    </div>
                </>
            )}
            {gradingProfiles.length > 0 && gradingProfiles.length < releasedGrades.length && (
                <>
                    {showAddAnother ? (
                        <button onClick={() => setShowAddAnother(!showAddAnother)} className="hide-add-additional-btn">
                            Hide Add Additional EPR
                        </button>
                    ) : (
                        <div style={{ marginLeft: "-8px", marginBottom: "24px" }}>
                            <ActionButton
                                className="action-button-reg"
                                onClick={() => setShowAddAnother(!showAddAnother)}
                                label="Create Homeroom EPR"
                            />
                        </div>
                    )}

                    {showAddAnother && (
                        <div className="add-additional-epr">
                            <div className="add-epr-note">
                                Note: Please delete an incorrectly created homeroom EPR before creating a new one.
                            </div>
                            <div className="add-additional-epr-form">
                                <label className="inline-select-label">
                                    <div className="select-title">Create new EPR for grade:</div>
                                    <select
                                        className="grade-select"
                                        name="grade-select"
                                        value={selectedGradeToCreate}
                                        onChange={handleSelectChange}
                                    >
                                        {releasedGrades
                                            .filter(
                                                (grade) =>
                                                    !gradingProfiles.find((prof) => prof.grade === grade.profileGradeId)
                                            )
                                            .map((grade) => (
                                                <option key={grade.gradeLevel} value={grade.gradeLevel}>
                                                    {gradeTranslation[grade.gradeLevel] === "K"
                                                        ? "Kindergarten"
                                                        : `${gradeTranslation[grade.gradeLevel]} grade`}
                                                </option>
                                            ))}
                                    </select>
                                </label>
                                <ActionButton label="Create New EPR" onClick={handleCreate} />
                            </div>
                        </div>
                    )}
                </>
            )}
            {gradingProfiles.length > 0 && (
                <GradingAccordions
                    dispatch={dispatch}
                    gradingProfiles={gradingProfiles}
                    setSavedMessage={setSavedMessage}
                    setShowSaved={setShowSaved}
                    state={state}
                />
            )}
            {loader && <LoadingSvg />}
            {showSaved && <div className="saved-status-bar">{savedMessage}</div>}
        </div>
    );
};

EprSummary.propTypes = {
    dispatch: PropTypes.func.isRequired,
    releasedGrades: PropTypes.array.isRequired,
    state: PropTypes.object.isRequired,
    teacherGradeIfReleased: PropTypes.object
};

EprSummary.defaultProps = {
    teacherGradeIfReleased: null
};

export default EprSummary;
